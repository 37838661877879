/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
/* eslint-disable array-bracket-spacing */
/* eslint-disable no-empty */
/* eslint-disable keyword-spacing */
/* eslint-disable brace-style */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { Pagination } from 'antd';
import Cookies from "js-cookie";
import { useCallback, useEffect, useRef, useState } from "react";

import Calendar from "react-calendar";
import { IoMdClose } from "react-icons/io";
import { get, post } from "../../api-services/fetch";
import { newget } from "../../api-services/newfetch";
import "../../assets/css/calendar.css";
import "../../assets/css/Dashboard.css";
import { decodeToken } from "../../utility/auth";
import {
  formatDate,
  formatDateforPicker, formatSimpleDate
} from "../../utility/dateTime";
import { formatStatus } from "../../utility/general";

import notification from "../../utility/notification";
import Greeting from "../common/Greeting";
import IsLoading from "../common/IsLoading";
import useClickOutsideBox from "../hooks/useClickOutsideBox";
import SearchInput from "../inputs/SearchInput";
import SelectInputColored from "../inputs/SelectInputColored";
import LocationDetails from "../layouts/LocationDetails";
import MeetingRequestTable from "../layouts/MeetingRequestTable";
import RequestQueryForm from "../layouts/QueryForm";
import QueryThread from "../layouts/QueryThread";
import RequestDetails from "../layouts/RequestDetails";


const Dashboard = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [showMeetingTab, setShowMeetingTab] = useState(false);
  const [showOnlyMemos, setShowOnlyMemos] = useState(true);
  const [showOnlyMeetings, setShowOnlyMeetings] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [queryFilter, setQueryFilter] = useState("");
  
  const [requestCounts, setRequestCounts] = useState({});
  const [meetingCounts, setMeetingCounts] = useState({});
  const [errorMsg, setErrorMsg] = useState("No Request Yet");
  const [Mda, setMda] = useState([]);
  const [mailDisplay, setMailDisplay] = useState(true);
  const [mdaDisplay, setMdaDisplay] = useState(false);
  const [category, setCategory] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [paginationLength, setpaginationLength] = useState(10);
  
  const [meetingRequestList, setmeetingRequestList] = useState([]);

  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarFor, setCalendarFor] = useState("");
  const [calendar, setCalendar] = useState(null);
  const [requestCalendar, setRequestCalendar] = useState(null);
  const [meetingCalendar, setMeetingCalendar] = useState(null);
  const [showQueryForm, setShowQueryForm] = useState(false);
  const [showQueryThread, setShowQueryThread] = useState(false);
  const [updated, setUpdated] = useState([]);
  const wrapperRef = useRef(null);
  const focusRef = useRef();
  const [current, setCurrent] = useState("");
  const [currentId, setCurrentId] = useState("");
  console.log(details);
  console.log(errorMsg);

  useClickOutsideBox(wrapperRef, () => setShowCalendar(false));

  function retrieveui() {
    let ui = localStorage.getItem("ui");
    ui = decodeToken("ui").ui;
    console.log(ui);
    return ui;
  }

  const handleDetails = async(url, ID, reqUrlEach) => {
    setCurrent(url);
    setCurrentId(ID);
    console.log("from action");
    if (url === "category") {
      const loadCat = async () => {
        setDetails([])
        setIsLoading(true)
        setShowOnlyMemos(true)
        const res = await newget({
          endpoint: "eachCategory",
          param: ID,
          pQuery: { userId: retrieveui() },
          auth: true,
        });
        console.log(res);
        if (res && res.status === 200) {
          const { data } = res;
          setIsLoading(false);
          if (data.code === 0) {
            setErrorMsg("");
            setDetails([]);
            setDetails(data.data);
            console.log(data.data);
          } else {
            notification({
              title: "Error",
              message: data.message,
              type: "danger",
            });
          }
        } else {
          notification({
            title: "Error",
            message:
              "Something has gone wrong when fetching. Please, try again.",
            type: "danger",
          });
          setIsLoading(false);
        }
      };

      loadCat();
    } else if (url === "mda") {
      const loadMda = async () => {
        const res = await newget({
          endpoint: "eachMda",
          param: ID,
          pQuery: { userId: retrieveui() },
          auth: true,
        });
        setmeetingRequestList(res?.data?.newData?.scheduleResponses)
        console.log(res?.data?.newData?.requestResponses);
        if (res && res.status === 200) {
          const { data } = res;
          setIsLoading(false);
          if (data.code === 0) {
            setErrorMsg("");
            setDetails([]);
            setDetails(data?.newData?.requestResponses);
            console.log(data?.newData?.requestResponses);
            console.log(res?.data?.newData?.scheduleResponses);

          } else {
            notification({
              title: "Error",
              message: data.message,
              type: "danger",
            });
          }
        } else {
          notification({
            title: "Error",
            message:
              "Something has gone wrong when fetching. Please, try again.",
            type: "danger",
          });
          setIsLoading(false);
        }
      };

      loadMda();
    }

  };
  
  const getList = async (pageNumber,pageSize,queryFilter) => {
    let payload = {
       pageNumber,
       queryFilter:queryFilter,
       pageSize
     }
     // focusRef?.current?.focus();
     setIsLoading(true);
     setErrorMsg("");
     const res = await post({
       endpoint: "requests",
       body:payload,
       pQuery: { query: searchText, status,startDate,
         endDate },
     });

     console.log(res);

     if (res && res.status === 200) {
       const { data } = res;
     console.log(data);
    //  console.log( JSON.parse(res.headers['x-pagination']).TotalCount);

       if (data.code === 1) {
         
         if(!isNaN(Cookies.get("requestId"))){
           console.log("requestId + hello")
          //  setpaginationLength(JSON?.parse(res.headers['x-pagination']).TotalCount)

           const mail = await get({
            endpoint: "request",
           param:Number(Cookies.get("requestId"))
          }); 
         
           setDetails([mail.data.data]);
         }
         else{
           console.log("requestId + shello")
           setDetails(data.data);
           setpaginationLength(JSON?.parse(res.headers['x-pagination']).TotalCount)

          //  setpaginationLength(data.data.length)
         }



         
         if (data.data.length < 1) {
           if (searchText) {
             setErrorMsg("Request not found");
           } else {
             setErrorMsg("No Request yet");
           }
         }
       } else {
         setErrorMsg(data.message);
       }
     } else {
       setErrorMsg("Something has gone wrong. Please, try again.");
     }
     setIsLoading(false);
   };

   const getSpeificMail =(id)=>{

   }

  useEffect(() => {
    // window.scrollTo(0, 0);
    document.title = "EDSG - Dashboard";

    const getRequestCounts = async () => {
      let startDate = null;
      let endDate = null;
      if (requestCalendar) {
        startDate = formatDateforPicker(requestCalendar[0]);
        endDate = formatDateforPicker(requestCalendar[1]);
      }
      const res = await get({
        endpoint: "/api/dashboard/general-request",
        pQuery: { startDate, endDate },
      });

      console.log(res);

      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          setRequestCounts(data.data);
        } else {
          notification({
            title: "Request Count Error",
            message: data.message,
            type: "danger",
          });
        }
      } else {
        notification({
          title: "Network Error",
          message:
            "Something has gone wrong while fetching the general request counts. Please, try again.",
          type: "danger",
        });
      }
    };

    const getMeetingCounts = async () => {
      let startDate = null;
      let endDate = null;
      if (meetingCalendar) {
        startDate = formatDateforPicker(meetingCalendar[0]);
        endDate = formatDateforPicker(meetingCalendar[1]);
      }
      const res = await get({
        endpoint: "/api/dashboard/meeting-request",
        pQuery: { startDate, endDate },
      });

      console.log(res);

      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          setMeetingCounts(data.data);
        } else {
          notification({
            title: "Request Count Error",
            message: data.message,
            type: "danger",
          });
        }
      } else {
        notification({
          title: "Network Error",
          message:
            "Something has gone wrong while fetching the meeting request counts. Please, try again.",
          type: "danger",
        });
      }
    };
    
    getList(1,10,'');
    // getRequestCounts();
    // getMeetingCounts();
  }, [searchText, status, requestCalendar, meetingCalendar, updated]);

  const loadCat = useCallback(async () => {
    const res = await newget({
      endpoint: "allCategoryCount",
      param: retrieveui(),
      auth: true,
    });
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 0) {
        setCategory(data.data);
      } else {
        notification({
          title: "Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Error",
        message: "Something has gone wrong when fetching. Please, try again.",
        type: "danger",
      });
    }
  }, []);

  const loadMda = useCallback(async () => {
    const res = await newget({
      endpoint: "allMdaCount",
      param: retrieveui(),
      auth: true,
    });
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 0) {
        setMda(data.data);
      } else {
        notification({
          title: "Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something has gone wrong when fetching the document journey. Please, try again.",
        type: "danger",
      });
    }
  }, []);

  const hanldlePaginationChange = (page,pageSize,queryFilter)=>{
    getList(page,pageSize,queryFilter);

  }

  useEffect(() => {
    loadCat();
  }, [loadCat]);

  useEffect(() => {
    loadMda();
  }, [loadMda]);

  const handleCalendarChange = (val) => {
    setCalendar(val);
    if (calendarFor === "request") {
      setRequestCalendar(val);
    }
    if (calendarFor === "meeting") {
      setMeetingCalendar(val);
    }
    setShowCalendar(!showCalendar);
  };

  const handleShowCalendar = (cFor) => {
    setCalendarFor(cFor);
    setShowCalendar(!showCalendar);
  };

  const handleSearch = async (e) => {
    const { name, value } = e.target;

    if (name === "searchText") {
      setSearchText(value);
    } else if (name === "status") {
      setStatus(value);
    }
  };

  const statusOptions = [
    { name: "All Requests", value: "" },
    { name: "Actioned", value: "actioned" },
    { name: "Assigned", value: "assigned" },
    { name: "Communique", value: "communique" },
    { name: "Pending", value: "pending" },
    { name: "Declined", value: "declined" },
  ];
  const handleMail = () => {
    setMailDisplay(true);
    setMdaDisplay(false);
  };
  const handleMda = () => {
    setMailDisplay(false);
    setMdaDisplay(true);
  };
  return (
    <div className="w-100">
      {showQueryForm && (
        <RequestQueryForm closeModal={() => setShowQueryForm(false)} />
      )}
      {showQueryThread && (
        <QueryThread closeModal={() => setShowQueryThread(false)} />
      )}
      {showCalendar && (
        <div className="overlay">
          <div className="calendar" ref={wrapperRef}>
            <Calendar
              onChange={handleCalendarChange}
              value={calendar}
              className="calendar"
              selectRange
            />
          </div>
        </div>
      )}
      <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
      <div className="row m-t-40" style={{ width: "100%" }}>
        <div
          style={{
            border: "1px solid light grey",
            height: "50px",
            width: "100%",
            backgroundColor: "white",
            padding: "10px",
          }}
        >
          <div style={{ float: "left", display: "flex" }}>
            <h4
              style={{ fontWeight: "800", marginRight: "30px" }}
              className="strn"
              onClick={() => {
                handleMail()
                setShowOnlyMemos(true)
              }}
            >
              <strong>Mails/Requests</strong>
            </h4>
            <h4
              style={{ fontWeight: "900", color: "black" }}
              className="strn"
              onClick={() => {
                setShowMeetingTab(false)
                setShowOnlyMemos(false)
                handleMda()
              }}
            >
              MDA/Agendas
            </h4>
          </div>
          <div
            style={{ display: "flex", float: "right", alignItems: "center" }}
          >
            {/* <h4
              style={{
                fontWeight: '900', cursor: 'pointer', marginRight: '15px', border: '1px solid #1C811C', padding: '5px', background: '#1C811C', borderRadius: '3px', color: 'white'
              }}
              onClick={() => { setShowQueryForm(true); }}
            >
              Issue Query
            </h4> */}

            {/* <p
              style={{
                fontWeight: "noormal",
                color: "grey",
                fontSize: "11px",
                marginRight: "15px",
              }}
            >
              FILTER BY DATE
            </p> */}
            {/* <EventNoteIcon style={{ color: "#ccc" }} /> */}
          </div>
        </div>
        <div
          style={{
            border: "1px solid green",
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
          }}
        />
        <div style={{ width: "100%" }}>
          <div className={`dsp ${mailDisplay ? "d-true" : "d-none"} `}>
            {category
              ? category &&
              category.map(
                (cat) => (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    onClick={() => {
                      handleDetails("category", cat.categoryId);
                    }}
                    key={cat.categoryId}
                    // eslint-disable-next-line react/jsx-props-no-multi-spaces
                    className="cardHov"
                  >
                    <p
                      style={{
                        top: "0",
                        float: "right",
                        color: "grey",
                        margin: "8px",
                        fontSize: "14px",
                      }}
                      key={cat.categoryId}
                    >
                      {/* <b>{cat.meetingRequestCount}</b> */}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "35px",
                      }}
                    >
                      <CardGiftcardIcon
                        style={{
                          fontSize: "40px",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "15px",
                          marginRight: "auto",
                          marginLeft: "auto",
                          marginTop: "15px",
                          textAlign: "center",
                        }}
                        key={cat.categoryId}
                      >
                        {cat.categoryName}
                      </p>
                    </div>
                  </div>
                ),
                console.log(category.data)
              )
              : []}
          </div>

          <div className={`dsp ${mdaDisplay ? "d-true" : "d-none"} `}>
            {Mda
              ? Mda &&
              Mda.map(
                (m) => (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    // eslint-disable-next-line block-spacing
                    onClick={() => {
                      setShowMeetingTab(true)
                      handleDetails("mda", m.mdaId);
                    }}
                    // style={{
                    //   border: "1px solid light grey",
                    //   backgroundColor: "white",
                    //   padding: "20px",
                    //   cursor: "pointer",
                    //   width: "28%",
                    //   marginRight: "1rem",
                    //   marginBottom: "20px",
                    // }}
                    className="cardHov"
                  >
                    <p
                      style={{
                        top: "0",
                        float: "right",
                        color: "grey",
                        margin: "8px",
                        fontSize: "14px",
                      }}
                      key={m.mdaId}
                    >
                      {/* <b>{m.meetingRequestCount}</b> */}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "35px",
                      }}
                    >
                      <CardGiftcardIcon
                        style={{
                          fontSize: "30px",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "15px",
                          marginRight: "auto",
                          marginLeft: "auto",
                          marginTop: "15px",
                          textAlign: "center",
                        }}
                        key={m.mdaId}
                      >
                        {m.mdaName}
                      </p>
                    </div>
                  </div>
                ),
                console.log(Mda.data)
              )
              : []}
          </div>
        </div>

        {/* <div style={{display: 'flex', flexWrap: 'wrap', padding: '20px'}}>
        <div style={{ border: '1px solid light grey', height: '110px', width: '13%', backgroundColor: 'white', marginTop: '1px', margin: '10px' }}>
          <p style={{top: '0', float: 'right', color: 'grey', margin: '8px', fontSize: '14px'}}><b>22</b></p>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '35px'}}>
        <CardGiftcardIcon style={{fontSize: '40px', marginRight: 'auto', marginLeft: 'auto'}} />
        <p style={{fontSize: '10px', marginRight: 'auto', marginLeft: 'auto'}}>Congratulatory <br /> Messages</p>
        </div>
        </div>
        <div style={{ border: '1px solid light grey', height: '110px', width: '13%', backgroundColor: 'white', marginTop: '1px', margin: '10px' }}>
          <p style={{top: '0', float: 'right', color: 'grey', margin: '8px', fontSize: '14px'}}><b>22</b></p>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '35px'}}>
        <CardGiftcardIcon style={{fontSize: '40px', marginRight: 'auto', marginLeft: 'auto'}} />
        <p style={{fontSize: '10px', marginRight: 'auto', marginLeft: 'auto'}}>Congratulatory <br /> Messages</p>
        </div>
        </div>
        <div style={{ border: '1px solid light grey', height: '110px', width: '13%', backgroundColor: 'white', marginTop: '1px', margin: '10px' }}>
          <p style={{top: '0', float: 'right', color: 'grey', margin: '8px', fontSize: '14px'}}><b>22</b></p>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '35px'}}>
        <CardGiftcardIcon style={{fontSize: '40px', marginRight: 'auto', marginLeft: 'auto'}} />
        <p style={{fontSize: '10px', marginRight: 'auto', marginLeft: 'auto'}}>Congratulatory <br /> Messages</p>
        </div>
        </div>
        <div style={{ border: '1px solid light grey', height: '110px', width: '13%', backgroundColor: 'white', marginTop: '1px', margin: '10px' }}>
          <p style={{top: '0', float: 'right', color: 'grey', margin: '8px', fontSize: '14px'}}><b>22</b></p>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '35px'}}>
        <CardGiftcardIcon style={{fontSize: '40px', marginRight: 'auto', marginLeft: 'auto'}} />
        <p style={{fontSize: '10px', marginRight: 'auto', marginLeft: 'auto'}}>Congratulatory <br /> Messages</p>
        </div>
        </div>
        <div style={{ border: '1px solid light grey', height: '110px', width: '13%', backgroundColor: 'white', marginTop: '1px', margin: '10px' }}>
          <p style={{top: '0', float: 'right', color: 'grey', margin: '8px', fontSize: '14px'}}><b>22</b></p>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '35px'}}>
        <CardGiftcardIcon style={{fontSize: '40px', marginRight: 'auto', marginLeft: 'auto'}} />
        <p style={{fontSize: '10px', marginRight: 'auto', marginLeft: 'auto'}}>Congratulatory <br /> Messages</p>
        </div>
        </div>
        <div style={{ border: '1px solid light grey', height: '110px', width: '13%', backgroundColor: 'white', marginTop: '1px', margin: '10px' }}>
          <p style={{top: '0', float: 'right', color: 'grey', margin: '8px', fontSize: '14px'}}><b>22</b></p>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '35px'}}>
        <CardGiftcardIcon style={{fontSize: '40px', marginRight: 'auto', marginLeft: 'auto'}} />
        <p style={{fontSize: '10px', marginRight: 'auto', marginLeft: 'auto'}}>Congratulatory <br /> Messages</p>
        </div>
        </div> */}
      </div>
      {
        mdaDisplay && showMeetingTab && <div className="flex flex-v-center " style={{ marginLeft: "auto", width: "40%" }}>
          <button className='button pointer p-5' style={{ fontSize: "12px", backgroundColor: showOnlyMemos ? 'grey' : "#017D34" }} onClick={() => {
            setShowOnlyMemos(true)
            setShowOnlyMeetings(false)
          }}>
            Memo Request
          </button>


          <button className='button pointer p-5' style={{ fontSize: "12px", backgroundColor: showOnlyMeetings ? 'grey' : "#017D34" }} onClick={() => {
            setShowOnlyMeetings(true)
            setShowOnlyMemos(false)

          }}>
            Meetings Requests
          </button>



        </div>
      }
      {showOnlyMemos &&
        <div className="overlay">
          <IoMdClose 
          className="close-btn pointer" 
          onClick={() => { 
            setShowOnlyMemos(false);
            if(Cookies.get("requestId") !== 'undefined'){
               Cookies.remove('requestId');
                Cookies.remove('fromDashboard')
                window.location.assign(Cookies.get('_HomeLink'));
            }
           
           }} 
           />

          <div
            className="w-100 flex space-between flex-v-center m-t-20"
            tabIndex={-1}
            ref={focusRef}
            style={{ maginTop: "40px" }}
          >
            <h3>Treated Requests</h3>
            <div className="flex flex-v-center">
              <p className="m-r-10 text-grey" style={{ color: "grey" }}>
                <b>Filter items by</b>
              </p>
              <SelectInputColored
                className="m-r-10"
                options={statusOptions || []}
                value={status}
                onChange={handleSearch}
                name="status"
              />
              <SearchInput
                name="searchText"
                value={searchText}
                onChange={handleSearch}
                className="m-l-5"
                placeholder="Search transactions invoices or help...."
              />
            </div>
          </div>
          <table className="table-view m-t-10" style={{ background: '#FFFFFF' }}>
            <thead className="table-header">
              <tr className="table-row">
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Document Title</p>
                </th>
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Upload Date</p>
                </th>
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Vendor/Source</p>
                </th>
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Status</p>
                </th>
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Last Treated</p>
                </th>
                {/* <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Actions</p>
                  </th> */}
              </tr>
            </thead>
            <tbody className="table-body">
              {details.length !== 0 && status !== null &&
                details?.filter((request)=>{return request?.status?.includes(status)}).map((item) => (
                  <ListItem
                    key={item.id}
                    detail={item}
                    current={current}
                    currentId={currentId}
                    handleDetails={handleDetails}
                    updateInfo={() => setUpdated(!updated)}
                  // meetingRequestList={meetingRequestList}
                  />
                ))}
            </tbody>
          </table>
          <div style={{marginTop:"15px", display:"flex", alignItems:'flex-end', textAlign:'right'}}>
        <Pagination onChange= {hanldlePaginationChange} defaultCurrent={1} total={paginationLength} />
      </div>

        </div>
        }
      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      {isLoading && <IsLoading />}
      {console.log(meetingRequestList)}
      {mdaDisplay && showMeetingTab && showOnlyMeetings && <MeetingRequestTable details={meetingRequestList} />}

    </div>
  );
};

const ListItem = ({ detail,updateInfo, handleDetails, current, currentId }) => {
  // const [itemDetails, setItemDetails] = useState(detail || {});
  const [showDetails, setShowDetails] = useState(false);

  useEffect(()=>{
    setShowDetails(Cookies.get("requestId") === "undefined" ? false : true)
    
  }, [detail])

  return (
    <>
      <tr
        className={`${showDetails && "active"}  ${detail.id
          } table-row pointer`}
        onClick={() => setShowDetails(!showDetails)}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {detail.title}
          {/* {showDetails && (
            <IoMdClose
              className="dropdown-close-btn pointer"
              onClick={() => setShowDetails(!showDetails)}
            />
          )} */}
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          {formatDate(detail.dateReceived)}
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          {detail.vendor}
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          <div className={`status ${detail.status}-status`}>
            {formatStatus(detail.status)}
          </div>
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          {formatSimpleDate(detail.updatedAt || detail.createdAt)}
        </td>
        {/* <td
          style={{
            display: "flex",
            right: "0",
            justifyContent: "flex-end",
            padding: "20px 10px",
          }}
        >
          <span
            style={{
              border: "1px solid white",
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ff8566",
            }}
          >
            <DeleteIcon style={{ color: "green" }} />
          </span>
          <span
            style={{
              border: "1px solid white",
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#66ff99",
            }}
          >
            {" "}
            <CreateIcon style={{ color: "green" }} />
          </span>
        </td> */}
      </tr>
      {showDetails && (
        <tr className="table-row bg-light-gray">
          <td colSpan={5}>
            <RequestDetails
              handleDetails={handleDetails}
              current={current}
              currentId={currentId}
              requestId={detail.id}
              updated={updateInfo}
            />
          </td>
        </tr>
      )}

    </>
  );
};

export default Dashboard;
