/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment-timezone';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';
import ReactTooltip from 'react-tooltip';
import { Pagination } from 'antd';
import SearchInput from '../inputs/SearchInput';
import LocationDetails from '../layouts/LocationDetails';
import RequestModal from '../layouts/RequestModal';
import { get, post, del } from '../../api-services/fetch';
import IsLoading from '../common/IsLoading';
import Greeting from '../common/Greeting';
import {
  formatSimpleDate, formatDate, formatDateforPicker, formatLongDate
} from '../../utility/dateTime';
import notification from '../../utility/notification';
import { formatStatus } from '../../utility/general';
import SelectInputColored from '../inputs/SelectInputColored';
import CalendarBtn from '../layouts/CalendarBtn';

const RequestManager = ({ history }) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState('');
  const [listUpdate, setListUpdate] = useState(false);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [startDate, setStartDate] = useState(formatDateforPicker(new Date()));
  const [endDate, setEndDate] = useState(formatDateforPicker(new Date()));
  const [selectedDate, setSelectedDate] = useState('Today');
  const [paginationLength, setpaginationLength] = useState(10);

  const focusRef = useRef();




  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Request Manager';


    getList(1, 10, searchText);
  }, [searchText, startDate, endDate, status, listUpdate]);


  const getList = async (pageNumber, pageSize, queryFilter) => {

    let payload = {
      pageNumber,
      queryFilter:queryFilter,
      pageSize,
      status
      
     
    }

    // focusRef.current.focus();
    // setIsLoading(true);
    setErrorMsg('');
    const res = await post({
      endpoint: 'requests',
      body: payload,
      pQuery: {
        query: searchText, startDate, endDate, status
      },

    });

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setDetails(data.data);
        setpaginationLength(JSON.parse(res.headers['x-pagination']).TotalCount);
        if (data.data.length < 1) {
          if (searchText) {
            setErrorMsg('Request not found');
          } else {
            setErrorMsg('No Request yet');
          }
        }
      } else {
        setErrorMsg(data.message);
      }
    } else {
      setErrorMsg('Something has gone wrong. Please, try again.');
    }
    setIsLoading(false);
  };

  const handleDate = async (e) => {
    const { value } = e.target;
    setSelectedDate(value);
    setSearchText('');

    switch (value) {
      case 'Today':
        // get meetings for today
        setStartDate(formatDateforPicker(new Date()));
        setEndDate(formatDateforPicker(new Date()));
        break;
      case 'This Week':
        // get meetings for this week
        setStartDate(formatDateforPicker(moment().startOf('week').toDate()));
        setEndDate(formatDateforPicker(moment().endOf('week').toDate()));
        break;
      case 'This Month':
        // get meetings for this month
        setStartDate(formatDateforPicker(moment().startOf('month').toDate()));
        setEndDate(formatDateforPicker(moment().endOf('month').toDate()));
        break;
      case 'Calendar':
        // get meetings for the day selected in the calendar
        setStartDate(formatDateforPicker(calendarDate));
        setEndDate(formatDateforPicker(calendarDate));
        break;
      case 'All Requests':
        // get meetings for the day selected in the calendar
        setStartDate('');
        setEndDate('');
        break;
      default:
        break;
    }
  };

  const handleCalendarDate = async (val) => {
    setCalendarDate(val);
    setStartDate(formatDateforPicker(val));
    setEndDate(formatDateforPicker(val));
    setSelectedDate('Calendar');
    setSearchText('');
  };

  const handlePaginationChange = (page, pageSize, queryFilter) => {
    getList(page, pageSize, queryFilter);

  }

  const showEditForm = (id) => {
    history.push(`/schedule-manager/update-request/${id}`);
  };

  const showPreview = (id) => {
    const selected = details.filter((item) => id === item.id);

    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setViewDetails(true);
    }
  };

  const closeModal = () => {
    setSelectedRecord([]);
    setViewDetails(false);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    if (name === 'searchText') {
      setSearchText(value);
    } else if (name === 'status') {
      setStatus(value);
      console.log(value);
    }
  };

  const updateList = (newItem, isUpdate) => {
    if (isUpdate) {
      const newList = details.map((item) => (item.id === newItem.id ? newItem : item));
      setDetails(newList);
    } else {
      const newList = [...details];
      newList.unshift(newItem);
      setDetails(newList);
    }
  };

  const bookMeeting = (request) => {
    history.push(`/schedule-manager/create-request?type=meeting&requestId=${request.id}&requestTitle=${request.title}`);
  };

  const deleteItem = async (id, name) => {
    const confirmAction = confirm(`Click OK to confirm the deletion of "${name}"`);
    if (!confirmAction) return;
    setIsDeleting(true);
    const res = await del({ endpoint: 'request', param: id, auth: true });

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: 'Successful Request Deletion',
          message: data.message,
          type: 'success'
        });
        const others = details.filter((item) => id !== item.id);
        setDetails(others);
      } else {
        notification({
          title: 'Request Deletion Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while deleting a Request. Please, try again later.',
        type: 'danger'
      });
    }
    setIsDeleting(false);
  };

  const statusOptions = [
    { name: 'All Requests', value: '' },
    { name: 'Actioned', value: 'actioned' },
    { name: 'Assigned', value: 'assigned' },
    { name: 'Pending', value: 'pending' },
    { name: 'Declined', value: 'declined' }
  ];

  

  return (
    <div className="w-100" tabIndex={-1} ref={focusRef}>
      <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between m-t-20">
        <h3>
          Request Manager -&nbsp;
          {selectedDate === 'Calendar'
            ? formatLongDate(moment(calendarDate).format('YYYY-MM-DD'))
            : selectedDate}
        </h3>
      </div>
      <div className="w-100 flex space-between flex-v-center m-t-20">
        <div className="flex">
          <SelectInputColored
            className="m-r-10"
            options={['All Requests', 'Today', 'This Week', 'This Month', 'Calendar']}
            value={selectedDate}
            onChange={handleDate}
          />
          <CalendarBtn value={calendarDate} onChange={(val) => handleCalendarDate(val)} />
        </div>
        <div className="flex flex-v-center">
          <SelectInputColored
            className="m-r-10"
            options={statusOptions || []}
            value={status}
            onChange={handleSearch}
            name="status"
          />
          <SearchInput
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            className="m-l-5"
            placeholder="Search list..."
          />
        </div>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th"><p>Title</p></div>
            <div className="th"><p>Upload Date</p></div>
            <div className="th"><p>Vendor/Source</p></div>
            <div className="th"><p>Status</p></div>
            <div className="th"><p>Last Treated</p></div>
            <div className="th" />
          </div>
        </div>
        <div className="table-body">
          {!(isLoading || errorMsg) && details && details.filter((request)=>{return request.status.includes(status)}).map((item) => (
            <div className="table-row" key={item.id}>
              <div className="td">{item.title}</div>
              <div className="td">{formatDate(item.createdAt)}</div>
              <div className="td">{item.vendor}</div>
              <div className="td">
                <div className={`status ${item.status}-status`}>
                  {formatStatus(item.status)}
                </div>
              </div>
              <div className="td">{formatSimpleDate(item.updatedAt || item.createdAt)}</div>
              <div className="td actions flex flex-v-center flex-end">
                {item.status === 'actioned' && (
                  <button
                    className="booking-btn pointer m-r-5"
                    type="button"
                    onClick={() => bookMeeting(item)}
                  >
                    Book a meeting
                  </button>
                )}
                <button
                  data-tip
                  data-for="view-btn"
                  type="button"
                  className="action-btn pointer"
                  onClick={() => showPreview(item.id)}
                >
                  <AiOutlineFundProjectionScreen />
                </button>
                
                  
               
                {(item.status === 'pending' || item.status === 'declined') && (
                  <>
                    <button
                      data-tip
                      data-for="edit-btn"
                      type="button"
                      className="m-l-10 pointer action-btn"
                      onClick={() => showEditForm(item.id)}
                    >
                      <BiEditAlt />
                    </button>
                    
                      
                  
                    <button
                      data-tip
                      data-for="delete-btn"
                      type="button"
                      className="m-l-10 del pointer action-btn"
                      onClick={() => deleteItem(item.id, item.title)}
                    >
                      <GiTrashCan />
                    </button>
                   
                      
                  
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ marginTop: "15px", display: "flex", alignItems: 'flex-end', textAlign: 'right' }}>
        <Pagination onChange={handlePaginationChange} defaultCurrent={1} total={paginationLength} />
      </div>



      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      {(isLoading || isDeleting) && <IsLoading message={isDeleting ? 'Deleting...' : null} />}
      {viewDetails && (
        <RequestModal
          requestId={selectedRecord.id}
          updateList={updateList}
          closeModal={closeModal}
          update={()=>setListUpdate(!listUpdate)}
        />
      )}
    </div>
  );
};

export default RequestManager;
