/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable no-trailing-spaces */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import moment from "moment-timezone";
import Cookies from 'js-cookie'
import axios from "axios";
import { BsUpload } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import Loader from "../../assets/svg/loading.svg";
import TextareaInput from "../inputs/TextareaInput";
import AutoComplete from "../inputs/AutoComplete";
import { convertImgToBase64, toggleScroll } from "../../utility/general";
import notification from "../../utility/notification";
import { get, put } from "../../api-services/fetch";
import UploadedFileItem from "./UploadedFileItem";
import { SwapTableContext } from "../Contexts/SwapTableContext";
import { uploadDocument } from "../../api-services/otherApis";
import Select from "react-select";
// import { decodeToken } from '../../utility/auth';
const BASE_API_URL = `https://edogoverp.com/services/api`
const RequestAssignmentForm = ({
  closeModal,
  request,
  commentId,
  updateList,
  handleDetails,
  current,
  currentId,
  updated
}) => {
  const [assigneeId, setAssigneeId] = useState("");
  const [comment, setComment] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [uploadedDocuments] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [mda, setMda] = useState([]);
  const [mdaList, setMdaList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [b64FileArray, setB64FileArray] = useState([]);
  const { imageHolder } = useContext(SwapTableContext);

  useEffect(() => {
    toggleScroll();
    // getEmployeeList();
    loadMda();
    return () =>
      setTimeout(() => {
        toggleScroll();
      }, 0);
  }, []);


  const loadMda = async () => {
    const res = await get({ endpoint: "allMdaDrop", auth: true });
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // setMda(data.data);
        const roleList = data?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setMdaList(roleList)
      } else {
        notification({
          title: "Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something has gone wrong when fetching the document journey. Please, try again.",
        type: "danger",
      });
    }
  };

  const getEmployeesFromMda = (id) => {
    console.log(id)
    axios
      .get(`${BASE_API_URL}/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {

        console.log("--employeesByNads--")
        console.log(response?.data)
        // this.setState({
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setEmployeeList(roleList.filter((item) => { return !item.name.includes(Cookies.get('fullname')) }));

        // setMdaList(roleList)

        // });
      })
  }

  // const getEmployeeList = async () => {
  //   const res = await get({ endpoint: "employee", auth: true });
  //   console.log(res);

  //   if (res && res.status === 200) {
  //     const { data } = res;
  //     console.log(res)
  //     if (data.code === 1) {
  //       const roleList = data.data.map((item) => ({
  //         name: `${item.firstName} ${item.lastName} ${
  //           item.role ? item.role : ""
  //         } ${item.mda}`,
  //         value: item.id,
  //       }));
  //       console.log(roleList);
  //       setEmployeeList(roleList.filter((item)=>{return !item.name.includes(Cookies.get('fullname'))}));
  //     } else {
  //       notification({
  //         title: "Error Getting List of Employees",
  //         message: data.message,
  //         type: "danger",
  //       });
  //     }
  //   } else {
  //     notification({
  //       title: "Network",
  //       message: "Something has gone wrong. Please, try again.",
  //       type: "danger",
  //     });
  //   }
  // };

  const validateForm = () => {
    if (!assigneeId || assigneeId === undefined) return "Please, enter the name of the Assignee";
    if (!comment) return "Please, enter a comment/closing remark";
    return null;
  };
  // function retrieveui() {
  //   let ui = localStorage.getItem('ui');
  //   ui = decodeToken('ui').ui;
  //   return ui;
  // }
  // const comCall = async () => {
  //   const body = {
  //     assignee: assigneeId,
  //     comment,
  //     requestId: request.id
  //   };

  //   const res = await post({
  //     endpoint: 'assign', body, auth: true
  //   });
  //   console.log(res, 'post');

  //   if (res && res.status === 200) {
  //     const { data } = res;
  //     if (data.code === 1) {
  //       notification({
  //         title: 'Successful Request Assignment',
  //         message: data.message,
  //         type: 'success'
  //       });
  //       setAssigneeId('');
  //       const updatedItem = { comment, updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ') };
  //       updateList(updatedItem);
  //       closeModal();
  //     } else {
  //       notification({
  //         title: 'Request Assignment Error',
  //         message: data.message,
  //         type: 'danger'
  //       });
  //     }
  //   } else {
  //     notification({
  //       title: 'Network Error',
  //       message: 'Something went wrong while assigning a Request to an employee. Please, try again later.',
  //       type: 'danger'
  //     });
  //   }
  //   setIsLoading(false);
  // };

  // file uploadd
  const handleFilesUpload = async () => {
    const uploadField = document.getElementById("image-upload");

    uploadField.click();
    uploadField.onchange = async () => {
      if (!uploadField.files[0]) return;
      const { type, size } = uploadField.files[0];
      console.log(type, size, uploadField.files[0]);

      const supportedTypes = [
        "jpeg",
        "png",
        "gif",
        "pdf",
        "xls",
        "xlx",
        "csv",
        "vnd.ms-excel",
        "xlsx",
        "vnd.openxmlformats-officedocument.wordprocessingml.document",
        "doc",
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "ppt",
        "pptx"
      ];
      const fileType = type.slice(type.indexOf("/") + 1);

      console.log(fileType);
      if (b64FileArray.length + uploadedDocuments.length > 30) {
        notification({
          title: "File Limit Exceeded",
          message: "You can only upload maximum of 30 documents for an idea.",
          type: "danger",
        });
        return;
      }
      if (!supportedTypes.includes(fileType)) {
        notification({
          title: "Invalid File Type",
          message:
            "Invalid file format. Supported file types are pdf, docx, doc, xlsx, jpeg, ppt, pptx, png and gif",
          type: "danger",
        });
        return;
      }
      if (size / 1024 > 500000) {
        notification({
          title: "File Too Large",
          message: "The file size must not be more than 500MB",
          type: "danger",
        });
        return;
      }
      const fileBase64 = await convertImgToBase64(uploadField.files[0]);
      const b64FileSet = new Set(b64FileArray);
      const setSize = b64FileSet.size;
      const newFileList = [...fileArray];

      b64FileSet.add(fileBase64);

      if (setSize < b64FileSet.size) {
        newFileList.push(uploadField.files[0]);
      }

      setB64FileArray([...b64FileSet]);
      setFileArray(newFileList);
    };
  };

  const handleFileDelete = async (index) => {
    console.log(b64FileArray, fileArray);
    const newB64list = b64FileArray.filter((val, i) => i !== index);
    const newFileArray = fileArray.filter((val, i) => i !== index);

    setB64FileArray(newB64list);
    setFileArray(newFileArray);
  };

  const submitForm = async () => {
    const payload = {
      assignee: assigneeId.value,
      comment,
      requestId: request.id,
      documents: fileArray
    };
    console.log(payload, "playyyy");
    const error = validateForm();

    if (error) {
      notification({
        title: "Invalid Entry",
        message: error,
        type: "danger",
      });
      return;
    }

    setIsLoading(true);
    const res = await put({
      endpoint: "assign",
      param: commentId,
      body: payload,
      auth: true,
    });
    console.log(res, "put");

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // comCall();
        notification({
          title: "Successful Request Assignment",
          message: data.message,
          type: "success",
        });

        const updatedItem = {
          comment,
          updatedAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        };

        updateList(updatedItem);

        updated && updated();

        if (
          fileArray.length > 0 ||
          (imageHolder.name != "" && imageHolder.doclink != 0)
        ) {
          // upload documents then update the request details
          setIsLoading(true);
          notification({
            title: "Uploading Documents",
            message: "Currently uploading the attached documents...",
            type: "success",
          });
          const uploadedDocs = fileArray.map(async (file) => {
            console.log(file);
            const body = new FormData();
            body.append("files", file);
            const response = await uploadDocument(body);

            console.log(response);
            if (response && response.status === 200) {
              if (response.data.code === 1) {
                return {
                  name: file.name,
                  path: response.data.doclink,
                  id: response.data.id,
                };
              }
              notification({
                title: "Document Upload Failed",
                message: response.data.message,
                type: "danger",
              });
            } else {
              notification({
                title: "Network Error",
                message:
                  "Something went wrong while uploading the documents for this request. Please, try again later.",
                type: "danger",
              });
            }
            return null;
          });

          if (
            (imageHolder.name != "" ||
              imageHolder.name != null ||
              imageHolder.name != undefined) &&
            (imageHolder.docklink != "" ||
              imageHolder.docklink != null ||
              imageHolder.docklink != undefined)
          ) {
            uploadedDocs.push({
              name: imageHolder.name,
              path: imageHolder.doclink,
              // id: response.data.id
            });
          } else {
            notification({
              title: "Upload Error",
              message:
                "Something went wrong while uploading document from M-FILES",
              type: "danger",
            });
          }

          Promise.all(uploadedDocs).then(async (resp) => {
            console.log(uploadedDocs, resp);
            payload.documents = resp;
            console.log(payload.documents);
            const updateRes = await put({
              endpoint: "assign",
              body: payload,
              auth: true,
              param: commentId,
            });
            setIsLoading(true);
            console.log(updateRes);

            if (updateRes && updateRes.status === 200) {
              if (updateRes.data.code === 1) {
                notification({
                  title: "Uploading Documents Successful",
                  message:
                    "You have successfully uploaded the documents for this request",
                  type: "success",
                });
              } else {
                notification({
                  title: "Document Upload Failed",
                  message: updateRes.data.message,
                  type: "danger",
                });
              }
            } else {
              notification({
                title: "Network Error",
                message:
                  "Something went wrong while uploading the documents for this request. Please, try again later.",
                type: "danger",
              });
            }
          });
        }
        closeModal();
      } else {
        notification({
          title: "Request Assignment Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something went wrong while assigning a Request to an employee. Please, try again later.",
        type: "danger",
      });
    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-600">
        <div className="content">
          {/* <AutoComplete
            label="Select name of Assignee*"
            options={employeeList || []}
            value={assigneeId}
            onChange={(value) => setAssigneeId(value)}
            className="w-100 m-b-10"
          /> */}
          {/* <AutoComplete
                  label="Assignee MDA*"
                  options={mdaList || []}
                  value={mda}
                  // isShow={false}
                  onChange={(value) => {
                    getEmployeesFromMda(value);
                    setMda(value);
                    
                  
                  }}
                  className="w-100 m-b-10"
                /> */}
          <div className="w-100 m-b-10">
            <Select
              // isMulti
              placeholder="Select MDA"

              className="basic-multi-select w-100 m-b-10"
              onChange={(value) => {
                setMda(value);
                console.log(value);
                getEmployeesFromMda(value.value);
              }}
              value={mda}
              options={mdaList.map((m) => ({
                label: m.name,
                value: m.value,
              }))}
            />
          </div>

          <div className="w-100 m-b-10">
            <Select
              // isMulti
              placeholder="Select Assignee"
              className="basic-multi-select"
              onChange={(value) => {
                setAssigneeId(value);
                console.log(value);
                getEmployeesFromMda(value.value);
              }}
              value={assigneeId}
              options={employeeList.map((m) => ({
                label: m.name,
                value: m.value,
              }))}
            />
          </div>

          {/* <AutoComplete
                  label="Assignee*"
                  options={employeeList || []}
                  value={assigneeId}
                  isShow={false}
                  onChange={(value) => setAssigneeId(value)}
                  className="w-100 m-b-10"
                /> */}
          <TextareaInput
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="w-100 m-b-10"
            rows={4}
            label="Comment*"
          />

          <div className="uploadDiv">
            <button
              type="button"
              className="btn w-100 btn-large flex flex-v-center space-between"
              onClick={handleFilesUpload}
            >
              Upload Document
              <BsUpload />
              <input type="file" hidden id="image-upload" />
            </button>
            <div className="m-t-20">
              <h4>Uploaded Documents</h4>
            </div>
            <div>
            
            </div>
            <div className="w-100 m-t-10 m-b-20">
              {fileArray &&
                fileArray.map((file, i) => (
                  <div className="flex idea-attachment m-t-10" key={file.name}>
                    <a
                      className="gray underline flex"
                      href={b64FileArray[i]}
                      alt="Idea attached file"
                      download
                    >
                      {file.name}
                    </a>
                    <RiDeleteBin5Line
                      className="pointer m-l-5"
                      style={{ fontSize: "1.2rem" }}
                      onClick={() => handleFileDelete(i)}
                    />
                  </div>
                ))}
              {uploadedDocuments &&
                uploadedDocuments.map((file) => (
                  <UploadedFileItem
                    details={file}
                    type="request"
                    status="pending"
                    key={file.id}
                  />
                ))}
            </div>
            <p>
              Supported file formats: jpeg , png , gif , pdf, doc , docx, xls, xlsx , ppt <br />
              Maximum File Size: 500MB
            </p>
          </div>

          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}
          >
            Assign Request
            {isLoading && (
              <img src={Loader} className="btn-loading" alt="Loading..." />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestAssignmentForm;
