/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import CalendarBtn from './CalendarBtn';
import { get } from '../../api-services/fetch';
import { formatDateforPicker, formatLongDate, formatTime } from '../../utility/dateTime';

const CheckCalendar = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [startDate, setStartDate] = useState(formatDateforPicker(new Date()));
  const [endDate, setEndDate] = useState(formatDateforPicker(new Date()));

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Meeting Manager';

    const getList = async () => {
      setIsLoading(true);
      setErrorMsg('');
      const res = await get({ endpoint: 'meeting', pQuery: { startDate, endDate, status: 'booked' } });

      console.log(res);

      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          setDetails(data.data);
          if (data.data.length < 1) {
            setErrorMsg('No Meeting');
          }
        } else {
          setErrorMsg(data.message);
        }
      } else {
        setErrorMsg('Something has gone wrong. Please, try again.');
      }
      setIsLoading(false);
    };

    getList();
  }, [startDate, endDate]);

  const handleCalendarDate = async (val) => {
    setCalendarDate(val);
    setStartDate(formatDateforPicker(val));
    setEndDate(formatDateforPicker(val));
  };

  console.log(details);
  return (
    <div className="modal-box max-w-400 meeting-booker-calendar m-l-20">
      <div className="w-100 flex flex-v-center header">
        <CalendarBtn value={calendarDate} onChange={(val) => handleCalendarDate(val)} />
        <p>{formatLongDate(moment(calendarDate).format('YYYY-MM-DD'))}</p>
      </div>
      <div className="calendar-meeting-list">
        {!(isLoading || errorMsg) && details && details.map((item) => (
          <div className="flex item" key={item.id}>
            <div className="time m-r-10">
              {`${formatTime(item.startTime)} - ${formatTime(item.endTime)}`}
            </div>
            <div className="title">{item.title}</div>
          </div>
        ))}
        {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
        {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      </div>
    </div>
  );
};

export default CheckCalendar;
