/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { MdNotifications } from 'react-icons/md';
import { TiArrowSortedDown } from 'react-icons/ti';

import UserAvater from "../../assets/images/user-avater.png";
// import HeaderSearch from '../inputs/HeaderSearch';

import { formatFileUrl } from '../../utility/general';
import { decodeToken } from '../../utility/auth';
import Cookies from 'js-cookie';

const Header = ({ history }) => {
  const [userPix, setUserPix] = useState('');
  const [imgHasError, setImgHasError] = useState(false);

  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [avatar, setAvatar] = useState('')
  

  useEffect(() => {
    setAvatar(Cookies.get('profilePicture'))
    const r = localStorage.getItem('r');
    const pp = localStorage.getItem('pp');
    let fn = localStorage.getItem('fn');
    let ln = localStorage.getItem('ln');
    
    r && setRole(decodeToken('r').r);
    if (fn && ln) {
      fn = decodeToken('fn').fn;
      ln = decodeToken('ln').ln;
      setUsername(`${fn} ${ln}`);
    }
    if (pp) {
      !imgHasError && setUserPix(formatFileUrl(decodeToken('pp').pp));
    }
  }, [imgHasError, userPix, ]);

  const handleImgError = () => {
    setUserPix('');
    setImgHasError(true);
  };
  console.log(avatar)
  console.log(avatar!==null)
  console.log(avatar===null)

  return (
    <header>
      <div className="content header-content space-between flex-v-center">
        <div className="header-left">
          {/* <HeaderSearch placeholder="Search transactions or invoices" /> */}
        </div>
        <div className="header-right flex flex-v-center">
          {/* <div className="right-item notification flex p-r">
            <MdNotifications />
            <span className="indicator" />
          </div> */}
          {/* <div className="right-item">
            <ul className="nav-menu">
              <li><a href={process.env.REACT_APP_INDEX_URL}>Home</a></li>
            </ul>
          </div> */}
          <div className="right-item">
            <div
              role="presentation"
              onClick={() => {}}
              className="user-avater-details flex flex-v-center pointer"
            >
              <div className="m-r-5 right-text" style={{ paddingTop: '3px' }}>
                <p className="name">{username || 'N/A'}</p>
                <p className="role">{role || 'N/A'}</p>
              </div>
              <div className="flex">
                {avatar === 'null' ? <img src={UserAvater} alt="user avater" /> :(avatar === '') ? <img src={UserAvater} alt="user avater" /> : (avatar === undefined) ? <img src={UserAvater} alt="user avater" /> : <img src={avatar} alt="user avater" />}

              </div>
              <div className="flex arrow">
                <TiArrowSortedDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
