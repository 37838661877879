/* eslint-disable linebreak-style */
/* eslint-disable max-len */
// /* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import moment from 'moment-timezone';

import Thread from './Thread';
import Loader from '../../assets/svg/loading.svg';
import TextareaInput from '../inputs/TextareaInput';
import AutoComplete from '../inputs/AutoComplete';
import IsLoading from '../common/IsLoading';
import { toggleScroll } from '../../utility/general';
import notification from '../../utility/notification';
import { post, get } from '../../api-services/fetch';
import { decodeToken } from '../../utility/auth';

const QueryThread = ({
  closeModal
}) => {
  const [assigneeId, setAssigneeId] = useState('2');
  const [comment, setComment] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  const [subject, setSubject] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [threadData, setThreadData] = useState([]);
  const [chat, setChat] = useState([]);
  const [showThread, setShowThread] = useState(false);
  const [arrayOption, setArrayOption] = useState([]);
  const [initialPage, setInitialPage] = useState(0);

  console.log(initialPage);
  function retrieveui() {
    let ui = localStorage.getItem('ui');
    ui = decodeToken('ui').ui;
    return ui;
  }

  console.log(chat);
  useEffect(() => {
    toggleScroll();
    fetchQuery();

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, []);

  const fetchQuery = async () => {
    setIsLoading(true);
    const res = await get({
      endpoint: 'querylist', pQuery: { userId: retrieveui(), pageNumber: 1, pageSize: 10 }, auth: true
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      console.log(data);
      if (data.code === 0) {
        notification({
          title: 'Successful',
          message: data.message,
          type: 'success'
        });
        setThreadData(data);
        setInitialPage(data.totalPages);
      } else {
        notification({
          title: 'Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while assigning a Request to an employee. Please, try again later.',
        type: 'danger'
      });
    }
    setIsLoading(false);
  };

  const handleThread = async (id) => {
    console.log(id);
    setIsLoading(true);
    setShowThread(true);

    const res = await get({
      endpoint: 'queryThread',
      pQuery: {
        userId: retrieveui(), queryId: id, pageNumber: 0, pageSize: 0
      },
      auth: true
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 0) {
        setChat(data);
      } else {
        notification({
          title: 'Error Getting Messages',
          message: data.message,
          type: 'danger'
        });
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      notification({
        title: 'Network',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const handlePage = async (page) => {
    console.log(page);
    setIsLoading(true);
    const res = await get({
      endpoint: 'querylist', pQuery: { userId: retrieveui(), pageNumber: page, pageSize: 10 }, auth: true
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      console.log(data);
      if (data.code === 0) {
        // notification({
        //   title: 'Successful',
        //   message: data.message,
        //   type: 'success'
        // });
        setThreadData(data);
      } else {
        notification({
          title: 'Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while assigning a Request to an employee. Please, try again later.',
        type: 'danger'
      });
    }
    setIsLoading(false);
  };

  // const select = document.querySelector('.selector');
  // console.log(select);
  // const fillselect = (page) => {
  //   select.innerHTML = '';
  //   // eslint-disable-next-line no-plusplus
  //   for (let i = 1; i <= page; i++) {
  //     const opt = document.createElement('option');
  //     opt.textContent = i;
  //     opt.value = i;
  //     select.append(opt);
  //   }
  // };
  // if (threadData) {
  //   fillselect(threadData.totalPages);
  // }

  // const loadOption = (num) => {
  //   const options = [];

  //   // eslint-disable-next-line no-plusplus
  //   for (let index = 1; index <= num; index++) {
  //     options.push(index);
  //   }
  //   setArrayOption(options);
  // };

  // loadOption(5);
  // console.log(arrayOption);

  const options = new Array(initialPage || 0);
  options.fill();

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-600">
        <div className="content">
          <div
            className="allSubject"
            style={{
              display: 'flex', width: '100%', paddingTop: '30px', flexDirection: 'column'
            }}
          >
            <div style={{
              width: '100%', display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'flex-end'
            }}
            >
              <p style={{ fontSize: '16px', marginRight: '10px' }}>Select Page</p>
              <select
                className="selector"
                onChange={(e) => {
                  console.log(`page ${e.target.value}`);
                  handlePage(e.target.value);
                }}
                style={{
                  padding: '8px 16px', borderRadius: '2px', color: 'white', background: '#1c811c'
                }}
                id=""
              >
                {options.map((val, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={index} value={index + 1}>{index + 1}</option>
                ))}
              </select>
            </div>

            <div
              className="createdThread"
              style={{
                display: 'flex', width: '100%', flexDirection: 'column', marginBottom: '30px'
              }}
            >
              <p style={{
                textAlign: 'center', fontSize: '20px', fontWeight: '800', marginBottom: '40px'
              }}
              >
                Created Queries
              </p>

              {
                // eslint-disable-next-line array-callback-return
                threadData.data ? threadData.data.filter((item) => item.isCreated).map((data) => (
                  <div
                    key={data.id}
                    style={{
                      width: '100%', marginBottom: '30px', paddingBottom: '20px', borderBottom: '1px solid #1c811c'
                    }}
                    className="eachSubject"
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="topicBox">

                      <p className="topic">
                        Subject:

                        <span>
                          {' '}
                          {data.subject}
                        </span>
                        <br />
                        <br />
                        To:

                        <span>{data.assigneeName}</span>
                      </p>

                      <p
                        className="unread"
                        style={{
                          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px', height: '20px', borderRadius: '50%', background: '#1c811c', color: 'white'
                        }}
                      >
                        {data.totalComment}
                      </p>

                    </div>

                    <div
                      className="coverBtn"
                      style={{
                        display: 'flex', width: '100%', marginTop: '20px', justifyContent: 'space-between'
                      }}
                    >

                      <button
                        type="button"
                        className="btn btn-large w-100"
                        style={{ width: '100%' }}
                        onClick={() => { handleThread(data.id); }}
                      >
                        View Thread
                        {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
                      </button>

                    </div>

                  </div>

                )) : <div><p>No Query</p></div>

              }

            </div>

            <div
              className="receivedThread"
              style={{
                display: 'flex', width: '100%', flexDirection: 'column', marginBottom: '30px'
              }}
            >
              <p style={{
                textAlign: 'center', fontSize: '20px', fontWeight: '800', marginBottom: '40px'
              }}
              >
                Received Queries
              </p>

              {
                // eslint-disable-next-line array-callback-return
                threadData.data ? threadData.data.filter((item) => !item.isCreated).map((data) => (
                  <div
                    key={data.id}
                    style={{
                      width: '100%', marginBottom: '30px', paddingBottom: '20px', borderBottom: '1px solid #1c811c'
                    }}
                    className="eachSubject"
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="topicBox">

                      <p className="topic">
                        Subject:

                        <span>
                          {' '}
                          {data.subject}
                        </span>
                        <br />
                        <br />
                        To:

                        <span>{data.assigneeName}</span>
                      </p>

                      <p
                        className="unread"
                        style={{
                          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px', height: '20px', borderRadius: '50%', background: '#1c811c', color: 'white'
                        }}
                      >
                        {data.totalComment}
                      </p>

                    </div>

                    <div
                      className="coverBtn"
                      style={{
                        display: 'flex', width: '100%', marginTop: '20px', justifyContent: 'space-between'
                      }}
                    >

                      <button
                        type="button"
                        className="btn btn-large w-100"
                        style={{ width: '100%' }}
                        onClick={() => { handleThread(data.id); }}
                      >
                        View Thread
                        {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
                      </button>

                    </div>

                  </div>

                )) : <div style={{ textAlign: 'center' }}><p>No Query</p></div>

              }
            </div>

            {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
            {isLoading && <IsLoading />}

          </div>

          {/* <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}
          >
            Issue Queryhhhhh
            {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
          </button> */}
        </div>
      </div>
      {showThread && (
        <Thread
          chat={chat}
          handle={handleThread}
          userID={retrieveui()}
          closeModal={() => setShowThread(false)}
        />
      )}
    </div>
  );
};

export default QueryThread;
