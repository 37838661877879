/* eslint-disable linebreak-style */
import React from 'react';

const PresentValue = ({
  label, value, className, indent
}) => (
  <div className={`${className || ''} w-100 present-value m-b-10`}>
    <p className="label">{label || 'Label'}</p>
    <p className={`value m-b-5 ${indent ? 'indent' : ''}`}>
      {value && value.length > 0 ? value : '...'}
    </p>
  </div>
);

export default PresentValue;
