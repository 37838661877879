/* eslint-disable linebreak-style */
import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';
import ProtectedRoute from './ProtectedRoute';
import SessionHOC from '../components/hoc/SessionHOC';
import Dashboard from '../components/pages/Dashboard';
import RequestManager from '../components/pages/RequestManager';
import GeneralRequests from '../components/pages/GeneralRequests';
import MeetingRequest from '../components/pages/MeetingRequest';
import MeetingManager from '../components/pages/MeetingManager';
import CreateRequest from '../components/pages/CreateRequest';

export default pure(() => (
  <Switch>
    <ProtectedRoute
      path="/schedule-manager/"
      exact
      component={SessionHOC(Dashboard)}
    />
    <ProtectedRoute
      path="/schedule-manager/create-request"
      exact
      component={SessionHOC(CreateRequest)}
    />
    <ProtectedRoute
      path="/schedule-manager/update-request/:requestId"
      exact
      component={SessionHOC(CreateRequest)}
    />
    <ProtectedRoute
      path="/schedule-manager/update-meeting/:meetingId"
      exact
      component={SessionHOC(CreateRequest)}
    />
    <ProtectedRoute
      path="/schedule-manager/requests"
      exact
      component={SessionHOC(GeneralRequests)}
    />
    <ProtectedRoute
      path="/schedule-manager/request-manager"
      exact
      component={SessionHOC(RequestManager)}
    />
    <ProtectedRoute
      path="/schedule-manager/meeting-request"
      exact
      component={SessionHOC(MeetingRequest)}
    />
    <ProtectedRoute
      path="/schedule-manager/meeting-manager"
      exact
      component={SessionHOC(MeetingManager)}
    />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
