/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';

import { toggleScroll } from '../../utility/general';
import RequestDetails from './RequestDetails';

const RequestModal = ({ requestId, closeModal, updateList,update }) => {
  useEffect(() => {
    toggleScroll();

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, [requestId]);

  return (
    <div className="overlay" style={{ textAlign: 'left' }}>
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <RequestDetails requestId={requestId} updateList={updateList} updated={update}/>
    </div>
  );
};

export default RequestModal;
