/* eslint-disable linebreak-style */
const paths = {
  login: '/api/auth',
  request: '/api/request',
  requests: '/api/requests',
  meeting: '/api/meeting',
  specificMail: "/api/Memo/memo",
  assign: '/api/request-assignment',
  assign2: '/api​/request-assignment​/request-assignments',
  employee: '/api/employee/profile',
  mda: '/api/employee/mdas',
  eachMda: '/api/request/document/mda',
  eachCategory: '/api/request/document/category',
  oneCat: '/api/request',
  allCategoryCount: '/api/meeting-category/user',
  allMdaCount: '/api/meeting-category/mda/user',
  allMdaDrop: '/api/employee/mdas',
  allCatDrop: '/api/meeting-category',
  getrequest: '/api/request',
  comment: '/api/request/action-comment',
  queryform: '/api/query',
  querylist: '/api/query/user',
  queryThread: '/api/query/replies/user',
  queryReply: '/api/query/post-reply',
  followUp: '/api/request/follow-up',
  followUprep: '/api/request/follow-up/replies',
  broadcast: '/api/request/broadcast',
  assignMulti: '/api/request-assignment/request-assignments',
  assignMultis: '/api/request-assignment/multiple',
  meetingAssign: '/api/request-assignment/meeting-assignments',
  resetComment: "/api/request/update-comment"
  // meetingAssign: '/api/request-assignment/meeting-assignments'
};

export default paths;
