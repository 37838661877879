/* eslint-disable linebreak-style */
import React, { useState, useRef } from 'react';
import { GoCalendar } from 'react-icons/go';
import Calendar from 'react-calendar';

import '../../assets/css/calendar.css';
import useClickOutsideBox from '../hooks/useClickOutsideBox';

const CalendarBtn = ({ onChange, value }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const wrapperRef = useRef(null);

  useClickOutsideBox(wrapperRef, () => setShowCalendar(false));

  const handleChange = (val) => {
    onChange(val);
    setShowCalendar(!showCalendar);
  };

  return (
    <div className="p-r flex">
      <GoCalendar
        className="pointer"
        style={{ fontSize: '2rem', marginRight: '10px' }}
        onClick={() => setShowCalendar(!showCalendar)}
      />
      {showCalendar && (
        <div className="overlay">
          <div className="calendar" ref={wrapperRef}>
            <Calendar
              onChange={handleChange}
              value={value}
              className="calendar"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarBtn;
