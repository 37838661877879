/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
import React, { useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import moment from "moment-timezone";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BsUpload } from "react-icons/bs";
import Loader from "../../assets/svg/loading.svg";
import TextareaInput from "../inputs/TextareaInput";
// import AutoComplete from '../inputs/AutoComplete';
import { convertImgToBase64, toggleScroll } from "../../utility/general";
import notification from "../../utility/notification";
import { post } from "../../api-services/fetch";
import { uploadDocument } from "../../api-services/otherApis";
import { SwapTableContext } from "../Contexts/SwapTableContext";
import UploadedFileItem from "./UploadedFileItem";


const RequestCommentForm = ({
  closeModal,
  request,
  updateList,
  status,
  handleDetails,
  current,
  currentId,
  updated
}) => {

  const [comment, setComment] = useState("");

  const [uploadedDocuments] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [b64FileArray, setB64FileArray] = useState([]);
  const { imageHolder } = useContext(SwapTableContext);

  useEffect(() => {
    toggleScroll();
    // getEmployeeList();

    return () =>
      setTimeout(() => {
        toggleScroll();
      }, 0);
  }, []);



  const validateForm = () => {
    if (!comment) return "Please, enter a comment/closing remark";
    return null;
  };
  console.log(status, "statussssssssssssss");

  // file uploadd
  const handleFilesUpload = async () => {
    const uploadField = document.getElementById("image-upload");

    uploadField.click();
    uploadField.onchange = async () => {
      if (!uploadField.files[0]) return;
      const { type, size } = uploadField.files[0];
      console.log(type, size, uploadField.files[0]);

      const supportedTypes = [
        "jpeg",
        "png",
        "gif",
        "pdf",
        "xls",
        "csv",
        "xlx",
        "vnd.ms-excel",
        "xlsx",
        "ppt",
        "pptx",
        "vnd.openxmlformats-officedocument.wordprocessingml.document",
        "doc",
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      const fileType = type.slice(type.indexOf("/") + 1);

      console.log(fileType);
      if (b64FileArray.length + uploadedDocuments.length > 30) {
        notification({
          title: "File Limit Exceeded",
          message: "You can only upload maximum of 30 documents for an idea.",
          type: "danger",
        });
        return;
      }
      if (!supportedTypes.includes(fileType)) {
        notification({
          title: "Invalid File Type",
          message:
            "Invalid file format. Supported file types are pdf, docx, ppt, pptx, doc, xlsx, jpeg, png and gif",
          type: "danger",
        });
        return;
      }
      if (size / 1024 > 500000) {
        notification({
          title: "File Too Large",
          message: "The file size must not be more than 500MB",
          type: "danger",
        });
        return;
      }
      const fileBase64 = await convertImgToBase64(uploadField.files[0]);
      const b64FileSet = new Set(b64FileArray);
      const setSize = b64FileSet.size;
      const newFileList = [...fileArray];
      b64FileSet.add(fileBase64);
      if (setSize < b64FileSet.size) {
        newFileList.push(uploadField.files[0]);
      }
      setB64FileArray([...b64FileSet]);
      setFileArray(newFileList);
    };
  };

  const handleFileDelete = async (index) => {
    console.log(b64FileArray, fileArray);
    const newB64list = b64FileArray.filter((val, i) => i !== index);
    const newFileArray = fileArray.filter((val, i) => i !== index);
    setB64FileArray(newB64list);
    setFileArray(newFileArray);
  };

  const submitForm = async () => {

    const error = validateForm();

    if (error) {
      notification({
        title: "Invalid Entry",
        message: error,
        type: "danger",
      });
      return;
    }

    setIsLoading(true);

    if (!fileArray.length > 0) {
      const bodyPay = {
        comment,
        meetingRequestId: request.id,
        status,
      };

      const res = await post({
        endpoint: "comment",
        body: bodyPay,
        auth: true,
      });
      console.log(res);

      if (res && (res.status === 200 || res.message === "OK")) {
        const { data } = res;
        if (data.code === 0) {
          notification({
            title: "Successful",
            message: data.message,
            type: "success",
          });

          setComment("");
          const updatedItem = {
            comment,
            updatedAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          };
          updateList(updatedItem);
          updated();

        }
        closeModal();
      } else {
        notification({
          title: "Error",
          message: res.data.message,
          type: "danger",
        });
      }
    }
    else if (fileArray.length > 0) {
      const bodyPay = {
        comment,
        meetingRequestId: request.id,
        status,
        documents: []
      };

      setIsLoading(true);
      notification({
        title: "Uploading Documents",
        message: "Currently uploading the attached documents...",
        type: "success",
      });
      const uploadedDocs = fileArray.map(async (file) => {
        console.log(file);
        const body = new FormData();
        body.append("files", file);
        const response = await uploadDocument(body);

        console.log(response);
        // if(response.status === 200){
        //   notification({
        //   title: "Success",
        //   message:response?.data?.message,
        //   type: "success",
        // });
        // }

        if (response && response.status === 200) {
          if (response.data.code === 1) {
            return {
              name: file.name,
              path: response.data.doclink,
              id: response.data.id,
            };
          }
          notification({
            title: "Document Upload Failed",
            message: response.data.message,
            type: "danger",
          });
        } else {
          notification({
            title: "Network Error",
            message:
              "Something went wrong while uploading the documents for this request. Please, try again later.",
            type: "danger",
          });
        }
        return null;
      });

      if (
        (imageHolder.name !== "" ||
          imageHolder.name !== null ||
          imageHolder.name !== undefined) &&
        (imageHolder.docklink !== "" ||
          imageHolder.docklink !== null ||
          imageHolder.docklink !== undefined)
      ) {
        // uploadedDocs.push({
        //   name: imageHolder.name,
        //   path: imageHolder.doclink,
        //   // id: response.data.id
        // });
      } else {
        notification({
          title: "Upload Error",
          message:
            "Something went wrong while uploading document from M-FILES",
          type: "danger",
        });
      }

      Promise.all(uploadedDocs).then(async (resp) => {
        console.log(uploadedDocs, resp);
        bodyPay.documents = resp;
        console.log(bodyPay.documents);
        const updateRes = await post({
          endpoint: "comment",
          body: bodyPay,
          auth: true,
          // param: commentId,
        });
        setIsLoading(true);
        console.log(updateRes);

        if (updateRes && updateRes.status === 200) {
          if (updateRes.data.code === 0) {
            notification({
              title: "Uploading Documents Successful",
              message:
                "You have successfully uploaded the documents for this request",
              type: "success",
            });

            setComment("");
            const updatedItem = {
              comment,
              updatedAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            };
            updateList(updatedItem);
            updated();
          }
          else {
            notification({
              title: "Document Upload Failed",
              message: updateRes.data.message,
              type: "danger",
            });
          }
        } else {
          notification({
            title: "Network Error",
            message:
              "Something went wrong while uploading the documents for this request. Please, try again later.",
            type: "danger",
          });
        }
      });
    } else {
      notification({
        title: "Network Error",
        message: "Something went wrong. Please, try again later.",
        type: "danger",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <TextareaInput
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="w-100 m-b-10"
            rows={4}
            label="Comment*"
          />

          {status === "actioned" ? (
            <div className="uploadDiv">
              <button
                type="button"
                className="btn w-100 btn-large flex flex-v-center space-between"
                onClick={handleFilesUpload}
              >
                Upload Document
                <BsUpload />
                <input type="file" hidden id="image-upload" />
              </button>
              <div className="m-t-20">
                <h4>Uploaded Documents</h4>
              </div>
              <div className="w-100 m-t-10 m-b-20">
                {fileArray &&
                  fileArray.map((file, i) => (
                    <div
                      className="flex idea-attachment m-t-10"
                      key={file.name}
                    >
                      <a
                        className="gray underline flex"
                        href={b64FileArray[i]}
                        alt="Idea attached file"
                        download
                      >
                        {file.name}
                      </a>
                      <RiDeleteBin5Line
                        className="pointer m-l-5"
                        style={{ fontSize: "1.2rem" }}
                        onClick={() => handleFileDelete(i)}
                      />
                    </div>
                  ))}
                {uploadedDocuments &&
                  uploadedDocuments.map((file) => (
                    <UploadedFileItem
                      details={file}
                      type="request"
                      status="pending"
                      key={file.id}
                    />
                  ))}
              </div>
              <p>
                Supported file formats: jpeg , png , gif , pdf, doc , docx, xls, xlsx , ppt <br />
                Maximum File Size: 500MB
              </p>
            </div>
          ) : (
            ""
          )}

          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}
          >
            Submit
            {isLoading && (
              <img src={Loader} className="btn-loading" alt="Loading..." />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestCommentForm;
