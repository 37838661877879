/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import moment from 'moment-timezone';

// import Loader from '../../assets/svg/loading.svg';
// import TextareaInput from '../inputs/TextareaInput';
// import AutoComplete from '../inputs/AutoComplete';
import { toggleScroll } from '../../utility/general';
import notification from '../../utility/notification';
import { get, patch } from '../../api-services/fetch';
import GeneralRequestForm from './GeneralRequestForm';
// import { decodeToken } from '../../utility/auth';

const Massive = ({
  closeModal, request, commentId, updateList
}) => {
  const [assigneeId] = useState('2');
  const [comment] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log(isLoading, employeeList);
  useEffect(() => {
    toggleScroll();
    getEmployeeList();

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, []);

  const getEmployeeList = async () => {
    const res = await get({ endpoint: 'employee', auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data
          .map((item) => ({ name: `${item.firstName} ${item.lastName} ${item.role ? item.role : ''} ${item.mda}`, value: item.id }));
        console.log(roleList);
        setEmployeeList(roleList);
      } else {
        notification({
          title: 'Error Getting List of Employees',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const validateForm = () => {
    if (!assigneeId) return 'Please, enter the name of the Assignee';
    if (!comment) return 'Please, enter a comment/closing remark';
    return null;
  };
  // function retrieveui() {
  //   let ui = localStorage.getItem('ui');
  //   ui = decodeToken('ui').ui;
  //   return ui;
  // }
  // const comCall = async () => {
  //   const body = {
  //     assignee: assigneeId,
  //     comment,
  //     requestId: request.id
  //   };

  //   const res = await post({
  //     endpoint: 'assign', body, auth: true
  //   });
  //   console.log(res, 'post');

  //   if (res && res.status === 200) {
  //     const { data } = res;
  //     if (data.code === 1) {
  //       notification({
  //         title: 'Successful Request Assignment',
  //         message: data.message,
  //         type: 'success'
  //       });
  //       setAssigneeId('');
  //       const updatedItem = { comment, updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ') };
  //       updateList(updatedItem);
  //       closeModal();
  //     } else {
  //       notification({
  //         title: 'Request Assignment Error',
  //         message: data.message,
  //         type: 'danger'
  //       });
  //     }
  //   } else {
  //     notification({
  //       title: 'Network Error',
  //       message: 'Something went wrong while assigning a Request to an employee. Please, try again later.',
  //       type: 'danger'
  //     });
  //   }
  //   setIsLoading(false);
  // };

  const submitForm = async () => {
    const body = {
      assignee: assigneeId,
      comment,
      requestId: request.id
    };
    const error = validateForm();

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }
    console.log(commentId, 'commmm');
    console.log(request, 'requuesttttt');
    setIsLoading(true);
    const res = await patch({
      endpoint: 'assign', param: commentId, body, auth: true
    });
    console.log(res, 'patch');

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // comCall();
        notification({
          title: 'Successful Request Assignment',
          message: data.message,
          type: 'success'
        });
        const updatedItem = { comment, updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ') };
        updateList(updatedItem);
        closeModal();
      } else {
        notification({
          title: 'Request Assignment Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while assigning a Request to an employee. Please, try again later.',
        type: 'danger'
      });
    }
  };

  console.log(submitForm);
  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box" style={{ width: '70%' }}>
        <div className="w-100 m-b-20">
          <GeneralRequestForm massive="massive" />
        </div>
      </div>
    </div>
  );
};

export default Massive;
