/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';

import IsLoading from '../common/IsLoading';
import { formatStatus, toggleScroll } from '../../utility/general';
import { get, patch, post } from '../../api-services/fetch';
import notification from '../../utility/notification';
import { formatDate, formatTime } from '../../utility/dateTime';
import OptionsBtn from './OptionsBtn';
import PresentValue from './PresentValue';
import BookMeetingForm from './BookMeetingForm';
import DocumentPreview from './DocumentPreview';
import RequestAssignmentFormMulti from '../layouts/RequestAssignmentFormMulti';
import ActionForm from '../layouts/ActionForm';
import ApproveForm from '../layouts/ApproveForm';

import JourneyItem from "./journeyItem";
import RequestQueryForm from "./QueryForm";

const MeetingDetails = ({
  meetingId, closeModal, updateList, showActions, handleCount
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [assignments, setAssignments] = useState([]);
  const [commentId, setCommentId] = useState({});
  const [showQueryForm, setShowQueryForm] = useState(false);

  const [cout, setCout] = useState(0);
  const [status, setStatus] = useState("");
  const [showBookMeetingForm, setShowBookMeetingForm] = useState(false);
  const [showAssignmentFormMulti, setShowAssignmentFormMulti] = useState(false);
  const [actionForm, setActionForm] = useState(false);
  const [approveForm, setapproveForm] = useState(false);

  useEffect(() => {
    console.log(meetingId)
    toggleScroll();
    const getDetails = async () => {
      setIsLoading(true);
      const res = await get({ endpoint: 'meeting', param: meetingId, auth: true });
      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          setDetails(data.data);
        } else {
          notification({
            title: 'Error Loading Meeting',
            message: data.message,
            type: 'danger'
          });
        }
      } else {
        notification({
          title: 'Error Loading Meeting',
          message: 'Something has gone wrong. Please, try again.',
          type: 'danger'
        });
      }
      setIsLoading(false);
    };

    getDetails();
    getAssignmentJourney(meetingId)

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, [meetingId, cout]);


  const handleCot = () => {
    // console.log('hi')
    setCout(cout + 2)
  }


  const updateStatus = async (id, name, status) => {
    setStatus(status)
    setapproveForm(true)
    // const confirmAction = confirm(`Click OK to confirm updating the status of "${name}" to ${status.toUpperCase()}`);
    // if (!confirmAction) return;
    // setIsLoading(true);
    // const res = await patch({
    //   endpoint: '/api/meeting/status', param: id, pQuery: { status }, auth: true
    // });

    // console.log(res);

    // if (res && res.status === 200) {
    //   const { data } = res;
    //   if (data.code === 1) {
    //     notification({
    //       title: 'Successful Status Update',
    //       message: data.message,
    //       type: 'success'
    //     });
    //     const updatedItem = { ...details, status };
    //     setDetails(updatedItem);
    //     updateList(updatedItem, true);
    //     handleCount()
    //   } else {
    //     notification({
    //       title: 'Status Update Error',
    //       message: data.message,
    //       type: 'danger'
    //     });
    //   }
    // } else {
    //   notification({
    //     title: 'Network Error',
    //     message: 'Something went wrong while updating the status of a Request. Please, try again later.',
    //     type: 'danger'
    //   });
    // }
    // setIsLoading(false);
  };


  const ActionComment = async (id, name, status) => {
    setActionForm(true)

    // const confirmAction = confirm(`Click OK to confirm updating the status of "${name}" to ${status.toUpperCase()}`);
    // if (!confirmAction) return;
    // setIsLoading(true);
    // const res = await patch({
    //   endpoint: '/api/meeting/status', param: id, pQuery: { status }, auth: true
    // });

    // console.log(res);

    // if (res && res.status === 200) {
    //   const { data } = res;
    //   if (data.code === 1) {
    //     notification({
    //       title: 'Successful Status Update',
    //       message: data.message,
    //       type: 'success'
    //     });
    //     const updatedItem = { ...details, status };
    //     setDetails(updatedItem);
    //     updateList(updatedItem, true);
    //   } else {
    //     notification({
    //       title: 'Status Update Error',
    //       message: data.message,
    //       type: 'danger'
    //     });
    //   }
    // } else {
    //   notification({
    //     title: 'Network Error',
    //     message: 'Something went wrong while updating the status of a Request. Please, try again later.',
    //     type: 'danger'
    //   });
    // }
    // setIsLoading(false);
  };


  const AssignMeeting = async (id, name, status, payload) => {

    setShowAssignmentFormMulti(true)
    // const confirmAction = confirm(`Click OK to confirm updating the status of "${name}" to ${status.toUpperCase()}`);
    // // if (!confirmAction) return;
    // setIsLoading(true);
    // const res = await patpostch({
    //   endpoint: '/api​/request-assignment​/meeting-assignments', body:payload, param: id, pQuery: { status }, auth: true
    // });

    // console.log(res);

    // if (res && res.status === 200) {
    //   const { data } = res;
    //   if (data.code === 1) {
    //     notification({
    //       title: 'Successful Status Update',
    //       message: data.message,
    //       type: 'success'
    //     });
    //     const updatedItem = { ...details, status };
    //     setDetails(updatedItem);
    //     updateList(updatedItem, true);
    //   } else {
    //     notification({
    //       title: 'Status Update Error',
    //       message: data.message,
    //       type: 'danger'
    //     });
    //   }
    // } else {
    //   notification({
    //     title: 'Network Error',
    //     message: 'Something went wrong while updating the status of a Request. Please, try again later.',
    //     type: 'danger'
    //   });
    // }
    // setIsLoading(false);
  };



  const cancelMeeting = async (id, name) => {
    const confirmAction = confirm(`Click OK to confirm cancelling the meeting titled: "${name}"`);
    if (!confirmAction) return;
    setIsLoading(true);

    const res = await patch({ endpoint: '/api/meeting/cancel', param: id });
    setIsLoading(false);
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: 'Successful Meeting Cancelation',
          message: data.message,
          type: 'success'
        });
        const updatedItem = { ...details, status: 'cancelled' };
        setDetails(updatedItem);
        updateList(updatedItem, true);
        handleCount()
      } else {
        notification({
          title: 'Meeting Cancelation Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while cancelling a Meeting. Please, try again later.',
        type: 'danger'
      });
    }
  };

  const getAssignmentJourney = async (requestId) => {
    setIsLoading(true);
    const res = await get({
      endpoint: "assign",
      param: requestId,
      auth: true,
    });
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setAssignments(data.data);
        console.log(data, "ayeeeeeeeeeeeeee");
        setCommentId(data.data[0] && data.data[0].id);
      } else {
        notification({
          title: "Document Journey Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something has gone wrong when fetching the document journey. Please, try again.",
        type: "danger",
      });
    }
    setIsLoading(false);
  };
  const checkMeetingHeld = async (id, name) => {
    const confirmAction = confirm(`Click OK to confirm cancelling the meeting titled: "${name}"`);
    if (!confirmAction) return;
    setIsLoading(true);

    const res = await patch({ endpoint: '/api/meeting/cancel', param: id });
    setIsLoading(false);
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: 'Successful Meeting Cancelation',
          message: data.message,
          type: 'success'
        });
        const updatedItem = { ...details, status: 'cancelled' };
        setDetails(updatedItem);
        updateList(updatedItem, true);
        handleCount()
      } else {
        notification({
          title: 'Meeting Cancelation Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while cancelling a Meeting. Please, try again later.',
        type: 'danger'
      });
    }
  };


  return (
    <>
      {isLoading && <IsLoading />}
      <div className="overlay" style={{ textAlign: 'left' }}>
        <IoMdClose className="close-btn pointer" onClick={closeModal} />
        <div className="meeting-modal">
          <div className="row">
            <div className="col-4">
              <div className="modal-box w-100">
                <div className="w-100 flex flex-v-center header">
                  <h3>{details.title || ''}</h3>
                </div>
                <div className="content">
                  <p className="m-b-10 w-100">
                    <b>Initiation Date :&nbsp;</b>
                    {formatDate(details.createdAt) || ''}
                  </p>
                  <p className="m-b-10 w-100">
                    <b>Initiator :&nbsp;</b>
                    {details.createdBy || ''}
                  </p>
                  <p className="m-b-10 w-100">
                    <b>Current Status : &nbsp;</b>
                    <span className={`status ${details.status}-status`}>
                      {formatStatus(details.status)}
                    </span>
                  </p>
                </div>
              </div>
              {showActions && (
                <div className="w-100 m-t-20">
                  {details.status != 'assigned' && (new Date(details?.endDate) >= new Date()) && <OptionsBtn
                    className="w-100 btn-large"
                    options={details.status == 'pending' && !details.isApproved ?
                      [
                        { title: 'Approve Meeting', func: () => updateStatus(details.id, details.title, 'approved'), active: details.status === 'pending' },
                        { title: 'Decline Meeting', func: () => updateStatus(details.id, details.title, 'declined'), active: details.status === 'pending' },
                        { title: 'Assign Meeting Request', func: () => AssignMeeting(details.id, details.title, 'approved'), active: details.status === 'pending' },
                      ]

                      :

                      (details.status == 'assigned') ? [

                      ]
                        :
                        (details.status == 'pending' && details.isApproved) ?
                          [
                            { title: 'Action assigned Items', func: () => ActionComment(details.id, details.title, 'approved'), active: details.status === 'pending' },
                            { title: 'Reassign Items', func: () => AssignMeeting(details.id, details.title, 'approved'), active: details.status === 'pending' },

                          ]
                          : [
                            { title: 'Reschedule Meeting', func: () => setShowBookMeetingForm(true), active: details.status === 'approved' },
                            { title: 'Cancel Meeting', func: () => cancelMeeting(details.id, details.title), active: details.status === 'approved' },

                            { title: 'Action assigned Items', func: () => AssignMeeting(details.id, details.title, 'approved'), active: details.status === 'approved' },
                          ]
                    }
                  />}
                </div>
              )}
            </div>
            <div className="col-8">
              <div className="modal-box meeting-booker">
                <div className="w-100 flex flex-v-center space-between header bg-gray">
                  <h3>{details.title}</h3>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="no-margin col-8">
                      <PresentValue label="Attendees" value={details.attendees && details.attendees.join(', ')} className="bottom-border" />
                    </div>
                    <div className="no-margin col-4">
                      <PresentValue label="Location" value={details.location} className="bottom-border" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="no-margin col-6 no-margin">
                      <div className="row">
                        <div className="no-margin col-7">
                          <PresentValue label="Start Date" value={formatDate(details.startDate)} className="bottom-border" />
                        </div>
                        <div className="no-margin col-5">
                          <PresentValue label="Time" value={formatTime(details.startTime)} className="bottom-border" />
                        </div>
                      </div>
                    </div>
                    <div className="no-margin col-6 no-margin">
                      <div className="row">
                        <div className="no-margin col-7">
                          <PresentValue label="End Date" value={formatDate(details.endDate)} className="bottom-border" />
                        </div>
                        <div className="no-margin col-5">
                          <PresentValue label="Time" value={formatTime(details.endTime)} className="bottom-border" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <PresentValue label="Detail" value={details.description} />
                </div>
              </div>
              {/* <button onClick={handleCount}>HElo</button> */}
              <div className="m-t-20 document-preview" >
                <h3 className="bg-light-gray p-10 m-b-5">View Minutes </h3>
                <div className="row" >
                  {(details?.minutes?.fileName || details?.minutes?.filePath) &&
                    <DocumentPreview details={details.minutes} type="minutes" status={details.status} />
                  }
                </div>
                {!details.minutes && (
                  <div className="w-100 p-20 bg-white center-text">
                    No Document
                  </div>
                )}
              </div>
              <div className="m-t-20 document-preview">
                <h3 className="bg-light-gray p-10 m-b-5">Documents Preview</h3>
                <div className="row">
                  {details.uploadedDocuments && details.uploadedDocuments.map((file) => (
                    <DocumentPreview details={file} type="request" status={details.status} key={file.id} />
                  ))}
                </div>
                {details.uploadedDocuments && details.uploadedDocuments.length < 1 && (
                  <div className="w-100 p-20 bg-white center-text">
                    No Document
                  </div>
                )}
              </div>
              {assignments &&
                assignments.map((item) => (
                  <JourneyItem
                    details={item}
                    // setShowQueryForm={setShowQueryForm}
                    key={item.id}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      {showBookMeetingForm && (
        <BookMeetingForm
          details={details}
          closeModal={() => setShowBookMeetingForm(false)}
          updateList={updateList}
          reschedule
          handleCount={handleCount}
          handleCot={handleCot}

        />
      )}
      {showAssignmentFormMulti && (
        <RequestAssignmentFormMulti
          closeModal={() => setShowAssignmentFormMulti(false)}
          handleCount={handleCount}
          meetingAssign='meetingAssign'
          meetingId={meetingId}
          handleCot={handleCot}

        // request={details}
        // commentId={commentId}
        // updateList={updateAssignmentList}
        // handleDetails={handleDetails}
        // current={current}
        // currentId={currentId}
        />
      )}
      {actionForm && (
        <ActionForm
          closeModal={() => setActionForm(false)}
          meetingAssign='meetingAssign'
          meetingId={meetingId}
          handleCount={handleCount}
          handleCot={handleCot}

        // request={details}
        // commentId={commentId}
        // updateList={updateAssignmentList}
        // handleDetails={handleDetails}
        // current={current}
        // currentId={currentId}
        />
      )}

      {approveForm && (
        <ApproveForm
          closeModal={() => setapproveForm(false)}

          status={status}
          meetingId={details.id}
          handleCount={handleCount}
          handleCot={handleCot}

        // request={details}
        // commentId={commentId}
        // updateList={updateAssignmentList}
        // handleDetails={handleDetails}
        // current={current}
        // currentId={currentId}
        />
      )}

      {/* {showQueryForm && (
        <RequestQueryForm
          details={details}
          current={current}
          currentId={currentId}
          handleDetails={handleDetails}
          closeModal={() => setShowQueryForm(false)}
        />
      )} */}
    </>
  );
};

export default MeetingDetails;
