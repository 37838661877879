/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
import React from "react";

import { RiBarChartFill, RiMailSettingsLine } from "react-icons/ri";
import {
  BiEnvelope,
  BiCalendarPlus,
  BiCalendarAlt,
  BiMessageSquareAdd,
} from "react-icons/bi";
import { decodeToken } from "../utility/auth";

const sidebarMenu = () => {
  let isGov = null;
  let isPa = null;
  let forPa = null;

  const ig = localStorage.getItem("ig");

  if (ig) {
    isGov = decodeToken("ig").ig;
  }

  const ip = localStorage.getItem("ip");
  if (ip) {
    isPa = decodeToken("ip").ip;
  }

  const fp = localStorage.getItem("fp");
  if (fp) {
    forPa = decodeToken("fp").fp;
  }

  console.log("is-Gov: ", ig, isGov, "is-PA: ", ip, isPa);

  const menu = [];

  (isGov || isPa) &&
    menu.push({
      title: "Dashboard",
      href: "/schedule-manager",
      icon: <RiBarChartFill className="icon" />,
    });

  isPa && !forPa &&
    menu.push({
      title: "Create New Request",
      href: "/schedule-manager/create-request",
      icon: <BiMessageSquareAdd className="icon" />,
    });

  menu.push({
    title: "All Requests",
    href: "/schedule-manager/requests",
    icon: <BiEnvelope className="icon" />,
  });
  isPa &&
    menu.push({
      title: "Request Manager",
      href: "/schedule-manager/request-manager",
      icon: <RiMailSettingsLine className="icon" />,
    });
  (isGov || isPa) &&
    menu.push({
      title: "Meeting Requests",
      href: "/schedule-manager/meeting-request",
      icon: <BiCalendarAlt className="icon" />,
    });
  isPa &&
    menu.push({
      title: "Schedule Manager",
      href: "/schedule-manager/meeting-manager",
      icon: <BiCalendarPlus className="icon" />,
    });

  return menu;
};

export default sidebarMenu;
