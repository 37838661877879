/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */

import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment-timezone";
import { formatFileUrl } from "../../utility/general";
import { formatDateTime } from "../../utility/dateTime";
import UserAvater from "../../assets/images/user-avater.png";
import "../../assets/css/requestDetails.css";
import Loader from "../../assets/svg/loading.svg";
import { decodeToken } from "../../utility/auth";
import notification from "../../utility/notification";
import TextareaInput from "../inputs/TextareaInput";
import { post, get, put } from "../../api-services/fetch";

/* eslint-disable react/react-in-jsx-scope */
const JourneyItem = ({ details }) => {
  const [userPix, setUserPix] = useState("");
  const [imgHasError, setImgHasError] = useState(false);
  const [isActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  // const [assigneeId, setAssigneeId] = useState('2');
  const [comment, setComment] = useState("");
  // const [employeeList, setEmployeeList] = useState([]);
  // const [subject, setSubject] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [isComment, setIsComment] = useState(false);

  console.log(details, "details");
  console.log(comments, "comments");

  function retrieveui() {
    let ui = localStorage.getItem("ui");
    ui = decodeToken("ui").ui;
    console.log(ui);
    return ui;
  }
  const validateForm = () => {
    if (!comment) return "Please, enter a remark";
    return null;
  };

  const submitForm = async () => {
    
    const body = {
      meetingCommentId: details.commentId,
      userId: retrieveui(),
      comment,
      assignee: details.assignee.id
    };
    console.log(body);
    console.log(details);
    const error = validateForm();

    if (error) {
      notification({
        title: "Invalid Entry",
        message: error,
        type: "danger",
      });
      return;
    }

    setIsLoading(true);
    const res = await post({
      endpoint: "followUp",
      pQuery: { requestID: "kk" },
      body,
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 0) {
        notification({
          title: "Successful",
          message: data.message,
          type: "success",
        });
        setComment("");
        // getComments();
      } else {
        notification({
          title: "Request Assignment Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something went wrong while assigning a Request to an employee. Please, try again later.",
        type: "danger",
      });
    }
    setIsLoading(false);
  };

  // const handleisActive = () => {
  //   console.log(isActive);
  //   setIsActive(!isActive);
  // };
  const handleisActive2 = () => {
    console.log(isActive2);
    setIsActive2(!isActive2);
  };
  const handleisComment = async (id) => {
    console.log(isActive);
    setIsComment(!isComment);
    const res = await put({
      endpoint: "resetComment",
      pQuery: {
        commentId: id
      },
      auth: true,
    });
    if (res && res.status === 200) {
      const { data } = res;
      console.log(data, "data");
      if (data.code === 0) {
        console.log(data.data);
      } else {
        notification({
          title: "Error Loading Request",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Error Loading Request",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getComments = async () => {
    const res = await get({
      endpoint: "followUprep",
      pQuery: {
        userId: retrieveui(),
        commentId: details.commentId,
        requestID: "kk",
      },
      auth: true,
    });
    if (res && res.status === 200) {
      const { data } = res;
      console.log(data, "data");
      if (data.code === 0) {
        setComments(data.data);
      } else {
        notification({
          title: "Error Loading Request",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Error Loading Request",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    !imgHasError && setUserPix(formatFileUrl(details.assignee.profilePicture));
  }, [imgHasError, details]);

  const handleImgError = () => {
    setUserPix("");
    setImgHasError(true);
  };
  return (
    <tr className="bg-white">
      {/* <td className="td left p-10">
                  <div
                    role="presentation"
                    onClick={() => {}}
                    className="user-avater-details flex flex-v-center pointer"
                  >
                    <div className="flex">
                      <img onError={handleImgError} src={userPix || UserAvater} alt="assignee avater" />
                    </div>
                    <div className="m-l-5" style={{ paddingTop: '3px' }}>
                      <p className="name" style={{ fontSize: '0.8rem' }}>{details.assignee.name || 'N/A'}</p>
                      <p className="role" style={{ fontSize: '0.7rem' }}>{details.assignee.role || 'N/A'}</p>
                    </div>
                  </div>
                </td> */}
      <td className="aboutAssignee">
        <img
          onError={handleImgError}
          src={userPix || UserAvater}
          alt="assignee avater"
        />
        <div className="nameAssign">
          <p>{details.assignee.name || "N/A"}</p>
          <p className="postAssign">{details.assignee.role || "N/A"}</p>
        </div>
      </td>
      {/* <td className="td left p-10">
                  <p>{formatDateTime(details.createdAt) || ''}</p>
                  <p style={{ fontSize: '0.75rem' }}>{formatTimeFromNow(details.createdAt) || ''}</p>
                </td> */}
      <td className="datePlace">
        <div className="coverdate">
          <p className="dateAssign">
            {formatDateTime(details.createdAt) || ""}
          </p>
          <p className="moment">
            <Moment fromNow>{details.createdAt}</Moment>
          </p>
          {/* <p className="location">Uhunmwonde, Edo State</p> */}
        </div>
      </td>

      <td className="datePlace">
        <div className="coverdate">
          <p className="dateAssign">
            {formatDateTime(details.updatedAt) || ""}
          </p>
          <p className="moment">
            {details.updatedAt && <Moment fromNow>{details.updatedAt}</Moment>}
          </p>
          {/* <p className="location">Uhunmwonde, Edo State</p> */}
        </div>
      </td>

      {/* <td className="td left p-10">
                  <h4
                    onClick={() => { setShowQueryForm(true); }}
                    onKeyDown={() => { setShowQueryForm(true); }}
                    style={{
                      fontWeight: '900', cursor: 'pointer', marginRight: '15px', border: '1px solid #1C811C', padding: '5px', background: '#1C811C', borderRadius: '3px', color: 'white'
                    }}
                  >
                    Issue Query
                  </h4>
                </td> */}
      {/* <td className="td left p-10" style={{ maxWidth: '300px' }}><p>{details.comment}</p></td> */}
      <td className="commentRow">
        <p className="comment">{details.comment}</p>
        <div className="down">
          {}
          {comments && (
            <button className="expand" onClick={()=>handleisComment(details.id)}>
              Expand Queries & Responses
            </button>
          )}
          {/* {comments && <button className="followUp" onClick={handleisActive}>React/FollowUp</button>} */}

          <div
            style={{ display: `${isActive ? "block" : "none"}` }}
            className="queryBox"
          >
            <div className="form">
              <TextareaInput
                name="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="w-100 m-b-10"
                rows={4}
                label="Enter message*"
              />
              <button
                type="button"
                className="btn w-100"
                style={{ width: "100%" }}
                onClick={submitForm}
                disabled={isLoading}
              >
                Submit
                {isLoading && (
                  <img src={Loader} className="btn-loading" alt="Loading..." />
                )}
              </button>
            </div>
          </div>

          <div
            style={{ display: `${isComment ? "block" : "none"}` }}
            className="querylist"
          >
            {comments &&
              comments.map((item) => (
                <div style={{ marginTop: "20px" }} className="eachQuery">
                  <div className="nameCover">
                    <img src={UserAvater} alt="" />
                    <div className="nameFull">
                      <p> {item.name} </p>
                      <p className="post">{item.role}</p>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }} className="realComment">
                    <p>{item.comment}</p>
                  </div>

                  <div className="line" />
                </div>
              ))}
          </div>
          {decodeToken("fp")?.fp !== "True" && <button
            style={{ marginTop: "20px" }}
            onClick={handleisActive2}
            className="queryReact"
          >
            React/FollowUp
          </button>}
          <div
            style={{ display: `${isActive2 ? "block" : "none"}` }}
            className="queryBox"
          >
            <div className="form">
              <TextareaInput
                name="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="w-100 m-b-10"
                rows={4}
                label="Enter message*"
              />
              <button
                type="button"
                className="btn w-100"
                onClick={submitForm}
                style={{ width: "100%" }}
                disabled={isLoading}
              >
                Submit
                {isLoading && (
                  <img src={Loader} className="btn-loading" alt="Loading..." />
                )}
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default JourneyItem;
