/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable no-trailing-spaces */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import moment from "moment-timezone";

import { BsUpload } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import Loader from "../../assets/svg/loading.svg";
import TextareaInput from "../inputs/TextareaInput";
import AutoComplete from "../inputs/AutoComplete";
import { convertImgToBase64, toggleScroll } from "../../utility/general";
import notification from "../../utility/notification";
import { get, patch,post } from "../../api-services/fetch";
import UploadedFileItem from "./UploadedFileItem";
import { SwapTableContext } from "../Contexts/SwapTableContext";
import { uploadDocument } from "../../api-services/otherApis";
import  UploadButton  from "./UploadButton";
// import { decodeToken } from '../../utility/auth';

const ActionForm = ({
  closeModal,
  request,
  commentId,
  updateList,
  handleDetails,
  current,
  currentId,
  meetingId, 
  handleCount,
  handleCot
}) => {
  const [assigneeId, setAssigneeId] = useState("2");
  const [comment, setComment] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [uploadedDocuments] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [b64FileArray, setB64FileArray] = useState([]);
  const [actionDoc, setActionDoc] = useState([]);
  const { imageHolder } = useContext(SwapTableContext);


  useEffect(() => {
      console.log(meetingId)
    toggleScroll();
    // getEmployeeList();

    return () =>
      setTimeout(() => {
        toggleScroll();
      }, 0);
  }, []);

  const getEmployeeList = async () => {
    const res = await get({ endpoint: "employee", auth: true });
    console.log(res);

    if (res && res.status === 200||res.status===204) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data.map((item) => ({
          name: `${item.firstName} ${item.lastName} ${
            item.role ? item.role : ""
          } ${item.mda}`,
          value: item.id,
        }));
        console.log(roleList);
        setEmployeeList(roleList);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const validateForm = () => {
    // if (!assigneeId) return "Please, enter the name of the Assignee";
    if (!comment) return "Please, enter a comment/closing remark";
    return null;
  };
 
  const handleActionDoc = (actionDoc)=>{
    setActionDoc(actionDoc)
    console.log(actionDoc)
  }
  
  const submitForm = async () => {
    
  
   
    const payload = {
    //   assignee: assigneeId,
      comment,
      meetingRequestId: meetingId,
      documents: actionDoc,
      status:'actioned'
    };
    console.log(payload, "playyyy");
    const error = validateForm();

    if (error) {
      notification({
        title: "Invalid Entry",
        message: error,
        type: "danger",
      });
      return;
    }

    setIsLoading(true);
    const res = await post({
      endpoint: "/api/meeting/meeting/action-comment",
    //   param: commentId,
      body: payload,
      auth: true,
    });
    console.log(res, "patch");

    if (res && res.status === 200||res.status===204) {
      const { data } = res;
      if (data.code === 1|| data.code===0) {
        // comCall();
        notification({
          title: "Successfully Actoned this Request",
          message: data.message=='OK'&&'Request has been actioned',
          type: "success",
        });
handleCount()
handleCot()
closeModal();


        const updatedItem = {
          comment,
          updatedAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        };
        updateList(updatedItem);
        // handleDetails(current, currentId);
        
      } else {
    setIsLoading(false);

        notification({
          title: "Actioned Request Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
    setIsLoading(false);

      notification({
        title: "Network Error",
        message:
          "Something went wrong while assigning a Request to an employee. Please, try again later.",
        type: "danger",
      });
    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-600">
        <div className="content">
          {/* <AutoComplete
            label="Select name of Assignee*"
            options={employeeList || []}
            value={assigneeId}
            onChange={(value) => setAssigneeId(value)}
            className="w-100 m-b-10"
          /> */}
          <TextareaInput
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="w-100 m-b-10"
            rows={4}
            label="Comment*"
          />

         <div className='p-5'>
             <UploadButton
             handleActionDoc={handleActionDoc}
             />
         </div>
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}
          >
            Actioned
            {isLoading && (
              <img src={Loader} className="btn-loading" alt="Loading..." />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionForm;
