/* eslint-disable linebreak-style */
import React, { useState, useRef } from 'react';
import { RiArrowDownSFill } from 'react-icons/ri';
import useClickOutsideBox from '../hooks/useClickOutsideBox';

const OptionsBtn = ({ className, options }) => {
  const [showMenu, setShowMenu] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutsideBox(wrapperRef, () => setShowMenu(false));

  const handleOptionClick = (func) => {
    setShowMenu(false);
    func();
  };

  return (
    <div className="p-r w-100" ref={wrapperRef}>
      <button
        className={`${className || ''} options-btn pointer`}
        type="button"
        onClick={() => setShowMenu(!showMenu)}
        style={{ width: '100%' }}
      >
        Select An Action
        <RiArrowDownSFill />
      </button>
      {showMenu && (
        <div className="options-menu">
          {options && options.length > 0 && options.map((item) => (
            <div
              className={`menu-item ${item.active ? '' : 'inactive'}`}
              key={item.title}
              onClick={() => item.active && handleOptionClick(item.func)}
              role="presentation"
            >
              {item.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OptionsBtn;
