/* eslint-disable linebreak-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import moment from "moment-timezone";
import Cookies from 'js-cookie'
import { BsUpload } from "react-icons/bs";
import axios from "axios";
import { TiDeleteOutline } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import Loader from "../../assets/svg/loading.svg";
import TextareaInput from "../inputs/TextareaInput";
import AutoComplete from "../inputs/AutoComplete";
import { convertImgToBase64, toggleScroll } from "../../utility/general";
import notification from "../../utility/notification";
import { get, put, post } from "../../api-services/fetch";
import UploadedFileItem from "./UploadedFileItem";
import { SwapTableContext } from "../Contexts/SwapTableContext";
import { uploadDocument } from "../../api-services/otherApis";
import Select from "react-select";

// import { decodeToken } from '../../utility/auth';
const BASE_API_URL = `https://edogoverp.com/services/api`
const RequestAssignmentFormMulti = ({
  closeModal,
  request,
  updateList,
  handleDetails,
  current,
  currentId,
  meetingAssign,
  updated,
  meetingId,
  handleCount,
  handleCot
}) => {
  const [assigneeId, setAssigneeId] = useState([]);
  const [assigneeName, setAssigneeName] = useState("");
  const [comment, setComment] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [mda, setMda] = useState([]);
  const [mdaList, setMdaList] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [b64FileArray, setB64FileArray] = useState([]);
  const { imageHolder } = useContext(SwapTableContext);

  useEffect(() => {
    toggleScroll();
    // getEmployeeList();
    loadMda();
    return () =>
      setTimeout(() => {
        toggleScroll();
      }, 0);
  }, []);

  const onDelete = (id) => {
    const newOptions = options.filter((item) => item.assignee != id);
    setOptions(newOptions);
  };


  const loadMda = async () => {
    const res = await get({ endpoint: "allMdaDrop", auth: true });
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // setMda(data.data);
        const roleList = data?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setMdaList(roleList)
      } else {
        notification({
          title: "Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something has gone wrong when fetching the document journey. Please, try again.",
        type: "danger",
      });
    }
  };

  const getEmployeesFromMda = (id) => {
    console.log(id)
    axios
      .get(`${BASE_API_URL}/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {

        console.log("--employeesByNads--")
        console.log(response?.data)
        // this.setState({
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setEmployeeList(roleList.filter((item) => { return !item.name.includes(Cookies.get('fullname')) }));

        // setMdaList(roleList)

        // });
      })
  }


  // const getEmployeeList = async () => {
  //   const res = await get({ endpoint: "employee", auth: true });
  //   console.log(res);
  //   console.log(Cookies.get('fullname'))
  //   if (res && res.status === 200) {
  //     const { data } = res;
  //     if (data.code === 1) {
  //       const roleList = data.data.map((item) => ({
  //         name: `${item.firstName} ${item.lastName} ${
  //           item.role ? item.role : ""
  //         } ${item.mda}`,
  //         value: item.id,
  //       }));
  //       console.log(roleList);
  //       setEmployeeList(roleList.filter((item)=>{return !item.name.includes(Cookies.get('fullname'))}));
  //     } else {
  //       notification({
  //         title: "Error Getting List of Employees",
  //         message: data.message,
  //         type: "danger",
  //       });
  //     }
  //   } else {
  //     notification({
  //       title: "Network",
  //       message: "Something has gone wrong. Please, try again.",
  //       type: "danger",
  //     });
  //   }
  // };

  const validateForm = () => {
    if (assigneeId.length === 0) return 'Please, Enter at least one Assignee';
    // if (!comment) return 'Please, enter a comment';
    return null;
  };
  // function retrieveui() {
  //   let ui = localStorage.getItem('ui');
  //   ui = decodeToken('ui').ui;
  //   return ui;
  // }
  // const comCall = async () => {
  //   const body = {
  //     assignee: assigneeId,
  //     comment,
  //     requestId: request.id
  //   };

  //   const res = await post({
  //     endpoint: 'assign', body, auth: true
  //   });
  //   console.log(res, 'post');

  //   if (res && res.status === 200) {
  //     const { data } = res;
  //     if (data.code === 1) {
  //       notification({
  //         title: 'Successful Request Assignment',
  //         message: data.message,
  //         type: 'success'
  //       });
  //       setAssigneeId('');
  //       const updatedItem = { comment, updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ') };
  //       updateList(updatedItem);
  //       closeModal();
  //     } else {
  //       notification({
  //         title: 'Request Assignment Error',
  //         message: data.message,
  //         type: 'danger'
  //       });
  //     }
  //   } else {
  //     notification({
  //       title: 'Network Error',
  //       message: 'Something went wrong while assigning a Request to an employee. Please, try again later.',
  //       type: 'danger'
  //     });
  //   }
  //   setIsLoading(false);
  // };
  const handleOptionChange = async() => {
    const uploadedDocs = fileArray.map(async (file) => {
      console.log(file);
      const body = new FormData();
      body.append("files", file);
      const response = await uploadDocument(body);

      console.log(response);
      if (response && response.status === 200) {
        if (response.data.code === 1) {
          return {
            name: file.name,
            path: response.data.doclink,
            id: response.data.id,
          };
        }
        notification({
          title: "Document Upload Failed",  
          message: response.data.message,
          type: "danger",
        });
      }
      return null;
    });
  console.log(fileArray)
  console.log(uploadedDocs)
   let docs = await Promise.all(uploadedDocs)
    console.log(docs)

    setEachOption({
      comment,
      assignee: assigneeId.value,
      isMultiple: true,
      requestId: meetingAssign == 'meetingAssign' ? meetingId : request.id,
      // requestId: request.id,
      commentId: 0,
      assigneeName,
      documents:docs
    });
  };

  useEffect(() => {
    console.log(assigneeId);
    handleOptionChange();
  }, [comment, assigneeId, fileArray]);

  const [eachOption, setEachOption] = useState({
    comment: "",
    assignee: "",
    isMultiple: true,
    requestId: meetingAssign == 'meetingAssign' ? meetingId : request.id,
    commentId: 0,
    assigneeName: "",
    documents:[]
  });

  // all the options array
  const [options, setOptions] = useState([]);
  // add new option to options array
  const addOption = () => {
    console.log(eachOption)
    setOptions([...options, { ...eachOption }]);
    // toastr.success('', 'Option Added');
    notification({
      title: "Successful Request Assignment",
      message: "Added",
      type: "success",
    });
    setEachOption({
      comment: "",
      assignee: "",
      isMultiple: true,
      requestId: meetingAssign == 'meetingAssign' ? meetingId : request.id,
      // requestId: request.id,
      commentId: 0,
      assigneeName: "",
      documents: [],
    });
    resetForm();
  };

  const setAssignee = (name) => {
    setAssigneeId(name);
    setAssigneeName(name.label);
  };

  // file uploadd
  const handleFilesUpload = async () => {
    const uploadField = document.getElementById("image-upload");

    uploadField.click();
    uploadField.onchange = async () => {
      if (!uploadField.files[0]) return;
      const { type, size } = uploadField.files[0];
      console.log(type, size, uploadField.files[0]);

      const supportedTypes = [
        "jpeg",
        "png",
        "gif",
        "xls",
        "xlsx",
        "xlx",
        "csv",
        "vnd.ms-excel",
        "pdf",
        "vnd.openxmlformats-officedocument.wordprocessingml.document",
        "doc",
        "ppt",
        "pptx",
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      const fileType = type.slice(type.indexOf("/") + 1);

      console.log(fileType);
      if (b64FileArray.length + uploadedDocuments.length > 30) {
        notification({
          title: "File Limit Exceeded",
          message: "You can only upload maximum of 30 documents for an idea.",
          type: "danger",
        });
        return;
      }
      if (!supportedTypes.includes(fileType)) {
        notification({
          title: "Invalid File Type",
          message:
            "Invalid file format. Supported file types are pdf, docx, ppt, pptx, doc, xlsx, jpeg, png and gif",
          type: "danger",
        });
        return;
      }
      if (size / 1024 > 500000) {
        notification({
          title: "File Too Large",
          message: "The file size must not be more than 500MB",
          type: "danger",
        });
        return;
      }
      const fileBase64 = await convertImgToBase64(uploadField.files[0]);
      const b64FileSet = new Set(b64FileArray);
      const setSize = b64FileSet.size;
      const newFileList = [...fileArray];

      b64FileSet.add(fileBase64);

      if (setSize < b64FileSet.size) {
        newFileList.push(uploadField.files[0]);
      }

      setB64FileArray([...b64FileSet]);
      setFileArray(newFileList);
    };
  };

  const handleFileDelete = async (index) => {
    console.log(b64FileArray, fileArray);
    const newB64list = b64FileArray.filter((val, i) => i !== index);
    const newFileArray = fileArray.filter((val, i) => i !== index);

    setB64FileArray(newB64list);
    setFileArray(newFileArray);
  };

  const resetForm = () => {
    setAssigneeId("2");
    setMda("");
    setComment("");
    setFileArray([]);
    setB64FileArray([]);
    setUploadedDocuments([]);
  };

  const submitForm = async () => {
    const payload = {
      requestAssignments: options,
    };
    console.log(payload, "playyyy");
    const error = validateForm();

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }

    setIsLoading(true);


    const res = (meetingAssign == 'meetingAssign') ?
      await post({
        endpoint: '/api/request-assignment/meeting-assignments',
        body: payload,
        auth: true,
      })
      :
      await post({
        endpoint: "assignMulti",
        body: payload,
        auth: true,
      });
    console.log(res, "put");

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // comCall();
        notification({
          title: "Successful Request Assignment",
          message: data.message,
          type: "success",
        });
        const updatedItem = {
          comment,
          updatedAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        };
        updateList && updateList(updatedItem)
        updated && updated()
        closeModal();
        // updateList && handleDetails(current, currentId);
        if (
          fileArray.length > 0 ||
          (imageHolder.name != "" && imageHolder.doclink != 0)
        ) {
          // upload documents then update the request details
          setIsLoading(true);
          notification({
            title: "Uploading Documents",
            message: "Currently uploading the attached documents...",
            type: "success",
          });
          const uploadedDocs = fileArray.map(async (file) => {
            console.log(file);
            const body = new FormData();
            body.append("files", file);
            const response = await uploadDocument(body);

            console.log(response);
            if (response && response.status === 200) {
              if (response.data.code === 1) {
                return {
                  name: file.name,
                  path: response.data.doclink,
                  id: response.data.id,
                };
              }
              notification({
                title: "Document Upload Failed",
                message: response.data.message,
                type: "danger",
              });
            }
            return null;
          });
          if (
            (imageHolder.name != "" ||
              imageHolder.name != null ||
              imageHolder.name != undefined) &&
            (imageHolder.docklink != "" ||
              imageHolder.docklink != null ||
              imageHolder.docklink != undefined)
          ) {
            uploadedDocs.push({
              name: imageHolder.name,
              path: imageHolder.doclink,
              // id: response.data.id
            });
          } else {
            notification({
              title: "Upload Error",
              message:
                "Something went wrong while uploading document from M-FILES",
              type: "danger",
            });
          }

          Promise.all(uploadedDocs).then(async (resp) => {
            console.log(uploadedDocs, resp);
            console.log(payload.documents);
            const updateRes = await put({
              endpoint: "assignMultis",
              body: payload,
              auth: true,
            });
            setIsLoading(true);
            console.log(updateRes);

            if (updateRes && updateRes.status === 200) {
              if (updateRes.data.code === 1) {
                notification({
                  title: "Uploading Documents Successful",
                  message:
                    "You have successfully uploaded the documents for this request",
                  type: "success",
                });
                // handleCount()
                // handleCot()
                updated()
                closeModal();

              } else {
                notification({
                  title: "Document Upload Failed",
                  message: updateRes.data.message,
                  type: "danger",
                });
              }
            } else {
              notification({
                title: "Network Error",
                message:
                  "Something went wrong while uploading the documents for this request. Please, try again later.",
                type: "danger",
              });
            }
          });
        }
        setIsLoading(false);
        // handleCount()
        // handleCot()
        closeModal();

        // const updatedItem = { comment, updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ') };
        // updateList(updatedItem);

      } else {
        notification({
          title: "Request Assignment Error",
          message: data.message,
          type: "danger",
        });
      }
      setIsLoading(false);
    } else {
      notification({
        title: "Network Error",
        message:
          "Something went wrong while assigning a Request to an employee. Please, try again later.",
        type: "danger",
      });
      setIsLoading(false);

    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-600">
        <div className="content">
          {/* <AutoComplete
            label="Select name of Assignee*"
            options={employeeList || []}
            value={assigneeId}
            onChange={(name, value) => setAssignee(name, value)}
            className="w-100 m-b-10"
          /> */}
          {/* <AutoComplete
                  label="Assignee MDA*"
                  name="Assignee MDA"
                  options={mdaList || []}
                  value={mda}
                  // isShow={false}
                  onChange={(value) => {
                    getEmployeesFromMda(value);
                    setMda(value);
                    
                  
                  }}
                  className="w-100 m-b-10"
                /> */}

          <div className="w-100 m-b-10">
            <Select
              // isMulti
              placeholder="Select MDA"
              className="basic-multi-select w-100 m-b-10"
              onChange={(value) => {
                setMda(value);
                console.log(value);
                getEmployeesFromMda(value.value);
              }}
              value={mda}
              options={mdaList?.map((m) => ({
                label: m.name,
                value: m.value,
              }))}
            />
          </div>

          <div className="w-100 m-b-10">
            <Select
              // isMulti
              placeholder="Select Assignees"

              className="basic-multi-select w-100 m-b-10"
              onChange={(value) => {
                setAssignee(value);
                console.log(value);
              }}
              value={assigneeId}
              options={employeeList.map((m) => ({
                label: m.name,
                value: m.value,
              }))}
            />
          </div>
          {/* 
                <AutoComplete
                  label="Assignees*"
                  name="Assignees"
                  options={employeeList || []}
                  multiple = {true}
                  isShow={false}
                  onChange={(value) => {console.log(value)}}
                  className="w-100 m-b-10"
                /> */}
          <TextareaInput
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="w-100 m-b-10"
            rows={4}
            label="Comment*"
          />

          <div className="uploadDiv">
            <button
              type="button"
              className="btn w-100 btn-large flex flex-v-center space-between"
              onClick={handleFilesUpload}
            >
              Upload Document
              <BsUpload />
              <input type="file" hidden id="image-upload" />
            </button>
            <div className="m-t-20">
              <h4>Uploaded Documents</h4>
            </div>
            <div className="w-100 m-t-10 m-b-20">
              {fileArray &&
                fileArray.map((file, i) => (
                  <div className="flex idea-attachment m-t-10" key={file.name}>
                    <a
                      className="gray underline flex"
                      href={b64FileArray[i]}
                      alt="Idea attached file"
                      download
                    >
                      {file.name}
                    </a>
                    <RiDeleteBin5Line
                      className="pointer m-l-5"
                      style={{ fontSize: "1.2rem" }}
                      onClick={() => handleFileDelete(i)}
                    />
                  </div>
                ))}
              {uploadedDocuments &&
                uploadedDocuments.map((file) => (
                  <UploadedFileItem
                    details={file}
                    type="request"
                    status="pending"
                    key={file.id}
                  />
                ))}
              {options?.map((item) => (
                <div
                  className="flex space-between w-100 p-5 m-t-5"
                  id={item.assignee}
                  style={{
                    border: "1px solid green",
                    borderRadius: "8px",
                    backgroundColor: "lightgreen",
                  }}
                >
                  <span>{item.assigneeName}</span>

                  <TiDeleteOutline
                    className="inline-block pointer m-l-20 h-100"
                    style={{ fontSize: "20px" }}
                    onClick={() => onDelete(item.assignee)}
                  />
                </div>
              ))}
            </div>
            <p>
              Supported file formats: jpeg , png , gif , pdf, doc , docx, xls, xlsx , ppt <br />
              Maximum File Size: 500MB
            </p>
          </div>

          <button
            className="btn btn-large m-t-10 m-b-10 w-100"
            onClick={addOption}
            type="button"
          >
            + Add
          </button>

          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}
          >
            Assign Request
            {isLoading && (
              <img src={Loader} className="btn-loading" alt="Loading..." />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestAssignmentFormMulti;
