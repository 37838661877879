/* eslint-disable linebreak-style */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useEffect, useState, useRef } from 'react';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import Cookies from 'js-cookie'


import SelectInputColored from '../inputs/SelectInputColored';
import IsLoading from '../common/IsLoading';
import SearchInput from '../inputs/SearchInput';
import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';
import MeetingDetails from '../layouts/MeetingDetails';
import { get, patch } from '../../api-services/fetch';
import CalendarBtn from '../layouts/CalendarBtn';
import notification from "../../utility/notification";
import UploadButton from '../layouts/UploadButton';
import {
  formatDate, formatLongDate, formatTime, formatDateforPicker
} from '../../utility/dateTime';
import { formatStatus } from '../../utility/general';

const MeetingRequest = ({ history }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [statuz, setStatuz] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [searchText, setSearchText] = useState('');
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('All meetings');
  const [status, setStatus] = useState('');
  const [MiteenMiutes, setMiteenMiutes] = useState([]);
  const [idz, setIdz] = useState();
  let [count, setCount] = useState(0);
  let [isMinutes, setIsMinutes] = useState(0);

  const focusRef = useRef();

  console.log(Cookies.get('userId'))

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Meeting Request';

    const getList = async () => {
      // focusRef.current.focus();
      // setIsLoading(true);
      setErrorMsg('');
      const res = await get({
        endpoint: 'meeting',
        pQuery: {
          query: searchText, startDate, endDate, status
        }
      });

      console.log(res);

      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          console.log(data.data)
          setDetails(data.data);
          if (data.data.length < 1) {
            if (searchText) {
              setErrorMsg('Meeting not found');
            } else {
              setErrorMsg('No Meeting yet');
            }
          }
        } else {
          setErrorMsg(data.message);
        }
      } else {
        setErrorMsg('Something has gone wrong. Please, try again.');
      }
      setIsLoading(false);
    };

    getList({
      startDate: formatDateforPicker(new Date()),
      endDate: formatDateforPicker(new Date())
    });
  }, [searchText, startDate, endDate, status, count]);


  const handleCount = () => {
    // console.log('hi')
    setCount(count + 2)
  }




  const handleDate = async (e) => {
    const { value } = e.target;
    setSelectedDate(value);
    setSearchText('');

    switch (value) {
      case 'Today':
        // get meetings for today
        setStartDate(formatDateforPicker(new Date()));
        setEndDate(formatDateforPicker(new Date()));
        break;
      case 'This Week':
        // get meetings for this week
        setStartDate(formatDateforPicker(moment().startOf('week').toDate()));
        setEndDate(formatDateforPicker(moment().endOf('week').toDate()));
        break;
      case 'This Month':
        // get meetings for this month
        setStartDate(formatDateforPicker(moment().startOf('month').toDate()));
        setEndDate(formatDateforPicker(moment().endOf('month').toDate()));
        break;
      case 'Calendar':
        // get meetings for the day selected in the calendar
        setStartDate(formatDateforPicker(calendarDate));
        setEndDate(formatDateforPicker(calendarDate));
        break;
      case 'Complete':
        // get meetings for the day selected in the calendar
        setStartDate('');
        setEndDate('');
        break;
      default:
        break;
    }
  };

  const handleCalendarDate = async (val) => {
    setCalendarDate(val);
    setStartDate(formatDateforPicker(val));
    setEndDate(formatDateforPicker(val));
    setSelectedDate('Calendar');
    setSearchText('');
  };
  const handleDoc = async (minutes, isMinutes) => {
    console.log(minutes)
    console.log(isMinutes)


    // const getList = async () => {
    const updateRes = !isMinutes ? await patch({
      endpoint: `/api/meeting/minutes`, param: idz, pQuery: { isMinutes: true },

      body: (minutes),
      auth: true,
    })
      :
      await patch({
        endpoint: `/api/meeting/minutes`, param: idz, pQuery: { isMinutes: false },
        body: (minutes),
        auth: true,
      })

    //   setIsLoading(true);
    console.log(updateRes);

    if (updateRes && updateRes.status === 200) {
      if (updateRes.data.code === 1) {
        notification({
          title: "Uploading Documents Successful",
          message:
            "You have successfully uploaded the documents for this request",
          type: "success",
        });
        handleCount()
      } else {
        notification({
          title: "Document Upload Failed",
          message: updateRes.data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something went wrong while uploading the documents for this request. Please, try again later.",
        type: "danger",
      });
    }








    // console.log(minutes)
    // setMiteenMiutes(minutes)
  }

  const handleSearch = async (e) => {
    const { name, value } = e.target;

    if (name === 'searchText') {
      setSearchText(value);
      console.log(value);
    }
    if (name === 'status') {
      setStatus(value);
      console.log(value);
    }
  };

  const showEditForm = (rew) => {
    const selected = details.filter((item) => rew.id === item.id);

    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setShowFormModal(true);
    }
  };

  const closeModal = () => {
    setSelectedRecord([]);
    setShowFormModal(false);
  };

  const updateList = (newItem, isUpdate) => {
    if (isUpdate) {
      const newList = details.map((item) => (item.id === newItem.id ? newItem : item));
      // setDetails(newList);
      setCount(count + 2)

      // } else if (errorMsg) {
      // getList({});
    } else {
      const newList = [...details];
      newList.unshift(newItem);
      setDetails(newList);
    }
  };

  const checkMeetingHeld = async (id, statuz) => {
    console.log(id)
    const confirmAction = confirm(`Click OK to confirm if meeting has been held`);
    if (!confirmAction) return;
    setIsLoading(true);

    const res = await patch({ endpoint: '/api/meeting/action', param: id, pQuery: { statuz } });
    setIsLoading(false);
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: 'Successful Updated',
          message: data.message,
          type: 'success'
        });
        setCount(count + 2)
        // const updatedItem = { ...details, status: 'cancelled' };
        // setDetails(updatedItem);
        // updateList(updatedItem, true);
      } else {
        notification({
          title: 'Updating Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while cancelling a Meeting. Please, try again later.',
        type: 'danger'
      });
    }
  };


  const statusOptions = [
    { name: 'All Meetings', value: '' },
    { name: 'Booked', value: 'booked' },
    { name: 'Actioned', value: 'actioned' },
    { name: 'Pending', value: 'pending' },
    { name: 'Declined', value: 'declined' },
    { name: 'Cancelled', value: 'cancelled' }
  ];

  return (
    <div className="w-100" tabIndex={-1} ref={focusRef}>
      <div className="w-100 flex space-between">
        <Greeting />
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between m-t-20">
        <h3>
          Meeting Requests -&nbsp;
          {selectedDate === 'Calendar'
            ? formatLongDate(moment(calendarDate).format('YYYY-MM-DD'))
            : selectedDate}
        </h3>
      </div>
      <div className="w-100 flex space-between m-t-20 flex-v-center">
        <div className="flex">
          <SelectInputColored
            className="m-r-10"
            options={['Complete', 'Today', 'This Week', 'This Month', 'Calendar']}
            value={selectedDate}
            onChange={handleDate}
          />
          <CalendarBtn value={calendarDate} onChange={(val) => handleCalendarDate(val)} />
        </div>
        <div className="flex flex-v-center">
          <SelectInputColored
            className="m-r-10"
            options={statusOptions || []}
            value={status}
            onChange={handleSearch}
            name="status"
          />
          <SearchInput
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            className="m-l-5"
            placeholder="Search list..."
          />
        </div>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th"><p>Date</p></div>
            <div className="th"><p>Time</p></div>
            <div className="th"><p>Meetings Title</p></div>
            <div className="th"><p>Location</p></div>
            <div className="th"><p>Status</p></div>
            <div className="th"><p>Attendees</p></div>
            <div className="th"><p>Actions</p></div>
          </div>
        </div>
        <div className="table-body">
          {!(isLoading || errorMsg) && details && details.map((item) => (
            <ListItem details={item} statuz={statuz} handleCount={handleCount} checkMeetingHeld={checkMeetingHeld} showEditForm={showEditForm} handleDoc={handleDoc} key={item.id} setIdz={setIdz} idz={idz} />
          ))}
        </div>
      </div>
      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      {(isLoading) && <IsLoading />}
      {showFormModal && (
        <MeetingDetails
          handleCount={handleCount}
          meetingId={selectedRecord.id}
          updateList={updateList}
          closeModal={closeModal}
          showActions
        //  handleDoc = {handleDoc}

        />
      )}
    </div>
  );
};

const ListItem = ({ details, checkMeetingHeld, statuz, showEditForm, handleCount, handleDoc, setIdz, idz }) => (
  <>
    <div className="table-row" onClick={() => { setIdz(details.id) }}>
      <div className="td">{formatDate(details.startDate)}</div>
      <div className="td">{`${formatTime(details.startTime)} - ${formatTime(details.endTime)}`}</div>
      <div className="td">{details.title}</div>
      <div className="td">{details.location}</div>
      <div className="td">
        <div className={`status ${details.status}-status`}>
          {details.status == 'approved' && details.isHeld ? "Held" : formatStatus(details.status)}
        </div>
      </div>
      <div className="td" style={{ maxWidth: '400px' }}>{details.attendees && details.attendees.join(', ')}</div>
      <div className="td actions" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >

        {Cookies.get('userId') == details.initiatorId &&

          <div>
            {
              details.status == 'approved' && !details.isHeld && !details.hasMinutes ?
                //  details.status=='approved' && !details.isHeld ?


                <button style={{ padding: "7px", }} className='button pointer' onClick={() => { checkMeetingHeld(details.id, statuz) }}>Has meeting Held?</button>
                :
                details.status == 'approved' && details.isHeld && !details.hasMinutes ?

                  <UploadButton className=''
                    // updateList={updateList}
                    idz={idz}
                    handleDoc={handleDoc}
                    isMinutes={details.hasMinutes}
                    p='Upload Minutes'

                  />
                  :
                  details.status == 'approved' && details.isHeld && details.hasMinutes ?
                    // <button className='button pointer' onClick={()=>{checkMeetingHeld(details.id, statuz)}}>Upload Supporting Documents</button>
                    <UploadButton
                      // updateList={updateList}
                      idz={idz}
                      handleDoc={handleDoc}
                      isMinutes={details.hasMinutes}
                      className='button'
                      style={{ fontSize: '9px' }}
                      p='Upload Other Documents'
                    />
                    :
                    ""

            }

          </div>
        }


        <button
          data-tip
          data-for="view-btn"
          type="button"
          className="action-btn pointer"
          style={{ marginLeft: "5px" }}
          onClick={() => showEditForm(details)}
        >
          <AiOutlineFundProjectionScreen />
        </button>

        <ReactTooltip id="view-btn" type="info" effect="solid">
          <span>View Meeting Details</span>
        </ReactTooltip>
      </div>
    </div>
  </>
);

export default MeetingRequest;
