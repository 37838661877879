/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable curly */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
/* eslint-disable react/button-has-type */
/* eslint-disable keyword-spacing */
/* eslint-disable comma-dangle */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable key-spacing */
/* eslint-disable no-trailing-spaces */
/* eslint-disable comma-spacing */
/* eslint-disable arrow-parens */
/* eslint-disable arrow-spacing */
/* eslint-disable space-infix-ops */
/* eslint-disable import/order */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
import React, { useState, useEffect, useContext } from "react";
import { BsUpload } from "react-icons/bs";
// import { RiDeleteBin5Line } from 'react-icons/ri';
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import Cookies from 'js-cookie'
import { get, put, post } from "../../api-services/fetch";
// eslint-disable-next-line import/named
import { convertImgToBase64, toggleScroll } from "../../utility/general";
import Loader from "../../assets/svg/loading.svg";
import { formatDateforPicker, getDaysFromDate } from "../../utility/dateTime";
// eslint-disable-next-line quotes

import IsLoading from "../common/IsLoading";
import TextareaInput from "../inputs/TextareaInput";
import TextInput from "../inputs/TextInput";
import notification from "../../utility/notification";
import UploadedFileItem from "./UploadedFileItem";
// import { uploadDocument } from "../../api-services/otherApis";
import AutoComplete from "../inputs/AutoComplete";
import SearchDocument from "./SearchDocument";
import { SwapTableContext } from "../Contexts/SwapTableContext";
// import MultiSelect from 'react-multi-select-component';
import Select from "react-select";

const GeneralRequestForm = ({ requestId, massive }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [vendor, setVendor] = useState("");
  const [dateReceived, setDateReceived] = useState("");
  const [recipient, setRecipient] = useState("");
  const [recipientMda, setRecipientMda] = useState("");
  const [financialImplication, setFinancialImplication] = useState("");
  const [fileArray, setFileArray] = useState([]);
  const [b64FileArray, setB64FileArray] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [mdaId, setMdaId] = useState(null);
  const [meetingCategoryId, setmeetingCategoryId] = useState(null);
  const [formType, setFormType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [category, setCategory] = useState([]);
  const [Mda, setMda] = useState([]);
  const [mdaList, setMdaList] = useState([]);
  const [resetdel] = useState(false);
  const [multiple, setMultiple] = useState("");
  const [multipleMda, setMultipleMda] = useState("");
  const [uploadProgress, setUploadProgress] = React.useState(0);

  console.log(employeeList, "emooo");

  const [isShow1, setIsShow1] = useState(false);
  const [isShow3, setIsShow3] = useState(false);

  const { mFilesArray, holdMfilesArray } = useContext(SwapTableContext);

  const BASE_API_URL = `https://edogoverp.com/services/api`

  const resetReset = () => {
    setIsShow1(false);
    setIsShow3(false);
    setMdaId(null);
    setmeetingCategoryId(null);
    setTitle("");
    setDescription("");
    setVendor("");
    setDateReceived("");
    setRecipientMda("")
    setFileArray([])
    setRecipient("");
    setFinancialImplication("")

  };

  useEffect(() => {
    loadMda();
    toggleScroll();

    const getDetails = async () => {
      setIsLoading(true);
      const res = await get({
        endpoint: "request",
        param: requestId,
        auth: true,
      });
      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          setTitle(data.data.title || "");
          setDescription(data.data.description || "");
          setVendor(data.data.vendor || "");
          setDateReceived(
            (data.data.dateReceived &&
              formatDateforPicker(data.data.dateReceived)) ||
            ""
          );
          setRecipient(data.data.recipient.id || "");
          setFinancialImplication(data.data.financialImplication || 0);
          setUploadedDocuments(data.data.uploadedDocuments || []);
        } else {
          setHasError(true);
          notification({
            title: "Error Loading Request",
            message: data.message,
            type: "danger",
          });
        }
      } else {
        setHasError(true);
        notification({
          title: "Error Loading Request",
          message: "Something has gone wrong. Please, try again.",
          type: "danger",
        });
      }
      setIsLoading(false);
    };

    if (requestId) {
      setFormType("edit");
      getDetails();
    } else {
      setFormType("create");
    }

    // getEmployeeList();
    return () =>
      setTimeout(() => {
        toggleScroll();
      }, 0);
  }, [requestId]);

  // const getMdas = async()=>{
  //   axios
  //   .get(`${BASE_API_URL}/Employees/mdas`)
  //   .then((response) => {

  //       console.log("--mdas--")
  //       console.log(response?.data)
  //       // this.setState({
  //         const roleList = response?.data.map((item) => ({
  //           name: `${item.name}`,
  //           value: item.id,
  //         }));
  //         setMdaList(roleList)

  //       // });
  //   })
  // }

  const getEmployeesFromMda = (id) => {
    console.log(id)
    axios
      .get(`${BASE_API_URL}/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {
        // this.setState({
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setEmployeeList(roleList.filter((item) => { return !item.name.includes(Cookies.get('fullname')) }));
      })
  }

   const uploadDocument = (payload) => {
    const options = {
      url: 'https://edogoverp.com/services/api/documents/schedule',
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setUploadProgress(progress);
      },
      data: payload
    };
    console.log(options);
    return axios(options)
      .then((res) => res, (err) => err.response);
  };

  const loadMda = async () => {
    const res = await get({ endpoint: "allMdaDrop", auth: true });
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setMda(data.data);
        const roleList = data?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setMdaList(roleList)
      } else {
        notification({
          title: "Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something has gone wrong when fetching the document journey. Please, try again.",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    loadMda();
  }, []);

  const loadCat = async () => {
    const res = await get({ endpoint: "allCatDrop", auth: true });
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 0) {
        setCategory(data.data);
      } else {
        notification({
          title: "Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something has gone wrong when fetching the document journey. Please, try again.",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    loadCat();
  }, []);

  const getEmployeeList = async () => {
    const res = await get({ endpoint: "employee", auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        console.log(roleList);
        setEmployeeList(roleList.filter((item) => { return !item.name.includes(Cookies.get('fullname')) }));
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };
  console.log(fileArray)
  const validateForm = () => {
    if (!title) return "Please, enter a title for the request";
    if (!description) return "Please, enter a description for the request";
    if (!vendor) return "Please, enter a vendor name for the request";
    if (!dateReceived) return "Please, enter the date the request was received";
    if (fileArray.length < 1 && mFilesArray.length < 1 && !massive) return "Please attach a document to the mail"
    if (getDaysFromDate(dateReceived) > 0)
      return "Date received must not be beyond today";
    if (!recipient && !multiple)
      return "Please, enter a recipient name for the request";
    if (!financialImplication && !massive)
      return "Please, enter a financial Implication for the request";
    return null;
  };

  const submitForm = async () => {
    const error = validateForm();

    if (error) {
      notification({
        title: "Invalid Entry",
        message: error,
        type: "danger",
      });
      return;
    }

    const newMultiple =
      multiple &&
      multiple.map(({ label, value }) => ({
        name: label,
        userId: value,
      }));

    console.log(newMultiple, "new multiple");

    const payload =
      massive === "massive"
        ? {
          title,
          description,
          dateReceived,
          vendor,
          recipient: newMultiple,
          financialImplication: Number(financialImplication) || 0,
          documents: [...fileArray, ...mFilesArray],
        }
        : {
          title,
          description,
          dateReceived,
          vendor,
          recipient,
          financialImplication: Number(financialImplication) || 0,
          documents: [...fileArray, ...mFilesArray],
          meetingCategoryId,
          mdaId,
        };

    console.log(payload, "submitttttttt", requestId);

    setIsSubmitting(true);
    const res =
      formType === "create"
        ? await post({
          endpoint: massive === "massive" ? "broadcast" : "request",
          body: payload,
          auth: true,
        })
        : await put({
          endpoint: massive === "massive" ? "broadcast" : "request",
          body: payload,
          auth: true,
          param: requestId,
        });

    console.log(res);
    setIsSubmitting(false);

    if (res && res.status === 200) {
      console.log(res);
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: `Successful Request ${formType === "create" ? "Creation" : "Update"
            }`,
          message: data.message,
          type: "success",
        });
        if (fileArray.length > 0 || mFilesArray.length > 0) {
          // upload documents then update the request details
          setIsSubmitting(true);
          notification({
            title: "Uploading Documents",
            message: "Currently uploading the attached documents...",
            type: "success",
          });
          let uploadedDocs = fileArray.map(async (file) => {
            console.log(file);
            const body = new FormData();
            body.append("files", file);
            const response = await uploadDocument(body);

            console.log(response);
            if (response && response.status === 200) {
              if (response.data.code === 1) {
                return {
                  name: file.name,
                  path: response.data.doclink,
                  id: response.data.id,
                };
              }
              notification({
                title: "Document Upload Failed",
                message: response.data.message,
                type: "danger",
              });
              return;
            }
            // } else {
            //   notification({
            //     title: "Network Error",
            //     message:
            //       "Something went wrong while uploading the documents for this request. Please, try again later.",
            //     type: "danger",
            //   });
            // }
            return null;
          });

          if (mFilesArray.length > 0) {
            uploadedDocs = [...uploadedDocs, ...mFilesArray];
          }
          // else {
          //   notification({
          //     title: "Upload Error",
          //     message:
          //       "Something went wrong while uploading document from M-FILES",
          //     type: "danger",
          //   });
          // }

          Promise.all(uploadedDocs).then(async (resp) => {
            console.log(uploadedDocs, resp);
            payload.documents = resp;
            console.log(payload.documents);
            const updateRes =
              massive === "massive"
                ? await post({
                  endpoint: "broadcast",
                  body: payload,
                  auth: true,
                  param: massive === "massive" ? null : data.data.id,
                })
                : await put({
                  endpoint: "request",
                  body: payload,
                  auth: true,
                  param: massive === "massive" ? null : data.data.id,
                });
            setIsSubmitting(false);
            console.log(updateRes);

            if (updateRes && updateRes.status === 200) {
              if (updateRes.data.code === 1) {
                notification({
                  title: "Uploading Documents Successful",
                  message:
                    "You have successfully uploaded the documents for this request",
                  type: "success",
                });
              } else {
                notification({
                  title: "Document Upload Failed",
                  message: updateRes.data.message,
                  type: "danger",
                });
              }
            }
            //  else {
            //   notification({
            //     title: "Network Error",
            //     message:
            //       "Something went wrong while uploading the documents for this request. Please, try again later.",
            //     type: "danger",
            //   });
            // }
          });
        }

        // if (formType === 'create') {
        //   // assign the request to the recipient
        //   const assignmentPayload = {
        //     requestId: data.data.id,
        //     assignee: payload.recipient,
        //     comment: '',
        //   };
        //   if (massive !== 'massive') {
        //     const assignmentRes = await post({
        //       endpoint: 'assign',
        //       body: assignmentPayload,
        //       auth: true,
        //     });
        //     console.log(assignmentRes);
        //   }
        // }
        resetForm();
      } else {
        notification({
          title: `Request ${formType === "create" ? "Creation" : "Update"
            } Error`,
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message: `Something went wrong while ${formType === "create" ? "creating" : "updating"
          } an Request. Please, try again later.`,
        type: "danger",
      });
    }
  };

  // file uploadd
  const handleFilesUpload = async () => {
    const uploadField = document.getElementById("image-upload");

    uploadField.click();
    uploadField.onchange = async () => {
      if (!uploadField.files[0]) return;
      const { type, size } = uploadField.files[0];
      console.log(type, size, uploadField.files[0]);

      const supportedTypes = [
        "jpeg",
        "png",
        "gif",
        "xls",
        "xlsx",
        "xlx",
        "csv",
        "vnd.ms-excel",
        "pdf",
        "vnd.openxmlformats-officedocument.wordprocessingml.document",
        "doc",
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "ppt",
        "pptx"
      ];
      const fileType = type.slice(type.indexOf("/") + 1);

      console.log(fileType);
      if (b64FileArray.length + uploadedDocuments.length > 30) {
        notification({
          title: "File Limit Exceeded",
          message: "You can only upload maximum of 30 documents for an idea.",
          type: "danger",
        });
        return;
      }
      if (!supportedTypes.includes(fileType)) {
        notification({
          title: "Invalid File Type",
          message:
            "Invalid file format. Supported file types are pdf, docx, doc, xlsx,csv, ppt,pptx, jpeg, png and gif",
          type: "danger",
        });
        return;
      }
      if (size / 1024 > 500000) {
        notification({
          title: "File Too Large",
          message: "The file size must not be more than 500MB",
          type: "danger",
        });
        return;
      }
      const fileBase64 = await convertImgToBase64(uploadField.files[0]);
      const b64FileSet = new Set(b64FileArray);
      const setSize = b64FileSet.size;
      const newFileList = [...fileArray];

      b64FileSet.add(fileBase64);

      if (setSize < b64FileSet.size) {
        newFileList.push(uploadField.files[0]);
      }

      setB64FileArray([...b64FileSet]);
      setFileArray(newFileList);
    };
  };

  const handleFileDelete = async (index) => {
    console.log(b64FileArray, fileArray);
    const newB64list = b64FileArray.filter((val, i) => i !== index);
    const newFileArray = fileArray.filter((val, i) => i !== index);

    setB64FileArray(newB64list);
    setFileArray(newFileArray);
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setDateReceived("");
    setVendor("");
    setRecipient("");
    setFinancialImplication("");
    setFileArray([]);
    setB64FileArray([]);
    setUploadedDocuments([]);
    setMdaId(null);
    setmeetingCategoryId(null);
    setIsShow1(false);
    setIsShow3(false);
    setMultiple("");
    holdMfilesArray([]);
    setMultipleMda("")
    setRecipientMda('')
  };

  return (
    <div className="request-form w-100 p-r content">
      {isLoading && <IsLoading />}
      <div className="row">
        <div className="col-8 no-margin">
          {/* <TextInput
            className="w-100 m-b-10"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            label="Document Title"
          /> */}
          <div className="row">
            <div style={{ width: "90%" }} className="no-margin col-8">
              <TextInput
                className="w-100 m-b-10"
                label="Document Title *"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {massive === "massive" ? null : (
              <div
                style={{ width: "90%", marginTop: "10px" }}
                className="no-margin col-4"
              >
                <AutoComplete
                  label="Select Category *"
                  options={category.map((cat) => ({
                    name: cat.categoryName,
                    value: cat.id,
                  }))}
                  value={meetingCategoryId}
                  isShow={isShow1}
                  onChange={(value) => {
                    setIsShow3(true);
                    setmeetingCategoryId(value);
                  }}
                  className="w-100 m-b-10"
                />
              </div>
            )}
          </div>
          <TextareaInput
            className="w-100 m-b-10"
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Description *"
          />

          <div style={{ flexDirection: "column" }} className="row">
            <div
              style={{ width: "90%", marginBottom: "20px" }}
              className="col-6 no-margin"
            >
              <TextInput
                className="w-100 m-b-10"
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                label="Vendor/Source *"
              />
            </div>
            <div style={{ width: "90%" }} className="col-6 no-margin">
              <TextInput
                className="w-100 m-b-10"
                value={dateReceived}
                maxDate={formatDateforPicker(new Date())}
                onChange={(e) => setDateReceived(e.target.value)}
                label="Mail Date / Date Received *"
                type="date"
              />
            </div>
          </div>
          <div className="row">
            {massive === "massive" ? (
              <div className="col-12 no-margin">
                <div
                  className="form-group"
                  style={{ width: "90%", marginBottom: "20px" }}
                >
                  <label htmlFor="massive">Recipient MDA *</label>
                  <Select
                    // isMulti
                    className="basic-multi-select"
                    onChange={(value) => {
                      setMultipleMda(value);
                      console.log(value);
                      getEmployeesFromMda(value.value);
                    }}
                    value={multipleMda}
                    options={mdaList.map((m) => ({
                      label: m.name,
                      value: m.value,
                    }))}
                  />
                </div>

              </div>
            ) : (
              <div className="col-6 no-margin" style={{ width: "90%" }}>
                <AutoComplete
                  label="Recipient MDA *"
                  options={mdaList || []}
                  value={recipientMda}
                  // isShow={false}
                  onChange={(value) => {
                    console.log("yesss");
                    getEmployeesFromMda(value);
                    setRecipientMda(value);


                  }}
                  className="w-100 m-b-10"
                />
              </div>
            )}

            {massive === "massive" ? (
              <div className="col-12 no-margin">
                <div
                  className="form-group"
                  style={{ width: "90%", marginBottom: "20px" }}
                >
                  <label htmlFor="massive">Recipient *</label>
                  <Select
                    isMulti
                    className="basic-multi-select"
                    onChange={(value) => {
                      setMultiple(value);
                    }}
                    value={multiple}
                    options={employeeList.map((m) => ({
                      label: m.name,
                      value: m.value,
                    }))}
                  />
                </div>
              </div>
            ) : (
              <div className="col-6 no-margin" style={{ width: "90%" }}>
                <AutoComplete
                  label="Recipient *"
                  options={employeeList || []}
                  value={recipient}
                  isShow={false}
                  onChange={(value) => setRecipient(value)}
                  className="w-100 m-b-10"
                />
              </div>
            )}

            {massive === "massive" ? null : (
              <div className="no-margin col-6" style={{ width: "90%" }}>
                <AutoComplete
                  label="Select MDA*"
                  options={Mda.map((m) => ({
                    name: m.name,
                    value: m.id,
                  }))}
                  value={mdaId}
                  isShow={isShow3}
                  onChange={(value) => {
                    setIsShow1(true);
                    setMdaId(value);
                  }}
                  className="w-100 m-b-10"
                />
              </div>
            )}
          </div>

          <div className="col-12 no-margin">
            <TextInput
              className="w-100 m-b-10"
              value={financialImplication}
              onChange={(e) => setFinancialImplication(e.target.value)}
              label="Financial Implication *"
            />
          </div>

          <div style={{ marginTop: "10px", width: '100%', display: 'flex' }} className="btnDiv">
            <button
              type="button"
              className="btn btn-large w-100"
              onClick={submitForm}
              disabled={isLoading || isSubmitting || hasError}
              style={{ borderRadius: "20px", width: "48%" }}
            >
              Submit
              {isSubmitting && (
                <img src={Loader} className="btn-loading" alt="Loading..." />
              )}
            </button>

            {massive === "massive" ? null : (
              <button
                className="btn btn-large w-100"
                style={{
                  marginLeft: "10px",
                  borderRadius: "20px",
                  width: "48%",
                }}
                onClick={resetReset}
                type="button"
              >
                Reset
              </button>
            )}
          </div>
        </div>
        <div className="col-4  no-margin" style={{ width: "29%" }}>
          <button
            type="button"
            className="btn w-100 btn-large flex flex-v-center space-between"
            onClick={handleFilesUpload}
          >
            Upload Document
            <BsUpload />
            <input type="file" hidden id="image-upload" />
          </button>
          <div className="m-t-20">
            <h4>Uploaded Documents</h4>
          </div>
          <div className="w-100 m-t-10 m-b-20">
            {fileArray &&
              fileArray.map((file, i) => (
                <div
                  className="flex mFiles-hover idea-attachment m-t-10 w-100"
                  style={{ justifyContent: "space-between", padding: "5px" }}
                  key={file.name}
                >
                  <a
                    className="gray underline flex"
                    href={b64FileArray[i]}
                    alt="Idea attached file"
                    download
                  >
                    {file.name}
                  </a>
                  <DeleteTwoTone
                    className="pointer m-l-5"
                    style={{ fontSize: "1.2rem", marginLeft: "auto" }}
                    onClick={() => handleFileDelete(i)}
                  />
                </div>
              ))}
            {uploadedDocuments &&
              uploadedDocuments.map((file) => (
                <UploadedFileItem
                  details={file}
                  type="request"
                  status="pending"
                  key={file.id}
                />
              ))}
            {uploadProgress > 0 && (
              <div className='m-t-10'>
                {/* <p>Upload Progress: {uploadProgress}%</p> */}
                <div style={{ width: '100%', border: '1px solid #ccc' }}>
                  <div
                    style={{
                      width: `${uploadProgress}%`,
                      height: '12px',
                      backgroundColor: '#4caf50',
                      color: '#fff',
                      textAlign: 'center',
                      lineHeight: '16px',
                    }}
                  >
                    {uploadProgress}%
                  </div>
                </div>
              </div>
            )}
          </div>

          <p>
            Supported file formats: jpeg , png , gif , pdf, doc , docx, xls, xlsx , csv, ppt
            Maximum File Size: 500MB
          </p>

          <div style={{ width: "100%" }}>
            <SearchDocument resetDel={resetdel} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralRequestForm;
