/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { GiTrashCan } from 'react-icons/gi';
import PictureModal from '../layouts/PictureModal'
import Loader from '../../assets/svg/loading.svg';
import docLogo from '../../assets/images/docLogo.png';
// import { formatFileUrl } from '../../utility/general';
import { del } from '../../api-services/fetch';
import notification from '../../utility/notification';
import { decodeToken } from '../../utility/auth';

const DocumentPreview = ({ details, type, status }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [picture, setPicture] = useState('')
  const [showPix, setShowPix] = useState(false)

  useEffect(() => {
    const il = localStorage.getItem('il');
    if (il) {
      decodeToken('il');
    }
  }, []);

 const closeModal =()=>{
   setShowPix(false);
 }


  const showPicture = (path) =>{
    console.log('clicked' + showPix)
    setShowPix(true)
    setPicture(path)
  }

  const deleteFile = async (id, name) => {
    const confirmAction = window.confirm(`Click 'OK' to confirm the deletion of "${name}" permanently from the server.`);
    if (!confirmAction) return;

    setIsDeleting(true);
    const res = type === 'request'
      ? await del({
        endpoint: '/api/request/document', param: id, auth: true
      })
      : await del({
        endpoint: '/api/meeting/document', param: id, auth: true
      });
      
    console.log(res);
    setIsDeleting(false);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setIsDeleted(true);
        notification({
          title: 'Successful Document Deletion',
          message: `You have successfully deleted a documents for this request name "${name}"`,
          type: 'success'
        });
      } else {
        notification({
          title: 'Document Upload Failed',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while deleting a document for this request. Please, try again later.',
        type: 'danger'
      });
    }
  };

  return (
    <>
      {!isDeleted && (
        <div className="col-4">
          <div className="item bg-white w-100" style={{margin:"0 auto"}}>
            {
              (details?.filePath?.includes('.jpg') || details?.filePath?.includes('.png') || details?.filePath?.includes('.gif')) 
              ? 
              <a
              className=""
              onClick = {() => showPicture(details.filePath)}
              alt="Idea attached file"
              rel="noreferrer noopener"
              download
            >
              <div className="preview p-r">
                {(details?.filePath?.includes('.jpg') || details?.filePath?.includes('.png') || details?.filePath?.includes('.gif')) ? (
                  <img src={details.filePath}  alt="document preview" />
                ) : (
                  <img src={docLogo} alt="document preview" />
                )}
              </div>
            </a>
              :  
              <a
              className=""
              href={details.filePath}
              alt="Idea attached file"
              target="_blank"
              rel="noreferrer noopener"
              download
            >
              <div className="preview p-r">
                {(details?.filePath?.includes('.jpg') || details?.filePath?.includes('.png') || details?.filePath?.includes('.gif')) ? (
                  <img src={details.filePath} onClick = {()=>(showPicture(details.filePath))} alt="document preview" />
                ) : (
                  <img src={docLogo} alt="document preview" />
                )}
              </div>
            </a>
            }
            
            <div className="footer flex space-between flex-v-center">
              <div className="doc-title">
                <b>Document Title</b>
                <p>{details.fileName}</p>
              </div>
              {/* {type !=='minutes'&& (status === 'pending' || status === 'cancelled' || status === 'declined') && (
                <div className="actions">
                  <button
                    type="button"
                    className="m-l-5 del pointer"
                    onClick={() => deleteFile(details.id, details.fileName)}
                  >
                    {isDeleting ? <img src={Loader} alt="..." className="loading" /> : <GiTrashCan />}
                  </button>
                </div>
              )} */}
            </div>
          </div>
          {showPix && <PictureModal closeModal = {closeModal} picture = {picture}/>}
        </div>
      )}
    </>
  );
};

export default DocumentPreview;
