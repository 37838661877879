/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import moment from 'moment-timezone';

import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';

import IsLoading from '../common/IsLoading';
import Loader from '../../assets/svg/loading.svg';
import { toggleScroll } from '../../utility/general';
import notification from '../../utility/notification';
import { patch, get } from '../../api-services/fetch';
import { formatDateforPicker, formatTimeforPicker } from '../../utility/dateTime';
import CheckCalendar from './CheckCalendar';

const BookMeetingForm = ({
          
          details = {}, updateList, closeModal, reschedule,handleCount
}) => {
  const [showCalenderListForm, setShowCalenderListForm] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [attendees, setAttendees] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [requestId, setRequestId] = useState('');

  const [formType, setFormType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    toggleScroll();

    const getDetails = async () => {
      setIsLoading(true);
      const res = await get({ endpoint: 'meeting', param: details.id, auth: true });
      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          setTitle(data.data.title || '');
          setDescription(data.data.description || '');
          setLocation(data.data.location || '');
          setRequestId(data.data.requestId || '');
          setAttendees((data.data.attendees && data.data.attendees.join(', ')) || '');
          setStartTime((formatTimeforPicker(data.data.startTime)) || '');
          setEndTime((formatTimeforPicker(data.data.endTime)) || '');
          setStartDate((formatDateforPicker(data.data.startDate)) || '');
          setEndDate((formatDateforPicker(data.data.endDate)) || '');
        } else {
          setHasError(true);
          notification({
            title: 'Error Loading Meeting',
            message: data.message,
            type: 'danger'
          });
        }
      } else {
        setHasError(true);
        notification({
          title: 'Error Loading Meeting',
          message: 'Something has gone wrong. Please, try again.',
          type: 'danger'
        });
      }
      setIsLoading(false);
    };

    if (details && details.id) {
      setFormType('edit');
      getDetails();
    } else {
      setFormType('create');
    }

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, [details]);

  const validateForm = () => {
    if (!title) return 'Please, enter a title for the meeting';
    if (!location) return 'Please, enter a location for the meeting';
    if (!attendees) return 'Please, enter a least, one attendee for the meeting';
    if (attendees) {
      const attendeeList = attendees.split(',');

      for (let i = 0; i < attendeeList.length; i += 1) {
        if (attendeeList[i]
          && !(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(attendeeList[i].trim()) || attendeeList[i].trim().match(/^\d{11,}$/))) {
          return `${attendeeList[i]} is not a valid email or phone number`;
        }
      }
    }
    if (!startDate) return 'Please, enter a start date for the meeting';
    if (moment(startDate).isBefore(moment().format('YYYY-MM-DD'))) {
      return 'The start date must not be behind today';
    }
    if (!startTime) return 'Please, enter a start time for the meeting';
    if (moment(`${startDate}T${startTime}`).isBefore(moment())) {
      return 'The start time must not be behind now';
    }
    if (!endDate) return 'Please, enter the end date for the meeting';
    if (!endTime) return 'Please, enter the end time for the meeting';
    if (moment(endDate).isBefore(startDate)) {
      return 'The end date must be ahead of the start date';
    }
    if (moment(`0000-10-10T${endTime}`).isBefore(`0000-10-10T${startTime}`)) {
      return 'The end time must be ahead of the start time';
    }
    if (!description) return 'Please, enter a description for the meeting';
    return null;
  };

  const submitForm = async () => {
    const error = validateForm();

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }

    const payload = {
      title,
      description,
      location,
      attendees: attendees.split(',').map((e) => e.trim()),
      startDate,
      endDate,
      startTime: `${startDate}T${startTime}`,
      endTime: `${endDate}T${endTime}`
    };
    if (requestId) {
      payload.requestId = Number(requestId);
    }
    console.log(payload);

    setIsSubmitting(true);
    let res = null;

    if (reschedule) {
      res = await patch({
        endpoint: '/api/meeting/reschedule', body: payload, auth: true, param: details.id
      });
    } else {
      res = await patch({
        endpoint: '/api/meeting/book', body: payload, auth: true, param: details.id
      });
    }
    console.log(res);
    setIsSubmitting(false);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: `Successful Meeting ${formType === 'create' ? 'Creation' : 'Update'}`,
          message: data.message,
          type: 'success'
        });
        !reschedule && updateList(data.data, formType === 'edit');
closeModal();

        resetForm();
      } else {
        notification({
          title: `Meeting ${formType === 'create' ? 'Creation' : 'Update'} Error`,
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: `Something went wrong while ${formType === 'create' ? 'creating' : 'updating'} an Meeting. Please, try again later.`,
        type: 'danger'
      });
    }
  };

  const resetForm = () => {
    setTitle('');
    setAttendees([]);
    setDescription('');
    setLocation('');
    setEndTime('');
    setStartTime('');
    setStartDate('');
    setEndDate('');
  };

  return (
    <>
      {isLoading && <IsLoading />}
      <div className="overlay" style={{ textAlign: 'left' }}>
        <IoMdClose className="close-btn pointer" onClick={closeModal} />
        <div className="flex meeting-modal">
          <div className="modal-box max-w-800 meeting-booker">
            <div className="w-100 flex flex-v-center space-between header bg-gray">
              <h3>Book Meeting</h3>
              <button
                type="button"
                className="pointer"
                onClick={() => setShowCalenderListForm(!showCalenderListForm)}
              >
                {`${showCalenderListForm ? 'Hide' : 'Check'} Calendar`}
              </button>
            </div>
            <div className="content">
              <div className="row">
                <div className="no-margin col-8">
                  <TextInput
                    className="w-100 m-b-10"
                    label="Add Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="no-margin col-4">
                  <TextInput
                    className="w-100 m-b-10"
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
              </div>
              <TextInput
                className="w-100 m-b-10"
                label="Attendees (enter their emails separated with a comma)"
                value={attendees}
                onChange={(e) => setAttendees(e.target.value)}
              />
              <div className="row">
                <div className="no-margin col-6 no-margin">
                  <div className="row">
                    <div className="no-margin col-7">
                      <TextInput
                        className="w-100 m-b-10"
                        label="Start Date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        minDate={moment().format('YYYY-MM-DD')}
                        type="date"
                      />
                    </div>
                    <div className="no-margin col-5">
                      <TextInput
                        className="w-100 m-b-10"
                        label="Time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        type="time"
                      />
                    </div>
                  </div>
                </div>
                <div className="no-margin col-6 no-margin">
                  <div className="row">
                    <div className="no-margin col-7">
                      <TextInput
                        className="w-100 m-b-10"
                        label="End Date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        minDate={startDate || moment().format('YYYY-MM-DD')}
                        type="date"
                      />
                    </div>
                    <div className="no-margin col-5">
                      <TextInput
                        className="w-100 m-b-10"
                        label="Time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        type="time"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <TextareaInput
                name="mandate"
                className="w-100 m-b-10"
                rows={6}
                label="Details"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-large w-100"
                onClick={submitForm}
                disabled={isLoading || isSubmitting || hasError}
              >
                {`${reschedule ? 'Reschedule' : 'Book'} Meeting`}
                {isSubmitting && <img src={Loader} className="btn-loading" alt="Loading..." />}
              </button>
            </div>
          </div>
          {showCalenderListForm && (
            <CheckCalendar />
          )}
        </div>
      </div>
    </>
  );
};

export default BookMeetingForm;
