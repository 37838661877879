/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
// /* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable react/jsx-no-comment-textnodes */
// /* eslint-disable eqeqeq */
// /* eslint-disable no-shadow */
// /* eslint-disable react/jsx-curly-newline */
// /* eslint-disable jsx-a11y/no-static-element-interactions */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
// /* eslint-disable react/jsx-props-no-multi-spaces */
// /* eslint-disable max-len */
// /* eslint-disable import/no-unresolved */
// // export

// // import Cookies from 'universal-cookie'
// // import { SwapTableContext } from './Contexts/SwapTableContext'
// // const cookie = new Cookies()
// //   const {  handleImage, imageHolder,
// //   } = useContext(SwapTableContext)
// //   const [infoz, setinfoz]= useState([])
// //     let token = cookie.get('token');

// // import React from 'react';
// // import logo from './logo.svg';
// // import './App.css';
// // import RequestMaintenance from './pages/RequestMaintenance';

// // import MaintenanceRequestManagement from './pages/MaintenanceRequestManagement';
// // import Header1 from './components/Header1';

// import React, {
//   useContext, useEffect, useState
// } from 'react';
// import {
//   Select
// } from 'antd';

// import {
//   PaperClipOutlined
// } from '@ant-design/icons';
// // import Cookies from 'universal-cookie';
// import axios from 'axios';
// import { RiDeleteBin5Line } from 'react-icons/ri';
// import { SwapTableContext } from '../Contexts/SwapTableContext';
// // import { convertImgToBase64, toggleScroll } from '../../utility/general';
// import { decodeToken } from '../../utility/auth';

// // const cookie = new Cookies();
// const { Option } = Select;

// function SearchDocument(prop) {
//   const [title, setTitle] = useState([]);
//   const [mFilesVal, setMfilesVal] = useState({ fileUrl: '', name: '' });
//   const [mapVal, setMapVal] = useState([]);
//   // const [tempo, settemp] = useState([]);
//   // const [id, setId] = useState([]);
//   // const [staz, setStaz] = useState();

//   const {
//     handleImage, imageHolder, arrayOfFiles

//   } = useContext(SwapTableContext);

//   const temp = [];
//   // data.data.userId && encodeToken({ ui: data.data.userId }, 'ui');
//   // let ln =

//   // cookie.get('userId');

//   const change = (e) => {
//     console.log(e);
//     setTitle(e);
//   };
//   useEffect(() => {
//     let ui = localStorage.getItem('ui');
//     console.log(ui);
//     if (ui) {
//       ui = decodeToken('ui').ui;
//       console.log(ui);
//       // ln = decodeToken('ln').ln;
//       // setUsername(`${fn} ${ln}`);
//     }

//     console.log(title);
//     searchDocs(ui, title);
//   }, [title]);

//   const searchDocs = (userId, title) => {
//     const options = {
//       url: `https://edogoverp.com/services/api/dashboard/searchdocument?user_id=${userId}&DocTitle=${title}`,
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         crossorigin: true,
//         'Access-Control-Allow-Methods': 'GET',
//         'Access-Control-Allow-Origin': '*'
//       }
//     };
//     // console.log(options);
//     return axios(options)
//       .then((res) => {
//         console.log(res.data);
//         //  let a=res.data
//         const a = res.data.map((tem) => (
//           <Option
//             value={tem.title}
//             key={tem.fileurl}
//           >
//             { tem.title}
//           </Option>
//         ));
//         setMapVal(a);
//         console.log(a);

//         //
//         // (err) => console.log(err.response)
//       });
//   };

//   const del = () => {
//     mFilesVal.name = '';
//     mFilesVal.fileUrl = '';
//     handleImage('', '');
//   };

//   // const handleFileDelete = async (index) => {
//   //   // console.log(b64FileArray, fileArray);
//   //   const arrayOfFiles = arrayOfFiles.filter((val, i) => i !== index);
//   //   // const newFileArray = fileArray.filter((val, i) => i !== index);

//   //   addArrayOfFiles(arrayOfFiles);
//   //   // setFileArray(newFileArray);
//   // };

//   return (
//     <div style={{ width: '100%' }}>

//       <Select

//         showSearch
//         style={{ width: '100%', height: '14px' }}
//         placeholder="Select a file"
//         optionFilterProp="children"
//         value={imageHolder.name}
//         onSearch={(value) => { change(value); }}

//         //     onFocus={(text, index)=>{
//         //       handleImage(index?.value, index?.key)
//         //       setMfilesVal({
//         // name:index?.value,
//         // fileUrl:index?.key
//         //   })
//         // // settemp(...tempo, imageHolder)
//         //   // handleArrayOfFiles([1,2])
//         // }
//         // }
//         // onBlur={onBlur}
//         onChange={(text, index) => {
//           handleImage(index.value, index.key);
//           setMfilesVal({
//             name: index.value,
//             fileUrl: index.key
//           });
//           // settemp(...tempo, imageHolder)
//           // handleArrayOfFiles([1,2])
//         }}
//         filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
//       >
//         {mapVal}
//       </Select>
//       {(imageHolder.name == '' || imageHolder.name == null || imageHolder.name == undefined)

//         ? <h4 style={{ marginTop: '10px', border: '' }}>  Upload from M-Files</h4>
//         : <h4 style={{ marginTop: '10px', border: '' }}>  Uploaded Documents from M-Files</h4>}
//       <div style={{ display: (imageHolder.name == '' || imageHolder.name == null || imageHolder.name == undefined ? 'none' : 'flex'), marginTop: '-5px', border: '' }} className="flex-between mFiles-hover flex-align">

//         {/* <div className="w-100 m-t-10 m-b-20">
//             {arrayOfFiles && arrayOfFiles.map((file, i) => (
//               <div className="flex idea-attachment m-t-10" key={file.doclink}>
//                 <a
//                   className="gray underline flex"
//                   // href={b64FileArray[i]}
//                   alt="Idea attached file"
//                   // download
//                 >
//                   {file.name}
//                 </a>
//                 <RiDeleteBin5Line
//                   className="pointer m-l-5"
//                   style={{ fontSize: '1.2rem' }}
//                   onClick={() => handleFileDelete(i)}
//                 />
//               </div>
//             ))}
//             {/* {uploadedDocuments && uploadedDocuments.map((file) => (
//               <UploadedFileItem
//                 details={file}
//                 type="request"
//                 status="pending"
//                 key={file.id}
//               />
//             ))} */}
//         {/* </div>
//      */}

//         {console.log(arrayOfFiles)}

//         <div className="flex" style={{ border: '', alignItems: 'center', padding: '3px' }}>
//           <PaperClipOutlined />

//           <a style={{ marginLeft: '4px' }}>{imageHolder.name}</a>
//         </div>
//         {/* // eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
//         <div className="pointer" onClick={del}>
//           <RiDeleteBin5Line style={{ fontSize: '16px' }} />
//         </div>
//       </div>

//       { console.log('Hello:', imageHolder, 'Mfiles:', mFilesVal.name, 'Array of:', arrayOfFiles, 'Temp', temp)
//         //  console.log()
//       }
//     </div>
//   );
// }

// export default SearchDocument;




// export



// import Cookies from 'universal-cookie'
// import { SwapTableContext } from './Contexts/SwapTableContext'
// const cookie = new Cookies()
//   const {  handleImage, imageHolder,
//   } = useContext(SwapTableContext)
//   const [infoz, setinfoz]= useState([])
//     let token = cookie.get('token');


// import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import RequestMaintenance from './pages/RequestMaintenance';

// import MaintenanceRequestManagement from './pages/MaintenanceRequestManagement';
// import Header1 from './components/Header1';

import React, {
  useContext, useEffect, useState
} from 'react';
import {
  Select
} from 'antd';

import {
 PaperClipOutlined, DeleteTwoTone
} from '@ant-design/icons';
// import ItemRequest from './ItemRequest';
import Cookies from 'js-cookie';
import axios from 'axios';
import { SwapTableContext } from '../Contexts/SwapTableContext';

// import { SwapTableContext } from './Contexts/SwapTableContext'





const { Option } = Select;


function SearchDocument(prop) {
  const [title, setTitle] = useState([]);
  const [mFilesVal, setMfilesVal] = useState({ fileUrl: '', name: '' });
  const [mapVal, setMapVal] = useState([]);
  let [count, setCount] = useState(0);

  const {
    handleImage, imageHolder, mFilesArray, handleMfilesArray, holdMfilesArray

  } = useContext(SwapTableContext);

console.log(mFilesVal);
  useEffect(() => {
    holdMfilesArray([]);
  }, []);

  const userId = Cookies.get('userId');

  const change = (e) => {
    console.log(e);
    setTitle(e);
  };
  useEffect(() => {
    console.log(userId);
    console.log(title);
    searchDocs(userId, title);
  }, [title]);

  const searchDocs = (userId, title) => {
    const options = {
      url: `https://edogoverp.com/services/api/dashboard/searchdocument?user_id=${parseInt(userId)}&DocTitle=${title}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        crossorigin: true,
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Origin': '*'
      }
    };
    // console.log(options);
    return axios(options)
      .then((res) => {
        console.log(res);
        //  let b=res.data
        // let a =[]
        const a = res?.data?.map((tem) => (
          <Option
            value={tem.title}
            key={tem.fileurl}
          >
            { tem.title}
          </Option>
        ));
        setMapVal(a);
        console.log(a);


        //
        // (err) => console.log(err.response)
      });
  };


  const del = (id) => {
    console.log(id);
    console.log(mFilesArray);
    // dataSource.filter((item) => item.key !== key)
    const pp = mFilesArray.filter((x) => (
      x.id !== id
    ));
    holdMfilesArray(pp);
    imageHolder.name = '';
    imageHolder.fileUrl = '';
    handleImage('', '');
  };

  // const del = (id)=>{
  //   console.log(id)
  //   console.log(mFilesArray)
  //   // dataSource.filter((item) => item.key !== key)
  //   let pp = mFilesArray.filter((x)=>(
  // x.id !== id
  //   ))
  //   holdMfilesArray(pp)
  //     mFilesVal.name="";
  //     mFilesVal.fileUrl="";
  //     handleImage("","")

  // }









  return (
    <div style={{ width: '100%' }}>

      <Select
        showSearch
        style={{ width: '100%' }}
        placeholder="Select a file"
        optionFilterProp="children"
        onSearch={(value) => { change(value); }}
        value={imageHolder.name}
        // onFocus={onFocus}
        // onBlur={onBlur}
        onChange={(text, index) => {
          handleImage(index.value, index.key);

          handleMfilesArray({
            id: count++,
            name: index.value,
            path: index.key
          });
          // let a = prop?.handleDocAdd({
          //   key:count++,
          //   name : index.value,
          //   path : index.key
          // })
          setCount(count);
          setMfilesVal({
            name: index.value,
            fileUrl: index.key
          });
          handleImage('', '');
        }}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {mapVal}
      </Select>

      { mFilesArray.map((x) => (
        <div
          style={{ padding: '5px', border: '1px solid ash', marginBottom: '2px' }}
          // style={{display:(mFilesVal.name=="" ||mFilesVal.name==null||mFilesVal.name==undefined ? "none":"flex"), marginTop:"5px", border:"2px solid red"}}
          className="flex-between mFiles-hover flex-align"
        >

          <div className="flex-align">
            <PaperClipOutlined />
            <p style={{ marginLeft: '3px', marginBottom: '0px' }}>{x.name}</p>
          </div>

          
          <div className="pointer" onClick={() => { del(x.id); }}>
            <DeleteTwoTone style={{ fontSize: '16px' }} />
          </div>

        </div>
      ))}


      { console.log('Hello:', mFilesArray)
        //  console.log()
      }
    </div>
  );
}

export default SearchDocument;


