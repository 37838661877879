/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useRef } from "react";
import moment from "moment-timezone";
import { IoMdClose } from "react-icons/io";
import MailIcon from '@material-ui/icons/Mail'
import SearchInput from "../inputs/SearchInput";
import LocationDetails from "../layouts/LocationDetails";
import RequestDetails from "../layouts/RequestDetails";
import { Pagination } from 'antd';
import { get,post } from "../../api-services/fetch";
import IsLoading from "../common/IsLoading";
import Greeting from "../common/Greeting";
import {
  formatSimpleDate,
  formatDate,
  formatDateforPicker,
  formatLongDate,
} from "../../utility/dateTime";
import { formatStatus } from "../../utility/general";
import SelectInputColored from "../inputs/SelectInputColored";
import {BsEnvelope} from 'react-icons/bs';
import CalendarBtn from "../layouts/CalendarBtn";
import Massive from "../layouts/massive";
import { Badge } from "@material-ui/core";

const GeneralRequests = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchText, setSearchText] = useState("");
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("All Requests");
  const [status, setStatus] = useState("");
  const [endpoint, setEndPoint] = useState("requests");
  const [listUpdate, setListUpdate] = useState(false)
  const focusRef = useRef();
  const [paginationLength, setpaginationLength] = useState(10);
  const [massiveOpen, setMassiveOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - All Request";

    getList(1,10,searchText);

  }, [searchText, startDate, endDate, status, listUpdate]);

  
  const getList = async (pageNumber, pageSize, queryFilter) => {
    let payload = {
      pageNumber,
      queryFilter:queryFilter,
      pageSize,
      status
    }

    // focusRef.current.focus();
    // setIsLoading(true);
    setErrorMsg("");

    // status === "" ? endpoint = "requests" : endpoint = "requests

    const res = await post({
      endpoint: "requests",
      body:payload,
      pQuery: { query: searchText, status,startDate,
        endDate },
    });

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setDetails(data.data);
        setpaginationLength(JSON.parse(res.headers['x-pagination']).TotalCount)

        if (data.data.length < 1) {
          if (searchText) {
            setErrorMsg("Request not found");
          } else {
            setErrorMsg("No Request yet");
          }
        }
      } else {
        setErrorMsg(data.message);
      }
    } else {
      setErrorMsg("Something has gone wrong. Please, try again.");
    }
    setIsLoading(false);
  };

  const handleDate = async (e) => {
    const { value } = e.target;
    setSelectedDate(value);
    setSearchText("");

    switch (value) {
      case "Today":
        // get meetings for today
        setStartDate(formatDateforPicker(new Date()));
        setEndDate(formatDateforPicker(new Date()));
        break;
      case "This Week":
        // get meetings for this week
        setStartDate(formatDateforPicker(moment().startOf("week").toDate()));
        setEndDate(formatDateforPicker(moment().endOf("week").toDate()));
        break;
      case "This Month":
        // get meetings for this month
        setStartDate(formatDateforPicker(moment().startOf("month").toDate()));
        setEndDate(formatDateforPicker(moment().endOf("month").toDate()));
        break;
      case "Calendar":
        // get meetings for the day selected in the calendar
        setStartDate(formatDateforPicker(calendarDate));
        setEndDate(formatDateforPicker(calendarDate));
        break;
      case "All Requests":
        // get meetings for the day selected in the calendar
        setStartDate("");
        setEndDate("");
        break;
      default:
        break;
    }
  };

  const handlePaginationChange = (page,pageSize,queryFilter)=>{
    getList(page,pageSize,queryFilter);

  }


  const handleCalendarDate = async (val) => {
    setCalendarDate(val);
    setStartDate(formatDateforPicker(val));
    setEndDate(formatDateforPicker(val));
    setSelectedDate("Calendar");
    setSearchText("");
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    if (name === "searchText") {
      setSearchText(value);
    } else if (name === "status") {
      setStatus(value);
    }
  };

  const statusOptions = [
    { name: "All Requests", value: "" },
    { name: "Actioned", value: "actioned" },
    { name: "Assigned", value: "assigned" },
    { name: "Communique", value: "communique" },
    { name: "Pending", value: "pending" },
    { name: "Declined", value: "declined" },
  ];

  return (
    <div className="w-100" tabIndex={-1} ref={focusRef}>
      <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between m-t-20">
        <h3>
          General Requests -&nbsp;
          {selectedDate === "Calendar"
            ? formatLongDate(moment(calendarDate).format("YYYY-MM-DD"))
            : selectedDate}
        </h3>
      </div>
      {massiveOpen && <Massive closeModal={() => setMassiveOpen(false)} />}
      <div className="w-100 flex space-between flex-v-center m-t-20">
        <div className="flex">
          <SelectInputColored
            className="m-r-10"
            options={[
              "All Requests",
              "Today",
              "This Week",
              "This Month",
              "Calendar",
            ]}
            value={selectedDate}
            onChange={handleDate}
          />
          <CalendarBtn
            value={calendarDate}
            onChange={(val) => handleCalendarDate(val)}
          />
        </div>
        <div className="flex flex-v-center">
          <h4
            style={{
              fontWeight: "900",
              cursor: "pointer",
              marginRight: "15px",
              border: "1px solid #1C811C",
              padding: "10px",
              background: "#1C811C",
              borderRadius: "3px",
              marginBottom: "0",
              color: "white",
            }}
            onClick={() => {
              setMassiveOpen(true);
            }}
          >
            Communique
          </h4>
          <SelectInputColored
            className="m-r-10"
            options={statusOptions || []}
            value={status}
            onChange={handleSearch}
            name="status"
          />
          <SearchInput
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            className="m-l-5"
            placeholder="Search list..."
          />
        </div>
      </div>
      <table className="table-view m-t-10">
        <thead className="table-header">
          <tr className="table-row">
            <th className="th">
              <p>Title</p>
            </th>
            <th className="th">
              <p>Upload Date</p>
            </th>
            <th className="th">
              <p>Vendor/Source</p>
            </th>
            <th className="th">
              <p>Status</p>
            </th>
            <th className="th">
              <p>Comments</p>
            </th>
            <th className="th">
              <p>Last Treated</p>
            </th>
          </tr>
        </thead>
        <tbody className="table-body">
          {!(isLoading || errorMsg) &&
            details &&
            details.filter((request)=>{return request.status.includes(status)}).map((item) => <ListItem key={item.id} details={item} updateInfo={()=>setListUpdate(!listUpdate)}/>)}
        </tbody>
      </table>
      <div style={{marginTop:"15px", display:"flex", alignItems:'flex-end', textAlign:'right'}}>
        <Pagination onChange= {handlePaginationChange} defaultCurrent={1} total={paginationLength} />
      </div>

      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      {isLoading && <IsLoading />}
    </div>
  );
};

const ListItem = ({ details, updateInfo }) => {
  const [itemDetails, setItemDetails] = useState(details || {});
  const [showDetails, setShowDetails] = useState(false);

  // const updateInfo = (newItem) => {
  //   setItemDetails(newItem || {});
  // };

  return (
    <>
      <tr
        className={`${showDetails && "active"} table-row pointer`}
        onClick={() => setShowDetails(!showDetails)}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {itemDetails.title}
          {/* {showDetails && (
            <IoMdClose
              className="dropdown-close-btn pointer"
              onClick={() => setShowDetails(!showDetails)}
            />
          )} */}
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          {formatDate(itemDetails.createdAt)}
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          {itemDetails.vendor}
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          <div className={`status ${itemDetails.status}-status`}>
            {formatStatus(itemDetails.status)}
          </div>
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          <Badge badgeContent={itemDetails.queryCount} >
            <MailIcon style={{color:'green'}} />
          </Badge>
          
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          {formatSimpleDate(itemDetails.updatedAt || itemDetails.createdAt)}
        </td>
      </tr>
      {showDetails && (
        <tr className="table-row bg-light-gray">
          <td colSpan={5}>
            <RequestDetails
              requestId={itemDetails.id}
              updated={updateInfo}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default GeneralRequests;
