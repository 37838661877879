/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable comma-spacing */
/* eslint-disable import/newline-after-import */
/* eslint-disable arrow-parens */
/* eslint-disable arrow-spacing */
/* eslint-disable space-infix-ops */
/* eslint-disable indent */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { BsUpload } from 'react-icons/bs';
import { SiGooglehangoutsmeet } from 'react-icons/si';
import qs from 'query-string';
// import axios from "axios";

import LocationDetails from '../layouts/LocationDetails';
import MeetingRequestForm from '../layouts/MeetingRequestForm';
import GeneralRequestForm from '../layouts/GeneralRequestForm';

const CreateRequest = ({ history: { location }, match: { params: { requestId, meetingId } } }) => {
  const [formType, setFormType] = useState('general');
  const [request, setRequest] = useState(null);
  // const [category, setCategory] = useState([]);
  console.log(requestId,'reeeeeeeeeeeeeee');

  useEffect(() => {
    window.scrollTo(0, 0);

    const query = qs.parse(location.search);

    if (query && query.type) {
      setFormType('meeting');

      if (query.requestId) {
        setRequest({ id: query.requestId, name: query.requestTitle });
      }
    }

    if (meetingId) {
      setFormType('meeting');
    }
  }, [meetingId, location]);
//   const url= 'https://run.mocky.io/v3/23df0180-290d-4f98-bb0b-925b92b46e6e';
// useEffect(() => {
//  // eslint-disable-next-line no-undef
//  axios.get(url).then(res=>{
//   setCategory(res.data.Data);
//  }).catch(err=>{
//    console.log(err);
//  });
// },[category]);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-baseline">
        <h3>{`${requestId ? 'Update' : 'Create'} Request`}</h3>
        <LocationDetails />
      </div>
      <div className="create-request-btns m-t-20">
        <button
          type="button"
          className={`${formType === 'general' ? 'active' : 'pointer'} m-r-10`}
          onClick={() => setFormType('general')}
        >
          <BsUpload />
          General Request
        </button>
        <button
          type="button"
          className={`${formType === 'meeting' ? 'active' : 'pointer'}`}
          onClick={() => setFormType('meeting')}
        >
          <SiGooglehangoutsmeet />
          Meeting Request
        </button>
      </div>
      <div className="w-100 m-b-20">
        {formType === 'general' && <GeneralRequestForm requestId={requestId} />}
        {formType === 'meeting' && <MeetingRequestForm meetingId={meetingId} request={request} />}
      </div>
    </div>
  );
};

export default CreateRequest;
