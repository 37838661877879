/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
import React, { useState, useEffect, useContext } from 'react';
import { BsUpload } from 'react-icons/bs';
import { DeleteTwoTone } from "@ant-design/icons";
import { RiDeleteBin5Line } from 'react-icons/ri';
import moment from 'moment-timezone';
import AutoComplete from "../inputs/AutoComplete";
import Cookies from 'js-cookie'

import { get, put, post, patch } from '../../api-services/fetch';
import { convertImgToBase64, toggleScroll } from '../../utility/general';
import Loader from '../../assets/svg/loading.svg';
import { formatDateforPicker, formatTimeforPicker } from '../../utility/dateTime';

import axios from "axios";
import IsLoading from '../common/IsLoading';
import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import notification from '../../utility/notification';
import UploadedFileItem from './UploadedFileItem';
import { uploadDocument } from '../../api-services/otherApis';

import { SwapTableContext } from '../Contexts/SwapTableContext';
import SearchDocument from './SearchDocument';

const MeetingRequestForm = ({ meetingId, request }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [attendees, setAttendees] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [requestId, setRequestId] = useState('');
  const [fileArray, setFileArray] = useState([]);
  const [b64FileArray, setB64FileArray] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [recipient, setRecipient] = useState("");
  const [recipientMda, setRecipientMda] = useState("");
  const [mdaId, setMdaId] = useState(null);

  const [formType, setFormType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [Mda, setMda] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [mdaList, setMdaList] = useState([]);
  const [multipleMda, setMultipleMda] = useState("");

  const BASE_API_URL = `https://edogoverp.com/services/api`



  const {
    handleImage, imageHolder

  } = useContext(SwapTableContext)

  useEffect(() => {
    toggleScroll();

    const getDetails = async () => {
      setIsLoading(true);
      const res = await get({ endpoint: 'meeting', param: meetingId, auth: true });
      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          setTitle(data.data.title || '');
          setDescription(data.data.description || '');
          setLocation(data.data.location || '');
          setRequestId(data.data.requestId || '');
          setAttendees((data.data.attendees && data.data.attendees.join(', ')) || '');
          setStartTime((formatTimeforPicker(data.data.startTime)) || '');
          setEndTime((formatTimeforPicker(data.data.endTime)) || '');
          setStartDate((formatDateforPicker(data.data.startDate)) || '');
          setEndDate((formatDateforPicker(data.data.endDate)) || '');
          setMdaId(data.data.mdaId || '');
          setUploadedDocuments(data.data.uploadedDocuments || []);
          setRecipient(data.data.recipient || '');
        } else {
          setHasError(true);
          notification({
            title: 'Error Loading Request',
            message: data.message,
            type: 'danger'
          });
        }
      } else {
        setHasError(true);
        notification({
          title: 'Error Loading Request',
          message: 'Something has gone wrong. Please, try again.',
          type: 'danger'
        });
      }
      setIsLoading(false);
    };

    if (meetingId) {
      setFormType('edit');
      getDetails();
    } else {
      setFormType('create');
    }

    if (request && request.name) {
      setTitle(request.name);
    }

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, [meetingId, request]);

  const getEmployeeList = async () => {
    const res = await get({ endpoint: "employee", auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data.map((item) => ({
          name: `${item.firstName} ${item.lastName} ${item.role ? item.role : ""
            } ${item.mda}`,
          value: item.id,
        }));
        console.log(roleList);
        setEmployeeList(roleList);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };


  const loadMda = async () => {
    const res = await get({ endpoint: "allMdaDrop", auth: true });
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setMda(data.data);
      } else {
        notification({
          title: "Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message:
          "Something has gone wrong when fetching the document journey. Please, try again.",
        type: "danger",
      });
    }
  };


  useEffect(() => {
    loadMda();
    // getEmployeeList()
    getMdas()


  }, []);


  const getMdas = () => {
    axios
      .get(`${BASE_API_URL}/Employees/mdas`)
      .then((response) => {

        console.log("--mdas--")
        console.log(response?.data)
        // this.setState({
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setMdaList(roleList)

        // });
      })
  }

  const getEmployeesFromMda = (id) => {
    axios
      .get(`${BASE_API_URL}/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {

        console.log("--employeesByNads--")
        console.log(response?.data)
        // this.setState({
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setEmployeeList(roleList.filter((item) => { return !item.name.includes(Cookies.get('fullname')) }));

        // setMdaList(roleList)

        // });
      })
  }



  const submitForm = async () => {
    const error = validateForm();

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }

    const payload = {
      title,
      description,
      location,
      attendees: attendees.split(',').map((e) => e.trim()),
      startDate,
      endDate,
      startTime: `${startDate}T${startTime}`,
      endTime: `${endDate}T${endTime}`,
      mdaId,
      recipient,
      uploadedDocuments:[]
    };

    const uploadedDocs = fileArray.map(async (file) => {
      const body = new FormData();
      body.append('files', file);
      const response = await uploadDocument(body);
      console.log(response);
      if (response && response.status === 200) {
        if (response.data.code === 1) {
          return {
            name: file.name,
            path: response.data.doclink,
            id: response.data.id
          };
        }
      }
      return null;
    });

    if (imageHolder.name != '' && imageHolder.doclink != 0) {
      uploadedDocs.push({
        name: imageHolder.name,
        path: imageHolder.doclink
        // id: response.data.id
      })
    }
    

    await Promise?.all(uploadedDocs)?.then(async (resp) => {
      console.log(uploadedDocs, resp);
      payload.uploadedDocuments = resp;
    });

   
    if (request && request.id) {
      payload.requestId = Number(request.id);
    } else {
      payload.requestId = Number(requestId);
    }
    console.log(payload);

    setIsSubmitting(true);
    const res = formType === 'create'
      ? await post({
        endpoint: 'meeting', body: payload, auth: true
      }) : await patch({
        endpoint: 'meeting', body: payload, auth: true, param: meetingId
      });
    console.log(res);
    setIsSubmitting(false);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: `Successful Request ${formType === 'create' ? 'Creation' : 'Update'}`,
          message: data.message,
          type: 'success'
        });
        if (fileArray.length > 0 || (imageHolder.name != '' && imageHolder.doclink != 0)) {
          // upload documents then update the request details
          setIsSubmitting(true);
          notification({
            title: 'Uploading Documents',
            message: 'Currently uploading the attached documents...',
            type: 'success'
          });
          const uploadedDocs = fileArray.map(async (file) => {
            console.log(file);
            const body = new FormData();
            body.append('files', file);
            const response = await uploadDocument(body);

            console.log(response);
            if (response && response.status === 200) {
              if (response.data.code === 1) {
                return {
                  name: file.name,
                  path: response.data.doclink,
                  id: response.data.id
                };
              }
              // notification({
              //   title: 'Document Upload Failed',
              //   message: response.data.message,
              //   type: 'danger'
              // });
            }
            //  else {
            //   notification({
            //     title: 'Network Error',
            //     message: 'Something went wrong while uploading the documents for this request. Please, try again later.',
            //     type: 'danger'
            //   });
            // }
            return null;
          });

          if (imageHolder.name != '' && imageHolder.doclink != 0) {
            uploadedDocs.push({
              name: imageHolder.name,
              path: imageHolder.doclink
              // id: response.data.id
            })
          }
          // else {
          //   notification({
          //     title: 'Upload Error',
          //     message: 'Something went wrong while uploading document from M-FILES',
          //     type: 'danger'
          //   });
          // }

          Promise.all(uploadedDocs).then(async (resp) => {
            console.log(uploadedDocs, resp);
            payload.uploadedDocuments = resp;
            const updateRes = await patch({
              endpoint: 'meeting', body: payload, auth: true, param: data.data.id
            });
            setIsSubmitting(false);
            console.log(updateRes);

            if (updateRes && updateRes.status === 200) {
              if (updateRes.data.code === 1) {
                notification({
                  title: 'Uploading Documents Successful',
                  message: 'You have successfully uploaded the documents for this request',
                  type: 'success'
                });
              }
              // else {
              //   notification({
              //     title: 'Document Upload Failed',
              //     message: updateRes.data.message,
              //     type: 'danger'
              //   });
              // }
            }
            else {
              notification({
                title: 'Network Error',
                message: 'Something went wrong while uploading the documents for this request. Please, try again later.',
                type: 'danger'
              });
            }
          });
        }
        resetForm();
      } else {
        notification({
          title: `Request ${formType === 'create' ? 'Creation' : 'Update'} Error`,
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: `Something went wrong while ${formType === 'create' ? 'creating' : 'updating'} an Request. Please, try again later.`,
        type: 'danger'
      });
    }
  };

  const handleFilesUpload = async () => {
    const uploadField = document.getElementById('image-upload');

    uploadField.click();
    uploadField.onchange = async () => {
      if (!uploadField.files[0]) return;
      const { type, size } = uploadField.files[0];
      console.log(type, size, uploadField.files[0]);

      const supportedTypes = [
        'jpeg', 'png', 'gif',"csv", "xls","xlx", "vnd.ms-excel",
        "xlsx", 'pdf', 'ppt', 'pptx',
        'vnd.openxmlformats-officedocument.wordprocessingml.document', 'doc',
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      const fileType = type.slice(type.indexOf('/') + 1);

      console.log(fileType);
      if (b64FileArray.length + uploadedDocuments.length > 30) {
        notification({
          title: 'File Limit Exceeded',
          message: 'You can only upload maximum of 30 documents for an idea.',
          type: 'danger'
        });
        return;
      }
      if (!supportedTypes.includes(fileType)) {
        notification({
          title: 'Invalid File Type',
          message: 'Invalid file format. Supported file types are pdf, docx, doc, ppt, pptx, xlsx, jpeg, png and gif',
          type: 'danger'
        });
        return;
      }
      if (size * 50 / 1024 > 500000) {
        notification({
          title: 'File Too Large',
          message: 'The file size must not be more than 500MB',
          type: 'danger'
        });
        return;
      }
      const fileBase64 = await convertImgToBase64(uploadField.files[0]);
      const b64FileSet = new Set(b64FileArray);
      const setSize = b64FileSet.size;
      const newFileList = [...fileArray];

      b64FileSet.add(fileBase64);

      if (setSize < b64FileSet.size) {
        newFileList.push(uploadField.files[0]);
      }

      setB64FileArray([...b64FileSet]);
      setFileArray(newFileList);
    };
  };

  const handleFileDelete = async (index) => {
    console.log(b64FileArray, fileArray);
    const newB64list = b64FileArray.filter((val, i) => i !== index);
    const newFileArray = fileArray.filter((val, i) => i !== index);

    setB64FileArray(newB64list);
    setFileArray(newFileArray);
  };

  const validateForm = () => {
    if (!title) return 'Please, enter a title for the meeting';
    if (!location) return 'Please, enter a location for the meeting';
    if (!attendees) return 'Please, enter a least, one attendee for the meeting';
    if (attendees) {
      const attendeeList = attendees.split(',');

      for (let i = 0; i < attendeeList.length; i += 1) {
        if (attendeeList[i]
          && !(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(attendeeList[i].trim()) || attendeeList[i].trim().match(/^\d{11,}$/))) {
          return `${attendeeList[i]} is not a valid email or phone number`;
        }
      }
    }
    if (!startDate) return 'Please, enter a start date for the meeting';
    if (moment(startDate).isBefore(moment().format('YYYY-MM-DD'))) {
      return 'The start date must not be behind today';
    }
    if (!startTime) return 'Please, enter a start time for the meeting';
    if (moment(`${startDate}T${startTime}`).isBefore(moment())) {
      return 'The start time must not be behind now';
    }
    if (moment(`${startDate}T${endTime}`).isBefore(moment(`${startDate}T${startTime}`))) {
      return 'The start time must not be behind the end time';
    }
    if (!endDate) return 'Please, enter the end date for the meeting';
    if (!endTime) return 'Please, enter the end time for the meeting';
    if (moment(endDate).isBefore(startDate)) {
      return 'The end date must be ahead of the start date';
    }
    if (moment(`0000-10-10T${endTime}`).isBefore(`0000-10-10T${startTime}`) && (moment(endDate).isSame(startDate))) {
      return 'The end time must be ahead of the start time';
    }
    if (!description) return 'Please, enter a description for the meeting';
    if (!recipient) return 'Please, enter a recipient of the meeting request';
    if (!mdaId) return 'Please, select your Mda';
    return null;
  };

  const resetForm = () => {
    setTitle('');
    setAttendees([]);
    setDescription('');
    setLocation('');
    setEndTime('');
    setStartTime('');
    setStartDate('');
    setEndDate('');
    setFileArray([]);
    setB64FileArray([]);
    handleImage('', '')
    setUploadedDocuments([]);
    setRecipient('')
    setMdaId('')
  };

  return (
    <div className="request-form w-100 p-r content">
      {isLoading && <IsLoading />}
      <div className="row">
        <div className="col-8 no-margin">
          <div className="w-100 flex-between">
            <div className="w-100 no-margin m-r-10">
              <TextInput
                className="w-100 m-b-10"
                label="Add Title *"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="no-margin ">
              <TextInput
                className="w-100 m-b-10"
                label="Location *"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
          </div>
          <TextInput
            className="w-100 m-b-10"
            label="Attendees *(enter their emails separated with a comma)"
            value={attendees}
            onChange={(e) => setAttendees(e.target.value)}
          />
          <div className="row w-100">
            <div className='flex-between w-100' style={{ width: "80%" }}>

              <div className="no-margin w-100 m-r-10" >


                <div className="col-6 no-margin w-100">
                  <TextInput
                    id="startDate"
                    name="Start Date"
                    className="w-100 m-b-10"
                    label="Start Date *"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    minDate={moment().format('YYYY-MM-DD')}
                    type="date"
                  />
                </div>

              </div>
              <div className="no-margin w-100 no-margin">
                <div className=" w-100">
                  <div className="no-margin w-100">
                    <TextInput
                      id="endDate"
                      name="End Date"
                      className="w-100 m-b-10"
                      label="End Date *"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      minDate={startDate || moment().format('YYYY-MM-DD')}
                      type="date"
                    />
                  </div>

                </div>


              </div>
            </div>

            <div className='flex-between w-100' style={{ width: "90%" }}>
              <div className="col-6 no-margin w-100">
                <TextInput
                  id="startTime"
                  name="Start time"
                  className="w-100 m-b-10"
                  label="Start Time *"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  type="time"
                />
              </div>
              <div className="row no-margin  w-100">
                <TextInput
                  id="endDate"
                  name="End Date"
                  className="w-100 m-b-10"
                  label="End Time *"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  type="time"
                />
              </div>
            </div>


            <div className="col-6 no-margin" style={{ width: "90%" }}>
              <AutoComplete
                label="Recipient MDA *"
                options={mdaList || []}
                value={recipientMda}
                // isShow={false}
                onChange={(value) => {
                  setRecipientMda(value)
                  getEmployeesFromMda(value)

                }}
                className="w-100 m-b-10"
              />
            </div>

            <div className="col-6 no-margin" style={{ width: "90%" }}>
              <AutoComplete
                label="Recipient *"
                options={employeeList || []}
                value={recipient}
                onChange={(value) => setRecipient(value)}
                className="w-100 m-b-10"
              />
            </div>


            <div className="col-6 no-margin" style={{ width: "90%" }}>
              <AutoComplete
                label="Select MDA *"
                options={Mda.map((m) => ({
                  name: m.name,
                  value: m.id,
                }))}
                value={mdaId}
                onChange={(value) => {
                  setMdaId(value);
                }}
                className="w-100 m-b-10"
              />
            </div>


          </div>
          <TextareaInput
            name="mandate"
            className="w-100 m-b-10"
            rows={6}
            label="Details *"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading || isSubmitting || hasError}
          >
            Submit
            {isSubmitting && <img src={Loader} className="btn-loading" alt="Loading..." />}
          </button>
        </div>
        <div className="col-4 no-margin">
          <button
            type="button"
            className="btn w-100 btn-large flex flex-v-center space-between"
            onClick={handleFilesUpload}
          >
            Upload Document
            <BsUpload />
            <input type="file" hidden id="image-upload" />
          </button>
          <div className="m-t-20">
            <h4>Uploaded Documents</h4>
          </div>
          <div className="w-100 m-t-10 m-b-20">
            {fileArray && fileArray.map((file, i) => (
              <div className="flex idea-attachment m-t-10" key={file.name}>
                <a
                  className="gray underline flex"
                  href={b64FileArray[i]}
                  alt="Idea attached file"
                  download
                >
                  {file.name}
                </a>
                <DeleteTwoTone
                  className="pointer m-l-5"
                  style={{ fontSize: "1.2rem", marginLeft: "auto" }}
                  onClick={() => handleFileDelete(i)}
                />
                {/* <RiDeleteBin5Line
                  className="pointer m-l-5"
                  style={{ fontSize: '1.2rem' }}
                  onClick={() => handleFileDelete(i)}
                /> */}
              </div>
            ))}
            {uploadedDocuments && uploadedDocuments.map((file) => (
              <UploadedFileItem
                details={file}
                type="request"
                status="pending"
                key={file.id}
              />

            ))}
            <div style={{ width: '100%' }}><SearchDocument /></div>

          </div>
          <p>
            Supported file formats: jpeg , png , gif , pdf, doc , docx, xls, xlsx , ppt <br />
            Maximum File Size: 500MB
          </p>
        </div>
      </div>
    </div>
  );
};

export default MeetingRequestForm;
