/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-alert */


/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";

// import UserAvater from '../../assets/images/user-avater.png' formatFileUrl  formatTimeFromNow;
import "../../assets/css/requestDetails.css";
import IsLoading from "../common/IsLoading";
import { formatCurrency, formatStatus } from "../../utility/general";
import { get } from "../../api-services/fetch";
import { newget } from "../../api-services/newfetch";
import notification from "../../utility/notification";
import { formatDate, formatDateTime } from "../../utility/dateTime";
// import PresentValue from './PresentValue';
import OptionsBtn from "./OptionsBtn";
import RequestAssignmentForm from "./RequestAssignmentForm";
import RequestAssignmentFormMulti from "./RequestAssignmentFormMulti";
import RequestCommentForm from "./RequestCommentForm";
import DocumentPreview from "./DocumentPreview";
import { decodeToken } from "../../utility/auth";
import RequestQueryForm from "./QueryForm";
// import profileImg from '../../assets/images/mustypicture.jpg'  updateList;
import JourneyItem from "./journeyItem";
import { Link } from "react-router-dom";

const RequestDetails = ({ requestId, handleDetails, currentId, current, updated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [assignments, setAssignments] = useState(null);
  const [commentId, setCommentId] = useState(null);

  const [userId, setUserId] = useState(0);

  const [showAssignmentForm, setShowAssignmentForm] = useState(false);
  const [showAssignmentFormMulti, setShowAssignmentFormMulti] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [ourStatus, setOurStatus] = useState("");
  const [showQueryForm, setShowQueryForm] = useState(false);
  // const [isActive, setIsActive] = useState(false);

  // updateStatus(details.id, details.title, 'declined'),
  console.log(details, "details", userId);
  // const handleisActive = () => {
  //   console.log(isActive);
  //   setIsActive(!isActive);
  // };

  let forPa;
  console.log(forPa)
  console.log(decodeToken("fp"))

  useEffect(() => {
    const fp = localStorage.getItem("fp");
  if (fp) {
    forPa = decodeToken("fp").fp;
  }
    const ui = localStorage.getItem("ui");
    if (ui) {
      setUserId(decodeToken("ui").ui);
    }
    const getDetails = async () => {
      setIsLoading(true);
      const res = await newget({
        endpoint: "oneCat",
        param: requestId,
        auth: true,
      });
      if (res && res.status === 200) {
        const { data } = res;
        console.log(data, "data");
        if (data.code === 1) {
          setDetails(data.data);
        } else {
          notification({
            title: "Error Loading Request",
            message: data.message,
            type: "danger",
          });
        }
      } else {
        notification({
          title: "Error Loading Request",
          message: "Something has gone wrong. Please, try again.",
          type: "danger",
        });
      }
      setIsLoading(false);
    };

console.log(decodeToken("fp")?.fp === 'True')

    const getAssignmentJourney = async () => {
      setIsLoading(true);
      const res = await get({
        endpoint: "assign",
        param: requestId,
        auth: true,
      });
      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          setAssignments(data.data);
          setCommentId(data.data[0] && data.data[0].id);
        } else {
          notification({
            title: "Document Journey Error",
            message: data.message,
            type: "danger",
          });
        }
      } else {
        notification({
          title: "Network Error",
          message:
            "Something has gone wrong when fetching the document journey. Please, try again.",
          type: "danger",
        });
      }
      setIsLoading(false);
    };

    getDetails();
    getAssignmentJourney();
  }, [requestId]);

  // const updateStatus = async (id, name, status) => {
  //   const confirmAction = confirm(`Click OK to confirm updating the status of "${name}" to ${status.toUpperCase()}`);
  //   if (!confirmAction) return;
  //   setIsLoading(true);
  //   const res = await patch({
  //     endpoint: '/api/request/status', param: id, pQuery: { status }, auth: true
  //   });

  //   console.log(res);

  //   if (res && res.status === 200) {
  //     const { data } = res;
  //     if (data.code === 1) {
  //       notification({
  //         title: 'Successful Status Update',
  //         message: data.message,
  //         type: 'success'
  //       });
  //       const updatedItem = { ...details, status };
  //       setDetails(updatedItem);
  //       updateList(updatedItem, true);
  //     } else {
  //       notification({
  //         title: 'Status Update Error',
  //         message: data.message,
  //         type: 'danger'
  //       });
  //     }
  //   } else {
  //     notification({
  //       title: 'Network Error',
  //       message: 'Something went wrong while updating the status of a Request. Please, try again later.',
  //       type: 'danger'
  //     });
  //   }
  //   setIsLoading(false);
  // };

  const updateAssignmentList = (updatedItem) => {
    const newList = assignments.map((item) =>
      item.id === commentId ? { ...item, ...updatedItem } : item
    );
    setAssignments(newList);
    setDetails({ ...details, assignee: null });
  };

  // const bookMeeting = (request) => {
  //   history.push(`/schedule-manager/create-request?type=meeting&requestId=${request.id}&requestTitle=${request.title}`);
  // };

  return (
    <>
      {isLoading && <IsLoading />}
      <div className="meeting-modal">
        <div className="row">
          <div className="col-4">
            <div className="modal-box w-100">
              <div className="w-100 flex flex-v-center header">
                <h3>{details.title || ""}</h3>
              </div>
              <div className="content">
                <p className="m-b-10 w-100">
                  <b>Memo Initiation Date :&nbsp;</b>
                  {formatDate(details.createdAt) || ""}
                </p>
                <p className="m-b-10 w-100">
                  <b>Memo Initiator :&nbsp;</b>
                  {details.createdBy || ""}
                </p>
                <p className="m-b-10 w-100">
                  <b>Date Received : &nbsp;</b>
                  {formatDate(details.dateReceived) || ""}
                </p>
                <p className="m-b-10 w-100">
                  {details.mda ? `MDA : ${details.mda.name}` : ""}
                </p>
                <p className="m-b-10 w-100">
                  {details.meetingCategory
                    ? `Category : ${details.meetingCategory.categoryName}`
                    : ""}
                </p>
                <p className="m-b-10 w-100 sttt">
                  <b>Current Status : &nbsp;</b>
                  <span className={`status ${details.status}-status`}>
                    {formatStatus(details.status)}
                  </span>
                </p>
                {(details.status === "actioned" ||
                  details.status === "declined") && (
                    <>
                      <p className="m-b-10 w-100">
                        <b>
                          {`${details.status === "actioned"
                              ? "Actioned"
                              : "Declined"
                            } By :`}
                          &nbsp;
                        </b>
                        {details.updatedBy || ""}
                      </p>
                      <p className="m-b-10 w-100">
                        <b>
                          {`${details.status === "actioned"
                              ? "Actioned"
                              : "Declined"
                            } On :`}
                          &nbsp;
                        </b>
                        {formatDateTime(details.updatedAt) || ""}
                      </p>
                    </>
                  )}
              </div>
            </div>
            <div className="modal-box w-100 m-t-20">
              <div className="w-100 flex flex-v-center header space-between">
                <h3>Cost Implication</h3>
                <h3>(₦)</h3>
              </div>
              <div className="content">
                <p className="m-b-10">
                  {formatCurrency(details.financialImplication)}
                </p>
              </div>
            </div>


            {/* details.status !== 'actioned' && details.assignee
            condition before now for the button */}

            {details.status === "pending" && decodeToken("fp")?.fp !== "True" &&
              (details?.assignees?.map((a) => a?.id)?.includes(userId) ||
                userId === details?.assignee?.id) && (
                <div className="w-100 m-t-20">
                  <OptionsBtn
                    className="w-100 btn-large"
                    options={[
                      {
                        title: "Action Request",
                        func: () => {
                          setShowCommentForm(true);
                          setOurStatus("actioned");
                        },
                        active:
                          details.status === "pending" ||
                          details.status === "assigned",
                      },
                      {
                        title: "Assign Request",
                        func: () => setShowAssignmentForm(true),
                        active:
                          details.status === "pending" ||
                          details.status === "assigned",
                      },
                      {
                        title: "Multi Assign Request",
                        func: () => setShowAssignmentFormMulti(true),
                        active:
                          details.status === "pending" ||
                          details.status === "assigned",
                      },
                      {
                        title: "Decline Request",
                        func: () => {
                          setShowCommentForm(true);
                          setOurStatus("declined");
                        },
                        active:
                          details.status === "pending" ||
                          details.status === "assigned",
                      },
                      {
                        title: "Keep In View",
                        func: () => {
                          setShowCommentForm(true);
                          setOurStatus("Keep In View");
                        },
                        active:
                          details.status === "pending" ||
                          details.status === "assigned",
                      },
                      {
                        title: "Note Request",
                        func: () => {
                          setShowCommentForm(true);
                          setOurStatus("Note Request");
                        },
                        active:
                          details.status === "pending" ||
                          details.status === "assigned",
                      },
                      {
                        title: "File Request",
                        func: () => {
                          setShowCommentForm(true);
                          setOurStatus("File Request");
                        },
                        active:
                          details.status === "pending" ||
                          details.status === "assigned",
                      },
                    ]}
                  />
                </div>
              )}

            {/* <div className="w-100 m-t-20">
              <OptionsBtn
                className="w-100 btn-large"
                options={[
                  {
                    title: "Action Request",
                    func: () => {
                      setShowCommentForm(true);
                      setOurStatus("actioned");
                    },
                    active:
                      details.status === "pending" ||
                      details.status === "assigned",
                  },
                  {
                    title: "Assign Request",
                    func: () => setShowAssignmentForm(true),
                    active:
                      details.status === "pending" ||
                      details.status === "assigned",
                  },
                  {
                    title: "Multi Assign Request",
                    func: () => setShowAssignmentFormMulti(true),
                    active:
                      details.status === "pending" ||
                      details.status === "assigned",
                  },
                  {
                    title: "Decline Request",
                    func: () => {
                      setShowCommentForm(true);
                      setOurStatus("declined");
                    },
                    active:
                      details.status === "pending" ||
                      details.status === "assigned",
                  },
                  {
                    title: "Keep In View",
                    func: () => {
                      setShowCommentForm(true);
                      setOurStatus("Keep In View");
                    },
                    active:
                      details.status === "pending" ||
                      details.status === "assigned",
                  },
                  {
                    title: "Note Request",
                    func: () => {
                      setShowCommentForm(true);
                      setOurStatus("Note Request");
                    },
                    active:
                      details.status === "pending" ||
                      details.status === "assigned",
                  },
                  {
                    title: "File Request",
                    func: () => {
                      setShowCommentForm(true);
                      setOurStatus("File Request");
                    },
                    active:
                      details.status === "pending" ||
                      details.status === "assigned",
                  },
                ]}
              />
            </div> */}
          </div>
          {/* <div className="col-8">
            <div className="modal-box meeting-booker">
              <div className="w-100 flex flex-v-center space-between header bg-gray">
                <h3>{details.title}</h3>
              </div>
              <div className="content">
                <PresentValue indent label="Vendor" value={details.vendor} className="bottom-border" />
                <PresentValue indent label="Details" value={details.description} />
              </div>
            </div>
            <div className="m-t-20 document-preview">
              <h3 className="bg-light-gray p-10 m-b-5">Documents Preview</h3>
              <div className="row">
                {details.uploadedDocuments && details.uploadedDocuments.map((file) => (
                  <DocumentPreview details={file} type="request" status={details.status} key={file.id} />
                ))}
              </div>
              {details.uploadedDocuments && details.uploadedDocuments.length < 1 && (
                <div className="w-100 p-20 bg-white center-text">
                  No Document
                </div>
              )}
            </div>
            <div className="w-100 m-t-20 document-journey">
              <h3 className="p-10">Documents Journey</h3>
              <div className="w-100">
                <table className="w-100">
                  <thead className="">
                    <tr className="">
                      <th className="th left p-10"><p>Assigned Officer</p></th>
                      <th className="th left p-10"><p>In-Tray</p></th>
                      <th className="th left p-10"><p>Out-Tray</p></th>
                      <th className="th left p-10"><p>Comment</p></th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {assignments && assignments.map((item) => (
                      <JourneyItem details={item} setShowQueryForm={setShowQueryForm} key={item.id} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}

          {details.hasOwnProperty("title") && (
            <div className="col-8">
              <div className="coverAll" style={{ width: "100%" }}>
                <div
                  className="topic"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "8px",
                    background: "#D5FFD5"
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "black",

                    }}
                  >
                    {details.title}
                  </p>
                  <Link to={`/schedule-manager/create-request?type=meeting&requestId=${details.id}&detailsTitle=${details.title}`}>
                    <button
                      className="pointer"
                      style={{
                        color: "#1C811C",
                        background: "#F7DD11",
                        width: "120px",
                        padding: "10px 0px",
                        borderRadius: "30px",
                      }}
                    // onClick={() => bookMeeting(details)}
                    >
                      Book a meeting
                    </button>
                  </Link>

                </div>
                <div className="messageBox">
                  <div className="eachMessage">
                    <div className="firstCol">
                      <p>To</p>
                    </div>
                    <div className="secondCol">
                      <p>
                        {details.assignee
                          ? details.assignee.name
                          : details.recipients.map((item) => (
                            <span>{item.name} </span>
                          ))}
                        {details.assignee ? "-" : null}{" "}
                        {details.assignee ? details.assignee.role : null}
                      </p>
                      <div className="line" />
                    </div>
                  </div>

                  <div className="eachMessage">
                    <div className="firstCol">
                      <p>From</p>
                    </div>
                    <div className="secondCol">
                      <p>
                        {details.recipient
                          ? details.recipient.name
                          : details.createdBy}
                        {details.recipient ? "-" : null}{" "}
                        {details.recipient ? details.recipient.role : null}
                      </p>
                      <div className="line" />
                    </div>
                  </div>

                  <div className="eachMessage">
                    <div className="firstCol">
                      <p style={{ color: "#909090" }}>Vendor</p>
                    </div>
                    <div className="secondCol">
                      <p>{details.vendor}</p>
                      <div className="line" />
                    </div>
                  </div>

                  <div className="eachMessage">
                    <div className="firstCol">
                      <p>Details</p>
                    </div>
                    <div className="secondCol">
                      <p>{details.description}</p>
                    </div>
                  </div>
                </div>

                <div className="m-t-20 document-preview">
                  <h3 className="bg-light-gray p-10 m-b-5">
                    Documents Preview
                  </h3>
                  <div className="row">
                    {details.uploadedDocuments &&
                      details.uploadedDocuments.map((file) => (
                        <DocumentPreview
                          details={file}
                          type="request"
                          status={details.status}
                          key={file.id}
                        />
                      ))}
                  </div>
                  {details.uploadedDocuments &&
                    details.uploadedDocuments.length < 1 && (
                      <div className="w-100 p-20 bg-white center-text">
                        No Document
                      </div>
                    )}
                </div>
                <div className="coverTable">
                  <div className="headTable">
                    <p>Document Journey</p>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Assigned Officers</th>
                        <th>In-Tray</th>
                        <th>Out-Tray</th>
                        <th>Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assignments &&
                        assignments.map((item) => (
                          <JourneyItem
                            details={item}
                            setShowQueryForm={setShowQueryForm}
                            key={item.id}
                          />
                        ))}
                      {/* <tr>
                        <td className="aboutAssignee">
                          <img src={profileImg} alt="" />
                          <div className="nameAssign">
                            <p>Adebayo Oluwafemi</p>
                            <p className="postAssign">
                              Officer2, Supervisor
                            </p>
                          </div>
                        </td>
                        <td className="datePlace">
                          <div className="coverdate">
                            <p className="dateAssign">May 23,2020; 09:45am</p>
                            <p className="moment">7 days ago</p>
                            <p className="location">Uhunmwonde, Edo State</p>
                          </div>

                        </td>
                        <td className="datePlace">
                          <p className="dateAssign">May 23,2020; 09:45am</p>
                          <p className="moment">7 days ago</p>
                          <p className="location">Uhunmwonde, Edo State</p>
                        </td>
                        <td className="commentRow">
                          <p className="comment">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas voluptas inventore obcaecati facilis facere, eligendi repudiandae deserunt modi praesentium sint provident dolores dolore harum incidunt doloribus, maxime corporis, quo ullam.</p>
                          <div className="down">
                            <button className="followUp" onClick={handleisActive}>React/FollowUp</button>
                            <button className="expand">Expand Queries & Responses</button>
                            <div style={{ display: `${isActive ? 'block' : 'none'}` }} className="queryBox">
                              <form action="">
                                <textarea name="" id="" rows="9" />
                                <button type="submit">Submit</button>
                              </form>
                            </div>
                            <div className="querylist">
                              <div className="eachQuery">
                                <div className="nameCover">
                                  <img src={profileImg} alt="" />
                                  <div className="nameFull">
                                    <p>Raji Mustapha</p>
                                    <p className="post">Perm Secretary Review</p>
                                  </div>
                                </div>
                                <div className="realComment">
                                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, omnis. Saepe animi expedita autem deleniti similique. Facere commodi fugit maiores. Reprehenderit temporibus cum modi, odio labore sapiente ullam sunt tempore.</p>
                                </div>

                                <div className="line" />

                              </div>

                              <div className="eachQuery">
                                <div className="nameCover">
                                  <img src={profileImg} alt="" />
                                  <div className="nameFull">
                                    <p>Raji Mustapha</p>
                                    <p className="post">Perm Secretary Review</p>
                                  </div>
                                </div>
                                <div className="realComment">
                                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam, omnis. Saepe animi expedita autem deleniti similique. Facere commodi fugit maiores. Reprehenderit temporibus cum modi, odio labore sapiente ullam sunt tempore.</p>
                                </div>

                                <div className="line" />

                              </div>
                              <button className="queryReact">React/FollowUp</button>
                              <div className="queryBox">
                                <form action="">
                                  <textarea name="" id="" cols="30" rows="10" />
                                  <button type="submit">Submit</button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showAssignmentForm && (
        <RequestAssignmentForm
          closeModal={() => setShowAssignmentForm(false)}
          request={details}
          commentId={commentId}
          handleDetails={handleDetails}
          current={current}
          currentId={currentId}
          updateList={updateAssignmentList}
          updated={updated}
        />
      )}

      {showAssignmentFormMulti && (
        <RequestAssignmentFormMulti
          closeModal={() => setShowAssignmentFormMulti(false)}
          request={details}
          commentId={commentId}
          updateList={updateAssignmentList}
          handleDetails={handleDetails}
          current={current}
          currentId={currentId}
          updated={updated}

        />
      )}

      {/* {showDeclineForm && (
        <RequestDeclineForm
          closeModal={() => setShowDeclineForm(false)}
          request={details}
          status="Declined"
          updateList={updateAssignmentList}
        />
      )} */}

      {showCommentForm && (
        <RequestCommentForm
          closeModal={() => setShowCommentForm(false)}
          request={details}
          status={ourStatus}
          handleDetails={handleDetails}
          current={current}
          currentId={currentId}
          updateList={updateAssignmentList}
          updated={updated}

        />
      )}
      {showQueryForm && (
        <RequestQueryForm
          details={details}
          current={current}
          currentId={currentId}
          handleDetails={handleDetails}
          closeModal={() => setShowQueryForm(false)}
        />
      )}
    </>
  );
};

export default RequestDetails;
