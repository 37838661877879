/* eslint-disable linebreak-style */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useEffect, useState, useRef } from 'react';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import Greeting from '../common/Greeting'
import SelectInputColored from '../inputs/SelectInputColored';
import IsLoading from '../common/IsLoading';
import SearchInput from '../inputs/SearchInput';
import LocationDetails from '../layouts/LocationDetails';
import MeetingDetails from '../layouts/MeetingDetails';
import { get } from '../../api-services/fetch';
import CalendarBtn from '../layouts/CalendarBtn';
import {
  formatDate, formatLongDate, formatTime, formatDateforPicker
} from '../../utility/dateTime';
import { formatStatus } from '../../utility/general';
import BookMeetingForm from '../layouts/BookMeetingForm';

const MeetingManager = ({ history }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [recordForEditing, setRecordForEditing] = useState([]);
  const [showBookMeetingForm, setShowBookMeetingForm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [searchText, setSearchText] = useState('');
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [startDate, setStartDate] = useState(formatDateforPicker(new Date()));
  const [endDate, setEndDate] = useState(formatDateforPicker(new Date()));
  const [selectedDate, setSelectedDate] = useState('Today');
  const [status, setStatus] = useState('');

  const focusRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Meeting Manager';

    const getList = async () => {
      // focusRef.current.focus();
      // setIsLoading(true);
      setErrorMsg('');
      const res = await get({
        endpoint: 'meeting',
        pQuery: {
          query: searchText, startDate, endDate, status
        }
      });

      console.log(res);

      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          setDetails(data.data);
          if (data.data.length < 1) {
            if (searchText) {
              setErrorMsg('Meeting not found');
            } else {
              setErrorMsg('No Meeting yet');
            }
          }
        } else {
          setErrorMsg(data.message);
        }
      } else {
        setErrorMsg('Something has gone wrong. Please, try again.');
      }
      setIsLoading(false);
    };

    getList();
  }, [searchText, startDate, endDate, status]);

  const handleDate = async (e) => {
    const { value } = e.target;
    setSelectedDate(value);
    setSearchText('');

    switch (value) {
      case 'Today':
        // get meetings for today
        setStartDate(formatDateforPicker(new Date()));
        setEndDate(formatDateforPicker(new Date()));
        break;
      case 'This Week':
        // get meetings for this week
        setStartDate(formatDateforPicker(moment().startOf('week').toDate()));
        setEndDate(formatDateforPicker(moment().endOf('week').toDate()));
        break;
      case 'This Month':
        // get meetings for this month
        setStartDate(formatDateforPicker(moment().startOf('month').toDate()));
        setEndDate(formatDateforPicker(moment().endOf('month').toDate()));
        break;
      case 'Calendar':
        // get meetings for the day selected in the calendar
        setStartDate(formatDateforPicker(calendarDate));
        setEndDate(formatDateforPicker(calendarDate));
        break;
      case 'All Meetings':
        // get meetings for the day selected in the calendar
        setStartDate('');
        setEndDate('');
        break;
      default:
        break;
    }
  };

  const handleCalendarDate = async (val) => {
    setCalendarDate(val);
    setStartDate(formatDateforPicker(val));
    setEndDate(formatDateforPicker(val));
    setSelectedDate('Calendar');
    setSearchText('');
  };

  const handleSearch = async (e) => {
    const { name, value } = e.target;

    if (name === 'searchText') {
      setSearchText(value);
    }
    if (name === 'status') {
      setStatus(value);
    }
  };

  const showMeetingDetails = (id) => {
    const selected = details.filter((item) => id === item.id);

    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setShowFormModal(true);
    }
  };

  const editMeeting = (id) => {
    const selected = details.filter((item) => id === item.id);

    if (selected.length > 0) {
      if (selected[0].status === 'pending' || selected[0].status === 'declined' || selected[0].status === 'cancelled') {
        history.push(`/schedule-manager/update-meeting/${id}`);
      } else {
        setShowBookMeetingForm(true);
        setRecordForEditing(selected[0]);
      }
    }
  };

  const closeMeetingEdit = () => {
    setRecordForEditing({});
    setShowBookMeetingForm(false);
  };

  const bookMeeting = () => {
    history.push('/schedule-manager/create-request?type=meeting');
  };

  const closeModal = () => {
    setSelectedRecord([]);
    setShowFormModal(false);
  };

  const updateList = (newItem, isUpdate) => {
    if (isUpdate) {
      const newList = details.map((item) => (item.id === newItem.id ? newItem : item));
      setDetails(newList);
    } else if (errorMsg) {
      // getList({});
    } else {
      const newList = [...details];
      newList.unshift(newItem);
      setDetails(newList);
    }
  };

  const statusOptions = [
    { name: 'All Meetings', value: '' },
    { name: 'Booked', value: 'booked' },
    { name: 'Actioned', value: 'actioned' },
    { name: 'Pending', value: 'pending' },
    { name: 'Declined', value: 'declined' },
    { name: 'Cancelled', value: 'cancelled' }
  ];

  return (
    <div className="w-100" tabIndex={-1} ref={focusRef}>
      <div className="w-100 flex space-between">
        <div>
          <Greeting />

          <h3>
            Schedule Manager -&nbsp;
            {selectedDate === 'Calendar'
              ? formatLongDate(moment(calendarDate).format('YYYY-MM-DD'))
              : selectedDate}
          </h3>
        </div>
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between m-t-20 flex-v-center">
        <div className="flex">
          <SelectInputColored
            className="m-r-10"
            options={['All Meetings', 'Today', 'This Week', 'This Month', 'Calendar']}
            value={selectedDate}
            onChange={handleDate}
          />
          <CalendarBtn value={calendarDate} onChange={(val) => handleCalendarDate(val)} />
          <button
            className="booking-btn pointer m-r-5"
            type="button"
            onClick={bookMeeting}
          >
            Book a meeting
          </button>
        </div>
        <div className="flex flex-v-center">
          <SelectInputColored
            className="m-r-10"
            options={statusOptions || []}
            value={status}
            onChange={handleSearch}
            name="status"
          />
          <SearchInput
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            className="m-l-5"
            placeholder="Search list..."
          />
        </div>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th"><p>Date</p></div>
            <div className="th"><p>Time</p></div>
            <div className="th"><p>Meetings Title</p></div>
            <div className="th"><p>Location</p></div>
            <div className="th"><p>Status</p></div>
            <div className="th"><p>Attendees</p></div>
            <div className="th"><p>Actions</p></div>
          </div>
        </div>
        <div className="table-body">
          {!(isLoading || errorMsg) && details && details.map((item) => (
            <div className="table-row" key={item.id}>
              <div className="td">{formatDate(item.startDate)}</div>
              <div className="td">{`${formatTime(item.startTime)} - ${formatTime(item.endTime)}`}</div>
              <div className="td">{item.title}</div>
              <div className="td">{item.location}</div>
              <div className="td">
                <div className={`status ${item.status}-status`}>
                  {formatStatus(item.status)}
                </div>
              </div>
              <div className="td" style={{ maxWidth: '400px' }}>{item.attendees && item.attendees.join(', ')}</div>
              <div className="td actions flex flex-v-center flex-end">
                {item.status === 'actioned' && (
                  <button
                    className="booking-btn pointer m-r-5"
                    type="button"
                    onClick={() => editMeeting(item.id)}
                  >
                    Book a meeting
                  </button>
                )}
                <button
                  data-tip
                  data-for="view-btn"
                  type="button"
                  className="action-btn pointer"
                  onClick={() => showMeetingDetails(item.id)}
                >
                  <AiOutlineFundProjectionScreen />
                </button>
                <ReactTooltip id="view-btn" type="info" effect="solid">
                  <span>View Meeting Details</span>
                </ReactTooltip>
                {item.status !== 'actioned' && item.status !== 'booked' && (
                  <>
                    <button
                      data-tip
                      data-for="edit-btn"
                      type="button"
                      className="m-l-10 pointer action-btn"
                      onClick={() => editMeeting(item.id)}
                    >
                      <BiEditAlt />
                    </button>
                    <ReactTooltip id="edit-btn" type="warning" effect="solid">
                      <span>Edit Meeting Details</span>
                    </ReactTooltip>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      {(isLoading) && <IsLoading />}
      {showFormModal && (
        <MeetingDetails
          meetingId={selectedRecord.id}
          updateList={updateList}
          closeModal={closeModal}
        />
      )}
      {showBookMeetingForm && (
        <BookMeetingForm
          details={recordForEditing}
          closeModal={closeMeetingEdit}
          updateList={updateList}
        />
      )}
    </div>
  );
};

export default MeetingManager;
